import { Box, Typography, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useConnectWallet } from '../../redux/connectWallet'
import { createWeb3Modal } from '../../web3/createWeb3Modal'
import { loginNFT } from '../../redux/login'

function LoginNew() {
  const {
    connectWallet,
    web3,
    address,
    networkId,
    connected,
    connectWalletPending,
  } = useConnectWallet()
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, nftAuthenticated, token, error } = useSelector((state) => ({
    user: state.home.user,
    nftAuthenticated: state.home.nftAuthenticated,
    token: state.home.token,
    error: state.home.error,
  }))
  const [web3Modal, setModal] = useState(null)

  useEffect(() => {
    setModal(createWeb3Modal())
  }, [setModal])

  useEffect(() => {
    document.body.classList.add('landing_page')
    return () => {
      document.body.classList.remove('landing_page')
    }
  }, [])

  const handleNftLogin = async () => {
    if (
      web3Modal &&
      (web3Modal.cachedProvider || window.ethereum) &&
      !connected
    ) {
      await connectWallet(web3Modal)
    }
    await dispatch(
      loginNFT(() => {
        history.push('/admin')
      }),
    )
  }

  return (
    <>
      <Box className="new_login_main">
        <Box
          component="img"
          src="/img/ftr_bg_logo.png"
          className="ftr_bg_lgl"
        />
        <Box className="login_man_box">
          <Box className="login_h1_box">
            <Typography component="h1">
              {' '}
              Login to <span>Dashboard</span>
            </Typography>
          </Box>
          <Box className="login_pop_box">
            <Box className="back_btn_box">
              <Button as="a" href="/">
                <img src="/img/back_btn.svg" alt="" /> Back
              </Button>
            </Box>
            <Box className="login_h3_btn_box">
              <Typography component="h3">Login</Typography>
              <Typography component="h4">
                {' '}
                For login you need connect your wallet with NFT
              </Typography>
              <Button onClick={handleNftLogin}>
                <img src="/img/metamask.svg" alt="" /> Metamask Wallet
              </Button>
              <Typography>
                New to Ethereum?<a href="#">Learn more about wallets</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default LoginNew
