import React from 'react'
import BigPicture from "@instaffogmbh/react-bigpicture";
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export default function VideoGallary() {
   
    return (
        <>
            <Box className="gallary_box">
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={12} md={4}>
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="video"
                                    caption="Example of an optional caption."
                                    src="/img/pi/Pi_Number_Spiral_v_WEB.mp4"
                                >
                                    <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>

                    </Row>
                </Container>
            </Box>
            
        
        </>
    )
}
