import React from 'react'
import { Box, Typography, Tabs, Tab, Container } from '@mui/material'
import { Row, Col, Button } from "react-bootstrap";
// import { Card1 } from "./nftCards/Card1";

export default function TabPanelTwo() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Container className='def_conta_iner contin_tab_two'>
                <Row className='synergy_bx_row'>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <Box className="synerg_box">
                            <Typography component="h2">Recently Added:<br/> <span>Some of our recent NFTs made for our beautiful clients. </span></Typography>
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                    </Col>
                </Row>

                <Box className="tab_panl_min_bx">
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tabe_two_img.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography className="bit_col_p bit_col_p_02">
                                        Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                    </Typography>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tabe_two_img.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography className="bit_col_p bit_col_p_02">
                                        Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                    </Typography>
                                </Box>
                            </Box>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tabe_two_img.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography className="bit_col_p bit_col_p_02">
                                        Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                    </Typography>
                                </Box>
                            </Box>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tabe_two_img.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography className="bit_col_p bit_col_p_02">
                                        Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                    </Typography>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Container>

            

            <Box className='def_conta_iner_ried_mor'>
                <Container className='def_conta_iner'>
                    <Box className="tab_panl_min_bx">
                        
                    </Box>
                </Container>
                <Button className="vew_aro_btn" as="a" href="/nft-gallary">
                    <Box className="viw_aro">
                    Vew FULL Collection
                            <Box component="img" src="/img/Arrow_right.svg" />
                    </Box>
                </Button>
            </Box>

            <Box className='recent_add_bax'>
                <Typography className='recent_add_p'>RECENT ADDED</Typography>
                <Container className='def_conta_iner'>
                    <Box className="tab_panl_min_bx contin_tab_two">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tabe_two_img.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography className="bit_col_p bit_col_p_02">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br /> This is the first NFT in a limited edition series.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                    <Button className="vew_aro_btn vew_aro_btn_v2" as="a" href="/nft-gallary">
                        <Box className="viw_aro">
                            Vew FULL Collection
                            <Box component="img" src="/img/Arrow_right.svg" />
                        </Box>
                    </Button>
                </Container>
            </Box>
                
        </>
    )
}