import { erc20ABI, nft5 } from "../../../../../configure/abi";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../../../../common/redux/snackbar";
import { useConnectWallet } from "../../../../../redux/hooks";
import Web3 from 'web3';
export { useFetchPoolBalances } from "./fetchPoolBalances";


export function useBalanceOf(tokenAddress) {
  const { address, web3 } = useConnectWallet();
  const [balance, setBalance] = useState("0");

  const fetchBalance = useCallback(async () => {
    let balance;
    if (tokenAddress === "") {
      balance = await web3.eth.getBalance(address);
    } else {
      const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

      balance = await contract.methods.balanceOf(address).call();
    }
    setBalance(balance);
  }, [address, setBalance, tokenAddress, web3]);

  useEffect(() => {
    if (web3 && address) {
      fetchBalance();

      let refreshInterval = setInterval(fetchBalance, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [web3, address, fetchBalance]);

  return balance;
}

// dragonDirect
export function useDeposit(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = useCallback(async (MP1) => {
    setIsPending(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(nft5, poolAddress);
        contract.methods
          .buyOne(1)
          .send({ from: address, value: MP1 })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve({});
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending(false);
    }
  }, [dispatch, setIsPending, web3, address, poolAddress]);

  return { isPending, onDeposit: handleDeposit };
}

export function useDeposit2(poolAddress, tokenAddress) {
  const { web3, address } = useConnectWallet();
  const [isPending2, setIsPending2] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit2 = useCallback(async (MP2) => {
    setIsPending2(true);
    try {
      await new Promise(async (resolve, reject) => {
        const contract = new web3.eth.Contract(nft5, poolAddress);
       
        contract.methods
        .buyThree(1)
        .send({ from: address, value: MP2 })
          .on("transactionHash", function (hash) {
            dispatch(
              enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "success",
                },
                hash,
              })
            );
          })
          .on("receipt", function (receipt) {
            resolve({});
          })
          .on("error", function (error) {
            console.log(error);
            reject(error);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    } finally {
      setIsPending2(false);
    }
  }, [dispatch, setIsPending2, web3, address, poolAddress]);

  return { isPending2, onDeposit2: handleDeposit2 };
}


export function useBNBPrice1(poolAddress) {
  const { address, web3 } = useConnectWallet();

  const [MP1, setMP1] = useState("");

  const fetchMP1 = useCallback(async () => {
      const contract = new web3.eth.Contract(nft5, poolAddress);

      const MP1 = await contract.methods.NftTypes(1).call({ from: address })
      setMP1(MP1)
  }, [address, setMP1, poolAddress, web3])
  
  useEffect(() => {
      if (web3 && address) {
          fetchMP1()
      
      let refreshInterval = setInterval(fetchMP1, 3000)
      return () => clearInterval(refreshInterval)  }
  }, [web3, address, fetchMP1])
  return MP1
}