const imageCache = {}; // Object to store cached images

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    const dataUrl = canvas.toDataURL("image/jpeg"); // 
    resolve(dataUrl);
    // canvas.toBlob((file) => {
    //   resolve(URL.createObjectURL(file));
    // }, "image/jpeg");
  });
}

/*
export default async function getCroppedImgNew(
  imageSrc,
  pixelCrop,
  rotation = 0,
  img,
  setImg,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  canvas.toBlob((file) => {
    //resolve(URL.createObjectURL(file));
    var url = URL.createObjectURL(file);
    setImg({id:img.image?._id, letter:img?.letter, original_url: img.url, url:url, cropSize:getValueByKey(img?.letter)});
  }, "image/jpeg");

  
  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, "image/jpeg");
  // });
}

const generateWH = (w,h) => {
  return {width:w, height:h}
}

const alphas = [
  {"a": generateWH(144,94)}, 
  {"b": generateWH(94,94)}, 
  {"c": generateWH(119,94)},
  {"d": generateWH(174,308)}, 
  {"e": generateWH(250,138)},
  {"f": generateWH(127,138)},
  {"g": generateWH(182,200)},
  {"h": generateWH(174,308)},                
  {"i": generateWH(253,185)},        
  {"j": generateWH(369,208)}, 
  {"k": generateWH(129,94)},         
  {"l": generateWH(248,164)},
  {"m": generateWH(182,101)},
  {"n": generateWH(253,251)},

  {"o": generateWH(128,64)},

  {"p": generateWH(186,331)},

  {"q": generateWH(224,143)},

  {"r": generateWH(132,143)},
  {"s": generateWH(226,143)},
  {"t": generateWH(215,204)},
  {"u": generateWH(141,204)},
  {"v": generateWH(146,212)},
  {"w": generateWH(137,77)},
  {"x": generateWH(110,197)},
  {"y": generateWH(131,182)},
  {"z": generateWH(323,182)},
  {"aa": generateWH(128,182)},
  {"ab": generateWH(215,122)},
  {"ac": generateWH(141,122)},
  {"ad": generateWH(201,114)},
  {"ae": generateWH(82,114)}        
];

function getValueByKey(key) {
  for (let i = 0; i < alphas.length; i++) {
    const obj = alphas[i];
    if (key in obj) {
      return obj[key];
    }
  }
  return null; // Key not found
}
*/