import React, {useState, useEffect} from 'react'
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import uuidv4 from "uuid";
import { Box, Typography } from '@mui/material'

export default function ProductsSlider(props:any) {
    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showArrows, setShowArrows] = useState(true);
    const [goToSlide, setGoToSlide] = useState(null);
    const slides = [
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        },
        {
          key: uuidv4(),
          content: <video autoPlay loop><source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" /></video>
        }
      ].map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
      });
      useEffect(() => {
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
      }, [props.offset, props.showArrows]);
    return (
        <>
            <Box className="productslider">
                <Carousel 
                    slides={slides} 
                    goToSlide={goToSlide}
                    offsetRadius={offsetRadius}
                    showNavigation
                    animationConfig={config.gentle}
                />
            </Box>
        </>
    )
}
