import React, { useState } from "react";

function MultipleFileUpload({files, setFiles, previewUrls, setPreviewUrls}) {
  // const [files, setFiles] = useState([]);
  const handleFileInputChange = (event) => {
    setFiles(event.target.files);
    let previewUrlsTemp = [];
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let reader = new FileReader();
      reader.onloadend = function () {
        previewUrlsTemp.push(reader.result);
        // setPreviewUrls(previewUrlsTemp);
        if(previewUrlsTemp.length == event.target.files.length){
          setPreviewUrls( // Replace the state
            previewUrlsTemp
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  
  return (
    <div className="cloudinary-button-container">
      <input
        type="file"
        id="file-input"
        multiple
        onChange={handleFileInputChange}
        style={{ display: "none" }}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          document.getElementById("file-input").click();
        }}
        id="upload_widget"
        className="cloudinary-button button"
      >
        Upload Files
      </button>
    </div>

    // <div>
    //   <input type="file" multiple onChange={handleFileInputChange} />
    //   <div>
    //     {previewUrls.map((previewUrl, index) => (
    //       <img key={index} src={previewUrl} alt="Preview" />
    //     ))}
    //   </div>
    // </div>
  );
}

export default MultipleFileUpload;
