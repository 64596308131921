import { combineReducers } from "redux";
// import { connectRouter } from "connected-react-router";
// import history from "./history";
import homeReducer from "../redux/reducer";

// import nft1Reducer from "features/nft1/redux/reducer";
// import nft2Reducer from "features/nft2/redux/reducer";
// import nft3Reducer from "features/nft3/redux/reducer";
// import nft4Reducer from "features/nft4/redux/reducer";
import nft5Reducer from "../components/home/partitions/nft5/redux/reducer";
// import nft6Reducer from "features/nft6/redux/reducer";
// import nft7Reducer from "features/nft7/redux/reducer";
import commonReducer from "../redux/reducer";

const reducerMap = {
  //   router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  //   nft1: nft1Reducer,
  //   nft2: nft2Reducer,
  //   nft3: nft3Reducer,
  //   nft4: nft4Reducer,
  nft5: nft5Reducer,
  //   nft6: nft6Reducer,
  //   nft7: nft7Reducer,
};

export default combineReducers(reducerMap);
