import { Button as MUIButton, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Cropper from "react-easy-crop";
import { connect, useDispatch } from "react-redux";

import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import EasyCrop from "./EasyCrop";
import { getGalleryItems, updateGalleryCropSize } from "../../../redux/gallery";
import { FileUploader } from "react-drag-drop-files";


function CropImage(props){

  const [image, setImage] = useState(null);
  const [updatedImage, setUpdatedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    // setImage("https://api.bitgraphix.io/api/gallery/view-image-thumb?image=1685199019308122bfb1d9bb155078c7c9c7f74645a78.png")
    setImage(props.image);
  }, [props.image])

  const handleImageUpload = async (e) => {
    // setUpdatedImage({image:e.target.files[0], original_url:URL.createObjectURL(e.target.files[0]), cropSize:props.image.cropSize, type:'new'});
    setUpdatedImage({image:e, original_url:URL.createObjectURL(e), cropSize:props.image.cropSize, type:'new'});
  };

  const doSubmit = () => {
    if(updatedImage != null){
      const formData = new FormData();
      formData.append('image', updatedImage.image);
      formData.append('id', props.image.id);
      formData.append('cropSize', JSON.stringify(croppedAreaPixels));
      formData.append('rotation', ""+rotation);


      dispatch(
        updateGalleryCropSize(
          formData,
          () => {
            dispatch(getGalleryItems());
            setUpdatedImage(null);
            props.handleClose();          
          }
        )
      );
    }
    else{
      const formData = new FormData();
      formData.append('id', props.image.id);
      formData.append('cropSize', JSON.stringify(croppedAreaPixels));
      formData.append('rotation', ""+rotation);
      dispatch(
        updateGalleryCropSize(
          formData,
          () => {
            dispatch(getGalleryItems());
            setUpdatedImage(null);
            props.handleClose();          
          }
        )
      );
    }
    // console.log("image id : ",props.image.id);
    // console.log("croppedAreaPixels: ", croppedAreaPixels) 
  }

  let FileUploaderContent = () => {
    return (
      <div>
        DROP YOUR FILES HERE
      </div>
    )
  }
  return (
    <div className='modal_div_user'>
        <Modal
          keepMounted
          open={props.openCropPopup}
          onClose={(e) => {
            setUpdatedImage(null);
            props.handleClose();
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='user_modal_inr_main cropImageModal'>
            {/* <Box className='mdl_hdr_usr'>
                <Button className='back_btn_usr' onClick={(e) => {
                  setUpdatedImage(null);
                  props.handleClose();
                }}>
                  <img src='./img/back_arrow.svg' alt='' />
                  Back
                </Button>
                <Typography component="h1">Update Image</Typography>
            </Box> */}
            <Box className="cropHeader">
              <Typography component="h1">Update Image</Typography>
              <Button className='back_btn_usr' onClick={(e) => {
                  setUpdatedImage(null);
                  props.handleClose();
                }}>
                  <CloseIcon />
                </Button>

            </Box>
            <Box className='usr_mdl_body_prnt'>
            
            {/* <input
              type="file"
              name="cover"
              onChange={handleImageUpload}
              accept="img/*"
            /> */}

          <FileUploader
              multiple={false}
              handleChange={handleImageUpload}
              children={<FileUploaderContent/>}
              classes={"drop-zone"}
              // name="file"
              // types={fileTypes}
            />
            {updatedImage ? 
            <EasyCrop 
            rotation={rotation} setRotation={setRotation}
            image={updatedImage} croppedAreaPixels={croppedAreaPixels} setCroppedAreaPixels={setCroppedAreaPixels}/>
            :
            <EasyCrop 
            rotation={rotation} setRotation={setRotation}
            image={image} croppedAreaPixels={croppedAreaPixels} setCroppedAreaPixels={setCroppedAreaPixels}/>
            } 
            {/* <div className="controls">
                <input
                type="range"
                value={props.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                    props.setZoom(e.target.value)
                }}
                className="zoom-range"
                />
            </div> */}
              
              <Box className='user_mdl_ftr'>
                {/* <Button className='cncl_btn_usr' onClick={(e) => {
                  setUpdatedImage(null);
                  props.handleClose();
                }}>Cancel</Button> */}
                <Button className='create_btn_usr' 
              onClick={doSubmit}
              >Crop and post</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
  )
}

export default React.memo(CropImage);
  