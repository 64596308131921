import React, { useEffect, useState } from 'react'
import { Box, Button, } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import Web3 from 'web3';
import { TypeNFTList } from '../../utils/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { getNftsWithRole } from '../../redux/nfts';
import BitAdmin from '../../constants/bitAdmin.json'
import { addIPFSProxy } from '../../helpers/utils';
import { fetchPutApi } from '../../utils/backendApi';
import httpStatus from 'http-status';

export const NFT_TYPE_VIDEO = ['video/mp4', 'video/webm']

const label = { inputProps: { 'aria-label': 'Switch demo' } };


export default function TabOne() {
    const dispatch = useDispatch();
    const history = useHistory();
    const address = useSelector((state: any) => state.home.address)
    const web3 = useSelector((state: any) => state.home.web3)
    const nfts = useSelector((state: any) => state.home.nfts);
    const getData = () => {
        dispatch(getNftsWithRole(1, 'admin'));
    }

    const [nftImage, setNftImage] = useState<any[]>([]);

    const getNFTMetadata = async () => {
        setNftImage([]);
        const nftLists = nfts?.result

        const web3 = new Web3(window.ethereum);

        const contract = new web3.eth.Contract(BitAdmin as any, process.env.REACT_APP_CONTRACT_ADDRESS);

        if (nftLists && nftLists.length > 0) {
            let tokenUris = []
            for (let i = 0;i < nftLists.length;i++) {
                let nftToken = nftLists[i].nft_token;
                // contract.methods.tokenURI(nftToken)
                //     .call()
                //     .then(uri => {
                //         tokenUris.push(uri)
                //     })
                //     .catch(err => console.log(err))
                const res = await contract.methods.tokenURI(nftToken)
                    .call()
                tokenUris.push(res)
            }

            let promises = await Promise.all(tokenUris);
            let metadatas = []
            console.log(tokenUris)
            for (let j = 0;j < promises.length;j++) {
                let ipfsURL = addIPFSProxy(promises[j]);
                const request = new Request(ipfsURL);
                metadatas.push(fetch(request))
            }


            let metadataPromises = await Promise.all(metadatas)
            let images = []
            for (let k = 0;k < metadataPromises.length;k++) {
                let promises = metadataPromises[k]
                let metadata = await promises.json();
                const image = addIPFSProxy(metadata.image);
                images.push({ metadata, image })
            }
            setNftImage(images)
        }
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        getNFTMetadata()
    }, [nfts, address])

    const changeStatus = async (nft: any, status: any) => {
        try {
            const body = {
                id: nft._id,
                active: status
            }
            const response = await fetchPutApi('api/user-nft/update', body, true);
            if (response?.status == httpStatus.OK) {
                let data = await response.json();
                if (data?.success) {
                    getData()
                }
                else {
                    console.log(`Error:${data?.message}`);
                }
            }
            else {
                console.log('Internal server error');
            }
        } catch (error: any) {
            console.log(error?.message);
        }
    }

    const pushToEditPage = () => {

    }

    return (
        <>
            <Box className='table_man_prnt'>
                <table className='table_man_prnt'>
                    <thead className='thed_prnt'>
                        <tr>
                            <th>NFT</th>
                            <th>NFT ID</th>
                            <th>User name</th>
                            <th className='block_text'>Blocked <span>Active</span></th>
                            {/* <th className='active_text'>Active </th> */}
                        </tr>
                    </thead>
                    <tbody className='tbody_prnt'>

                        {nfts?.result && (nfts?.result || []).map((item: TypeNFTList, index: number) => {
                            const nft = nftImage[index];
                            return (
                                <tr key={`nft-list=${index}`}>
                                    <td>
                                        {nft?.metadata?.type ? NFT_TYPE_VIDEO.includes(nft?.metadata?.type) ? (
                                            <video src={nft?.image}
                                                muted
                                                loop
                                                autoPlay
                                            />
                                        ) : (
                                            <img src={nft?.image} alt="" />
                                        ) : (
                                            <img src={nft?.image} alt="" />
                                        )}
                                    </td>
                                    <td>{item.nft_token}</td>
                                    <td>{item.id_user.firstName} {item.id_user.lastName}</td>
                                    {/* <td>05.08.2022</td> */}
                                    <td>
                                        <Box className='swich_btn_prnt'>
                                            <input type="checkbox" checked={item.active} onChange={(e) => changeStatus(item, e?.target.checked)} />
                                            <Box className='switch_me'>
                                                <img src='./img/swich_img_red.png' alt='' className='red_img_switch' />
                                                <Box className='switch_bg'>
                                                    <span className='switch_span'></span>
                                                </Box>
                                                <img src='./img/swich_img_blue.png' alt='' className='blue_img_switch' />
                                            </Box>
                                        </Box>
                                    </td>
                                    <td>
                                        <Box className='link_box'>
                                            <Link to={`/editnft?nftId=${item.nft_token}`}><img src='./img/pen_ic.svg' alt='' /></Link>
                                        </Box>
                                    </td>

                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </Box>
        </>
    )
}
