import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Container, Row, Col, Button } from "react-bootstrap";

import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../../../redux/hooks";
import { byDecimals } from "../../../../helpers/bignumber";
import { useDeposit, useDeposit2, useBNBPrice1 } from "../nft5/redux/hooks";



export const Card1 = ({ index, npool }) => {

  const [isActiveAs, setActiveAs] = useState(false);
  const toggleClassAs = () => {
    setActiveAs(!isActiveAs);
  };

  const [isActiveSeven, setActiveSeven] = useState(false);
  const toggleClassSeven = () => {
    setActiveSeven(!isActiveSeven);
  };

  const { networkId } = useConnectWallet();

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 }: any = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const MP1: any = useBNBPrice1(npool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(100000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(100000))
      .toNumber();
  };

  return (
    <Col xs={12} sm={6} md={6} lg={3}>
      <Box className="man_box_colps">
        <video width="100%" muted loop autoPlay>
          <source
            src="/img/col_vido_01.webm"
            type="video/mp4"
          ></source>
        </video>
        <Box className="min_h_box">
          {/* <Typography component="h3" className="the_fir_h3">
            The first official Bitgraphix NFT!- <br />
            Limited NFT Miami Edition -
          </Typography> */}
          <Typography component="h3" className="the_fir_h3">
            Coming Soon
            <br /> Bitgraphix NFT
          </Typography>
          <Box className="totel_img_box jut_con_c">
            {/* <Typography component="h5">
              total mint: <span>{MP1[0]}/100</span>
            </Typography> */}
            <Box
              component="img"
              src="/img/mint_img.svg"
              className=""
            />
            {/* <Typography component="h4">
              1 NFT <span>{forMat(byDecimals(MP1[8]))} ETH</span>
            </Typography> */}
          </Box>
          {/* <Box className="totel_img_box">
            
              <Box
                component="img"
                src="/img/mint_img.svg"
                className=""
              />
              <Typography component="h4">
                3 NFT <span>{forMat(byDecimals(MP1[8] * 3))} ETH</span>
              </Typography>
            </Box> */}


          <Typography className="bit_col_p">
          The Bitgraphix NFT collection will be coming soon as we finalize our utility partnerships.
          </Typography>
          <Button className="for_btn" onFocus={(event) => event.stopPropagation()}
            disabled={isDepositPending}
            onClick={(event) => {
              onDeposit(MP1[8]);
            }}>Coming Soon</Button>

          {/* <Button className="for_btn"      onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending2}
                        onClick={(event) => {
                          onDeposit2(MP1[8] * 3);
                        }}>Buy 3 NFT</Button> */}
        </Box>
      </Box>
    </Col>
  )
}