import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Box, Typography } from '@mui/material'

export default function DevTeam() {
  return (
    <>
        <Box className='dev_team_sc'>
            {/* <Box component="img" src="/img/bit_img.png" className="bit_img" alt="" /> */}
            <Container>
                <Box className='dev_team_cntnt'>
                    <Box className='headeingtext'>
                        <Typography component="h3">DEV TEAM</Typography>
                        <Typography>PARTNERSHIPS:</Typography>
                    </Box>
                    <Typography className='details_p'>Bitgraphix provides world class support <br/>as a development team partner for continued project  Growth</Typography>
                </Box>
            </Container>
        </Box>
    </>
  )
}
