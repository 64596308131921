import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  NFT_FETCH_POOL_BALANCES_BEGIN,
  NFT_FETCH_POOL_BALANCES_SUCCESS,
  NFT_FETCH_POOL_BALANCES_FAILURE,
} from './constants';

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator

  const { nft5pool, fetchPoolBalancesPending } = useSelector(
    (state:any) => ({
      nft5pool: state.nft5.nft5pool,
      fetchPoolBalancesPending: state.nft5.fetchPoolBalancesPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (data) => {
    },
    [],
  );

  return {
    nft5pool,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case NFT_FETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case NFT_FETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        nftpools: action.data,
        fetchPoolBalancesPending: false,
      };

    case NFT_FETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}