import { Box, Button, Typography, Grid } from '@mui/material'
import React from 'react'
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function EditNFT() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('nftId');

  console.log(id)

  return (
    <>
      <Box className='user_list_main'>
        <Typography className='dflt_h1_usr_list' component="h1">Team NFT Control Panel</Typography>
        <Box className='usr_list_in_bx usr_list_in_bx_v2'>
          <Box className='usr_list_header flx_clm_bx'>
            <Typography component="h2">Edit NFT</Typography>
            <Link to="/team-nft" className='back_btn_nft'>
              <img src='./img/back_arrow.svg' alt='' />
              Back
            </Link>
          </Box>
          <Box className='edit_nft_main'>
            <Box className='inr_nft_body'>
              <Box className='black_mmbr_bx'>
                <img src='./img/black_bx_ic.png' alt='' />
                <Typography component="h4">Team Member</Typography>
                <Button>
                  <Typography>Update NFT</Typography>
                  <img src='./img/pen_ic.svg' alt='' />
                </Button>
              </Box>
              <Box className='right_nft_inpts'>
                <Box className='inpts_nft_edit_right'>
                  <TextField id="outlined-basic" label="ID user" variant="outlined" />
                  <TextField id="outlined-basic" label="User wallet address" variant="outlined" />
                  <Box className='nft_ladt_input'>
                    <TextField id="outlined-basic" label="User name" variant="outlined" />
                    <img src='./img/wlt_adrs_ic.png' alt='' />
                  </Box>
                </Box>
                <Box className='nft_two_btns'>
                  <Button className='cncl_btn_nft' >Cancel</Button>
                  <Button className='save_btn_nft'>Save</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
