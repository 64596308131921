import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getGalleryItems } from "../../../redux/actions";
import getCroppedImg from "./Crop";
import CropImage from "./CropImage";

import LightGallery from 'lightgallery/react';
import { LightGallerySettings } from 'lightgallery/lg-settings';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

function Gallery(props) {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [previewImages, setPreviewImages] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState<any>();
  useEffect(() => {
    setPreviewImages(props.gallery)
  }, [props.gallery, editMode]);

  let apiUrl = process.env.REACT_APP_BACKEND_URL

  useEffect(() => {
    dispatch(getGalleryItems());
  }, []);


  // const alpha = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "e",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  // ];

  const [images, setImages] = React.useState([
    "/img/gallery/01.png",
    "/img/gallery/02.png",
    "/img/gallery/03.png",
    "/img/gallery/04.png",
    "/img/gallery/05.png",
    "/img/gallery/06.png",
    "/img/gallery/07.png",
    "/img/gallery/08.png",
    "/img/gallery/09.png",
    "/img/gallery/010.png",
    "/img/gallery/011.png",
    "/img/gallery/012.png",
    "/img/gallery/013.png",
    "/img/gallery/014.png",
    "/img/gallery/015.png",
    "/img/gallery/016.png",
    "/img/gallery/017.png",
    "/img/gallery/018.png",
    "/img/gallery/019.png",
    "/img/gallery/020.png",
    "/img/gallery/021.png",
    "/img/gallery/022.png",
    "/img/gallery/023.png",
    "/img/gallery/024.png",
    "/img/gallery/025.png",
    "/img/gallery/026.png",
    "/img/gallery/027.png",
    "/img/gallery/028.png",
    "/img/gallery/029.png",
    "/img/gallery/030.png",
    "/img/gallery/031.png",
    "/img/gallery/01.png",
    "/img/gallery/02.png",
    "/img/gallery/03.png",
    "/img/gallery/04.png",
    "/img/gallery/01.png",
    "/img/gallery/02.png",
    "/img/gallery/03.png",
    "/img/gallery/04.png",
    "/img/gallery/015.png",
    "/img/gallery/016.png",
    "/img/gallery/017.png",
    "/img/gallery/018.png",
    "/img/gallery/019.png",
    "/img/gallery/020.png",
    "/img/gallery/021.png",
    "/img/gallery/022.png",
    "/img/gallery/023.png",
    // "/img/gallery/024.png",
    "/img/gallery/025.png",
    "/img/gallery/026.png",
    "/img/gallery/027.png",
    "/img/gallery/028.png",
    "/img/gallery/029.png",
    "/img/gallery/030.png",
    "/img/gallery/031.png",
    "/img/gallery/01.png",
    "/img/gallery/02.png",
    "/img/gallery/03.png",
    "/img/gallery/04.png",
    "/img/gallery/01.png",
    "/img/gallery/02.png",
    "/img/gallery/03.png",
    "/img/gallery/04.png",
  ]);

  function splitImages(images: any) {
    const alphas = [
      "a", "b", "c", "h", "j", "o", "r", "x", "d", "k", "l", "s", "y",
      "e", "f", "m", "p", "u", "t", "g", "i", "n", "q", "v", "w", "z",
      "aa", "ab", "ac", "ad", "ae"
    ];
    const alphaCount = alphas.length;
    const outputArrays = [];
    let currentIndex = 0;
    let number = images.length;

    while (number > 0) {
      const currentArray = [];
      for (let i = 0; i < alphaCount; i++) {
        if (number > 0) {
          if (images[number - 1].url) {
            let url = images[number - 1].url;
            const imageUrl = url.replace('/upload', '/upload/c_thumb,w_500,h_500');
            const originalUrl = url;

            // const imageUrl = apiUrl + '/api/images/thumb/' + images[number - 1].fileName;
            // const imageUrl = apiUrl + '/api/gallery/view-image-thumb?image=' + images[number - 1].fileName;
            // const originalUrl = apiUrl + '/api/gallery/view-image?image=' + images[number - 1].fileName;

            currentArray.push({ letter: alphas[currentIndex], originalUrl: originalUrl, url: imageUrl, image: images[number - 1] });
          } else {
            //empty image
            const imageUrl = '';
            currentArray.push({ letter: alphas[currentIndex], url: imageUrl, image: images[number - 1] });
          }

          currentIndex = (currentIndex + 1) % alphaCount;
          number--;
        } else {
          break;
        }
      }
      outputArrays.push(currentArray);
    }

    return outputArrays;
  }


  // async function imageUrlToBlobUrl(imageUrl) {
  //   const response = await fetch(imageUrl);
  //   const blob = await response.blob();
  //   const blobUrl = URL.createObjectURL(blob);
  //   return blobUrl;
  // }
  const handleEdit = (img: any) => {
    if (editMode) {
      setSelectedImage(img);
      handleClickOpen();
    }
  }


  const generateWH = (w: any, h: any) => {
    return { width: w, height: h }
  }


  const alphas = [
    { "a": generateWH(144, 94) },
    { "b": generateWH(94, 94) },
    { "c": generateWH(119, 94) },
    { "d": generateWH(174, 308) },
    { "e": generateWH(250, 138) },
    { "f": generateWH(127, 138) },
    { "g": generateWH(182, 200) },
    { "h": generateWH(174, 308) },
    { "i": generateWH(253, 185) },
    { "j": generateWH(369, 208) },
    { "k": generateWH(129, 94) },
    { "l": generateWH(248, 164) },
    { "m": generateWH(182, 101) },
    { "n": generateWH(253, 251) },

    { "o": generateWH(128, 64) },

    { "p": generateWH(186, 331) },

    { "q": generateWH(224, 143) },

    { "r": generateWH(132, 143) },
    { "s": generateWH(226, 143) },
    { "t": generateWH(215, 204) },
    { "u": generateWH(141, 204) },
    { "v": generateWH(146, 212) },
    { "w": generateWH(137, 77) },
    { "x": generateWH(110, 197) },
    { "y": generateWH(131, 182) },
    { "z": generateWH(323, 182) },
    { "aa": generateWH(128, 182) },
    { "ab": generateWH(215, 122) },
    { "ac": generateWH(141, 122) },
    { "ad": generateWH(201, 114) },
    { "ae": generateWH(82, 114) }
  ];

  function getValueByKey(key: any) {
    for (let i = 0; i < alphas.length; i++) {
      const obj = alphas[i];
      if (key in obj) {
        return obj[key];
      }
    }
    return null; // Key not found
  }

  const ShowCroppedImage = ({ image, editMode }) => {
    const [img, setImg] = useState<any>();


    useEffect(() => {
      const getImg = async () => {
        if (image.image?.croppedAreaPixels) {
          var croppedImage = await getCroppedImg(
            image.url,
            image.image?.croppedAreaPixels,
            image.image?.rotation ? image.image?.rotation : 0
          );
          setImg({ id: image.image?._id, letter: image?.letter, original_url: image.url, url: croppedImage, cropSize: getValueByKey(image?.letter) });
        }
        else {
          setImg({ id: image.image?._id, letter: image?.letter, original_url: image.url, url: image.url, cropSize: getValueByKey(image?.letter) });
        }
      }
      getImg();
    }, [])

    return img ?
      <>
        {img.original_url ?
          <div
            onClick={(e) => handleEdit(img)}
            className="gallery-item"
            // style={{ backgroundImage: "url(" + apiUrl +'/api/gallery/view?image='+foundItem.fileName + "&width=500&height=500)" }}
            style={{ backgroundImage: `url(${img.url})` }}
          >
            {/* {img.letter} */}
          </div>
          :
          <div className="gallery-item empty"
            onClick={(e) => handleEdit(img)}
          >
            <div className="image-overlay"></div>
          </div>
        }
      </>
      : <>

      </>
  };

  const renderGalleryItems = () => {
    // const img = splitImages(images);
    // console.log("props.gallery : ", props.gallery)

    let x = []
    if (editMode) {
      x.push('');
    }
    let p = [...x, ...previewImages];
    const img = splitImages(p);
    const galleryContent = [];
    // for (let i = 0; i < img.length; i++) {
    for (let i = 0; i < img.length; i++) {
      const content = (
        <>
          {/* <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">             */}
          {img[i].map((data) => (
            <div data-src={data.originalUrl} className={`gallery-block gallery-1 block-${data.letter}`}>
              <ShowCroppedImage image={data} editMode={editMode}></ShowCroppedImage>
            </div>
          ))}
          {/* </LightGallery> */}
        </>
      );
      galleryContent.push(content);
    }
    return <>{galleryContent}</>;
  };


  const [openCropPopup, setOpenCropPopup] = React.useState(false);
  const handleClickOpen = () => {
    setOpenCropPopup(true);
  };
  const handleClose = () => {
    setOpenCropPopup(false);
    setSelectedImage(null);
  };

  const hideToolbar = () => {
    const toolbar: any = document.querySelector('.lg-toolbar');
    toolbar.style.display = 'none';
  };
  if (props.gallery) {
    return (
      <>

        {props.home && props.home.authenticated && (
          <div className="edit-button-container">
            <button
              className={editMode ? "button-red" : "button-green"}
              onClick={() => {
                setEditMode(!editMode)
                // if(!editMode == false){
                // setShowGalleryItemForm(null)
                // }
              }}
            >
              {editMode ? "Cancel Edit Gallery Mode" : "Edit Gallery Mode"}
            </button>
          </div>
        )}
        <div className="gallery-section">
          <div className={editMode ? "home-gallery edit" : "home-gallery"}>
            {editMode ?

              <div className="gallery-set">
                {renderGalleryItems()}
              </div> :
              <LightGallery
                //  onInit={hideToolbar} 
                elementClassNames={"gallery-set"} plugins={[lgVideo]} mode="lg-fade">
                {renderGalleryItems()}
              </LightGallery>
            }
          </div>

        </div>

        {/* <button onClick={handleClickOpen}>OPENPOPUP</button> */}
        <CropImage
          image={selectedImage}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          openCropPopup={openCropPopup}
        />
      </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    gallery: state.home.gallery,
    home: state.home,
  };
};

export default connect(mapStateToProps)(Gallery);
