import React from "react";
import { Box, Container, Typography, Button } from '@mui/material';
import { Row, Col, Form } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import { useState } from "react";
import HorizontalScroll from 'react-horizontal-scrolling';




export default function ProjectListingDetails(props:any) {
    const [imageIndex, setImageIndex] = useState(0);
    const sliderC = React.useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setImageIndex(next),
    }
    const slider = React.useRef(null);
  return (
    <>
        <Box className="prjct_lstng_main_bx detail_main_bx">
            <Box className="hdng_bx">
                <Typography component="h3">
                    Welcome to Bitgraphix:
                    <span>OUR PROJECTS</span>
                </Typography>
                <Typography>projects we’ve built and sites we’ve made.</Typography>
            </Box>
            <Box className="cards_cntnt_bx">
                <Box className="detail_slider_bx">
                    <Box className="ovrflw_bx">
                        {/* <HorizontalScroll> */}
                            {/* {pictures.map((pic, idx) => (
                                <img
                                className="rounded w-56 h-64 object-cover"
                                src={pic}
                                key={idx} />
                            ))} */}
                            <Box className="three_dtl_bx">
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx">
                                        <Box className="links_bx">
                                            <Button>Pi Protocol</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.pi-protocol.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/slide_pi_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx nkts_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/slide_nekotoshi_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>Nekotoshi</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.nekotoshi.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="rght_bx jgsw_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.jpic.club</a>
                                        </Box>
                                        <Button>
                                            <span>JigSaw</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/slide_jigsaw_img.png' />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx df_dngn">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/defi_dngn_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.prism.com</a>
                                        </Box>
                                        <Button>
                                            <span>DeFi Dungeon</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx ecc_bx">
                                        <Box className="links_bx">
                                            <Button>Empire Capital Token</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.ecc.capital</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/ecc_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx upcro_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/upcro_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>upCRO</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.upcro.finance</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx rua_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.ecc.capital\RUA</a>
                                        </Box>
                                        <Button>
                                            <span>RUA</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/rua_img.png' />
                                    </Box>
                                </Box>
                                <Box className="rght_bx cat_bx">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/cat_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.cat-omega.vercel.app</a>
                                        </Box>
                                        <Button>
                                            <span>Capital Aggregator Token</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx diatm_bx">
                                        <Box className="links_bx">
                                            <Button>Diatom</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.diatom.fund</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/diatom_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx emprdx_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/empire_dex_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>Empire Dex</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.empiredex.org</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="rght_bx prsm_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.prism.com</a>
                                        </Box>
                                        <Button>
                                            <span>Prism</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/prism_img.png' />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx magin_bx">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/defi_magic_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.defimagic.io</a>
                                        </Box>
                                        <Button>
                                            <span>Magic</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx bggrmnd_bx">
                                        <Box className="links_bx">
                                            <Button>BiggerMINDS</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.biggerminds.io</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/bigger_mind_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx pbgb_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/pbgb_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>Push Button Get Banana</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.pbgb.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx greed_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.greedtoken.com</a>
                                        </Box>
                                        <Button>
                                            <span>$GREED</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/greed_img.png' />
                                    </Box>
                                </Box>
                                <Box className="rght_bx herchain_bx">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/herchain_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.herchain.com</a>
                                        </Box>
                                        <Button>
                                            <span>Herchain</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx lnshrp_bx">
                                        <Box className="links_bx">
                                            <Button>Longs and Sharps</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.longsandsharps.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/long_shrp_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx strgi_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/strigoi_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>Strigoi</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.strigoi.io</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="rght_bx klima_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.klima.com</a>
                                        </Box>
                                        <Button>
                                            <span>Klima</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/klima_img.png' />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx moonbts_bx">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/moonboots_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.moonboots.io</a>
                                        </Box>
                                        <Button>
                                            <span>Monboots</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="lft_bx">
                                    <Box className="lft_tp_bx deflect_bx">
                                        <Box className="links_bx">
                                            <Button>Deflect</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.deflect.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/deflect_img.png' />
                                        </Box>
                                    </Box>
                                    <Box className="lft_bttm_bx lft_tp_bx bfm_bx">
                                        <Box className="img_bx">
                                            <Box component="img" src='/img/slider/battleformars_img.png' />
                                        </Box>
                                        <Box className="links_bx">
                                            <Button>Battle For Mars</Button>
                                            <Box className="lnk_innr_bx">
                                                <a href="#" className="www_lnk">www.bfm.com</a>
                                                <a href="#">View Gallery</a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="three_dtl_bx">
                                <Box className="rght_bx blkvd_bx">
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/blockvoodoo_img.png' />
                                    </Box>
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.blockvoodoo.io</a>
                                        </Box>
                                        <Button>
                                            <span>BlockVooDoo</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="rght_bx sprtcs_bx">
                                    <Box className="links_bx">
                                        <Box className="lnk_innr_bx">
                                            <a href="#" className="www_lnk">www.spartacus.com</a>
                                        </Box>
                                        <Button>
                                            <span>Spartacus</span>
                                        </Button>
                                        <Box className="lnk_innr_bx">
                                            <a href="#">View Gallery</a>
                                        </Box>
                                    </Box>
                                    <Box className="img_bx">
                                        <Box component="img" src='/img/slider/spartacus_img.png' />
                                    </Box>
                                </Box>
                            </Box>
                        {/* </HorizontalScroll> */}
                    </Box>
                    <Box className="blck_bg_bx">
                        <Box className="bg_clr_bx"></Box>
                        <Button onClick={() => sliderC?.current?.slickNext()} className="rght_arrw_btn">
                            Slide to see more
                            <Box component="img" src='/img/slide_arrw_img.svg' />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box className="clck_hide_btn clck_view_btn">
            <Button>click to view all</Button>
        </Box>
        <Box className="cntnt_crtn_bx">
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} md={5} className="form_order">
                        <Box className="cntnt_crtn_frm">
                            <Box className="crtn_frm_ttl">
                                <Typography component="h6">CONTENT CREATION</Typography>
                                <Typography>A diverse team of talented artists producing preimum content for the blockchain space. </Typography>
                            </Box>
                            <Box className="crtn_frm_form">
                                <Box className="crtn_frm_form_ttl">
                                    <Typography>Let’s make the Blockchain a more beautiful place. For inquiries:</Typography>
                                </Box>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Control type="text" placeholder="Your name" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Control type="text" placeholder="Your email" />
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Control as="textarea" placeholder="Your message" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <Button className="cntct_btn">
                                            CONTACT
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} md={7} >
                        <Box className="smng_rgt">
                            <Typography component="h4">We provide best tech product</Typography>
                            <Box component="img" className="img-fluid" src='/img/video_img.png' />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Box>
    </>
  )
}
