export const nft5pool = [

  { 
    chainId: 3,
    id: '1',
    name: '',
    token: '',
    tokenDescription: '',
    tokenAddress: '',  // Staking Token 1
    tokenDecimals: 18,
    claimedTokenAddress: '', // Reward Token
    earnedTokenAddress: '',  // Reward Token
    earnContractAddress: '0x5cdcFF2A3493899e46ca40ae31f353C1E30Be574',  // Golden ticket
    status: 'aktive'
  },
]