import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { toast } from 'react-toastify';
import { fetchPostContactFormApi } from '../../../utils/backendApi';

export default function ContactForm() {

    const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [services, setServices] = useState<any>({value:"all"});
  const [message, setMessage] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [isInvalidEmail, setIsInvalidEmail] = useState<any>();

  // const isError = (name === '' || email === '' || services === '' || message === '')
  const notify = () => toast("Thankyou for contacting us!");

  const sendForm = async () => {
    if (
      name === "" ||
      email === "" ||
      services === "" ||
      message === "" ||
      !validateEmail(email)
    ) {
      setError("Please fill out all fields.");
      return;
    }

    let msg = {
      name: name,
      email: email,
      services: services.value,
      message: message,
    };

    console.log("SEND : ", msg)

    // Send data to the backend via POST
    let resp = await fetchPostContactFormApi("api/contactus", msg);
    console.log("RESP : ", resp)
    notify();
    setError("");
  };

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <>
        <Element name='Contact'>
            <Box className="cntnt_crtn_frm">
                <Box className="crtn_frm_ttl">
                    <Typography component="h6">CONTENT CREATION</Typography>
                    <Typography>A diverse team of talented artists and hardcore programmers producing preimum content for the blockchain space. </Typography>
                </Box>
                <Box className="crtn_frm_form">
                    <Box className="crtn_frm_form_ttl">
                        <Box component="img" src="/img/blck_logo.png" className="img-fluid" alt="" />
                        <Typography>Let’s make the Blockchain a beautiful place. For inquiries:</Typography>
                    </Box>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Control type="text" placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Control type="text" placeholder="Your email" 
                                value={email} onChange={(e) => {
                              setEmail(e.target.value);

                              if (e.target.value != "") {
                                if (!validateEmail(e.target.value)) {
                                  setIsInvalidEmail(true);
                                } else {
                                  setIsInvalidEmail(false);
                                }
                              } else {
                                setIsInvalidEmail(false);
                              }
                            }}/>
                            </Form.Group>
                        </Col>
                        
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Control as="textarea" placeholder="Your message" value={message}
                        onChange={(e) => setMessage(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="text-center">
                            <Button className="cntct_btn"  onClick={sendForm}>
                            CONTACT us
                            </Button>
                        </Col>
                    </Row>
                </Box>
            </Box>
        </Element>
    </>
  )
}
