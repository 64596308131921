import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Link } from "react-scroll";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["EN", "SP", "FR"];

function getStyles(name: any, personName: any, theme: any) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Header(props) {
  const { connected, address, connectWallet, disconnectWallet } = props;
  console.log("--------0-0-0-0", connected);
  console.log("--------0-0-0-1", address);
  console.log("--------0-0-0-2", connectWallet);
  console.log("--------0-0-0-3", disconnectWallet);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState(["EN"]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  ////
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
      console.log("move header to the top");
    });
  }, []);

  const [shortAddress, setShortAddress] = useState("");
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (!connected) return;
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 3)}..${address.slice(-3)}`);
    }
  }, [dataUrl, address, connected]);

  return (
    <>
      <header className={scroll ? "header bgdrk header-top" : "header"}>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">
            <Box component="img" src="/img/logo.png" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <NavDropdown title="Shop" id="shop-dropdown">
                <NavLink to="/nft-gallary">NFTS</NavLink>
                <a href="https://bitgraphix.io/shop/" target="_blank">
                  MERCH
                </a>
              </NavDropdown> */}
              {/* <NavDropdown title="Projects" id="projects-dropdown">
                            <Link to="/">Items</Link>
                            <Link to="/">Items</Link>
                            <Link to="/">Items</Link>
                            <Link to="/">Items</Link>
                        </NavDropdown> */}
              <NavLink to="/project-listing" className="nav-link">
                Projects
              </NavLink>
              {/* <NavLink to="/about-us" className="nav-link">
              About
              </NavLink> */}
              

              {/* <a
                rel="nofollow noreferrer"
                target="_blank"
                href="https://bitgraphix.io/"
                className="nav-link"
              >
                News
              </a> */}

              <NavLink to="/bitgraphixabout" className="nav-link">About</NavLink>
              {/* <Link to="/" className="nav-link">news</Link> */}
            </Nav>
            <Box className="ms-auto">
              {/* <Link
                      to='Contact'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-60}
                      duration={500}
                      className="cntctbtn"    onClick={connected ? disconnectWallet : connectWallet}>
                        
                        {connected ? (
    
    <span>  {shortAddress} </span>
  ) : 
  "Connect Wallet"
  }

                      </Link> */}
              {/* <Link
                to="Contact"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
                className="cntctbtn"
              >
                CONTACT
              </Link> */}
            </Box>
          </Navbar.Collapse>
          <Box className="ms-auto">
            {/* <FormControl className="lngslctprnt">
                            <Select
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="cstmlng_slsct"
                            >
                            <MenuItem disabled value="">
                            LG
                            </MenuItem>
                            {names.map((name) => (
                                <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, personName, theme)}
                                >
                                {name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl> */}
          </Box>
        </Navbar>
      </header>
    </>
  );
}

export default Header;
