import { Box, Button, Typography, } from '@mui/material'
import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import TabOne from './TabOne';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function TeamNFT() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className='user_list_main team_nft_main'>
        <Typography className='dflt_h1_usr_list' component="h1">Team NFT Control Panel</Typography>
        <Box className='usr_list_in_bx'>
          <Box className='usr_list_header'>
            <Typography component="h2">NFTs</Typography>
          </Box>
          <Box>
            <Box sx={{ width: '100%' }} className='castm_tabs'>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Box className='tab_raight_btm_grop'>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs_widh_hith'>
                    <Tab className='btn_grop' label="Admins" {...a11yProps(0)} />
                    {/* <Tab className='btn_grop' label="Admins" {...a11yProps(1)} />
                <Tab className='btn_grop' label="Managers" {...a11yProps(2)} /> */}
                  </Tabs>
                  <Box className='btn_right'>
                    <Button className='link_btn_two'>
                      <Link to="/nftmember"><img src='./img/raight_btn_img.png' className='tab_mriht' alt='' />Create new NFT</Link>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <TabPanel value={value} index={0}>
                <TabOne />
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
              <TabOne />
              </TabPanel>
              <TabPanel value={value} index={2}>
              <TabOne />
              </TabPanel> */}
            </Box>
          </Box>
          <Box className='tabl_pgntn'>
            <Pagination count={10} />
          </Box>
        </Box>

      </Box>
    </>
  )
}
