import React from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, } from 'react-bootstrap';
import BackArrow from '@mui/icons-material/ArrowBack';
import LinkIcon from '@mui/icons-material/Link';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OurNftStore() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <>

          <Box className="nft_str_box nft_str_box_gallary">
              {/* <Box className="ttl_back" /> */}
              <Box className='p_rltv_bx'>
                {/* <Box className='box_clr_bg' /> */}
                <Box className='collps_box'>
                  <Box className='bitgr_tab_box'>
                        <Typography component="h3" className="def_bl_ttl vist_stor_titl">
                        <span>View</span> <span className="cmng_sn_txt">our own collection</span><br/>
                        our Bitgraphix NFTs
                      </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Box className="ris_btn_two">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="span_bg_n">
                          <Tab label="Bitgraphix Original" {...a11yProps(0)} className="stk_btn" icon={<Box className='b_img'><Box component="img" src='/img/b_tab_img.svg' className='' /></Box>}/>
                          <Tab label="Client Collection" {...a11yProps(1)} className="stk_btn margi_r" icon={<Box className='b_img'><Box component="img" src='/img/b_tab_img.svg' className='' /></Box>}/>
                        </Tabs>
                      </Box>
                      <Box className='pading_tab'>
                        <TabPanel value={value} index={1}>
                          <Row >
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                <video width="100%" muted loop autoPlay>
                                  <source src="/img/col_vido_01.webm" type="video/mp4"></source>
                                </video>
                                <Box className='min_h_box'>
                                  <Typography component="h3" className="the_fir_h3">The first official Bitgraphix NFT!<br />- Limited Edition -</Typography>
                                    <Box className='totel_img_box'>
                                      <Typography component="h5">total mint: <span>12/100</span></Typography>
                                      <Box component="img" src='/img/mint_img.svg' className='' />
                                      <Typography component="h4">1 NFT <span>0.132 ETH</span></Typography>
                                    </Box>
                                  <Typography className='bit_col_p'>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                                  <Button className='for_btn'>Buy NFT</Button>
                                </Box>
                                
                            </Box>
                          </Col>
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_02.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                    <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={3}>
                          <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_03.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                      <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_04.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                      <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                <video width="100%" muted loop autoPlay>
                                  <source src="/img/col_vido_01.webm" type="video/mp4"></source>
                                </video>
                                <Box className='min_h_box'>
                                  <Typography component="h3" className="the_fir_h3">The first official Bitgraphix NFT!<br />- Limited Edition -</Typography>
                                    <Box className='totel_img_box'>
                                      <Typography component="h5">total mint: <span>12/100</span></Typography>
                                      <Box component="img" src='/img/mint_img.svg' className='' />
                                      <Typography component="h4">1 NFT <span>0.132 ETH</span></Typography>
                                    </Box>
                                  <Typography className='bit_col_p'>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                                  <Button className='for_btn'>Buy NFT</Button>
                                </Box>
                                
                            </Box>
                          </Col>
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_02.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                    <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={3}>
                          <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_03.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                      <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          <Col xs={12} sm={6}  md={6} lg={3}>
                            <Box className='man_box_colps'>
                                  <Box component="img" src='/img/tab_col_04.png' className='img-fluid' />
                                  <Box className='min_h_box'>
                                      <Typography component="h3" className="the_fir_h3">Coming Soon<br/> Bitgraphix NFT</Typography>
                                      <Box className='totel_img_box jut_con_c'>
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box component="img" src='/img/mint_img.svg' className='' />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                      </Box>
                                    <Typography className='bit_col_p bit_col_p_02'>This collectors series of 3d Animated ETH inspired NFTs will drop throughout April </Typography>
                                    <Button className='for_btn cominsoon_btn'>Coming Soon</Button>
                                  </Box>
                                  
                              </Box>
                          </Col>
                          </Row>
                        </TabPanel>
                        <TabPanel value={value} index={0}>
                          Item Two
                        </TabPanel>
                      </Box>
                  </Box>
                  </Box>
                </Box>
                {/* <Button className='vew_aro_btn'> <Box className='viw_aro'>Vew all NFT’s<Box component="img" src='/img/Arrow_right.svg' /></Box> <Typography>gallery coming soon</Typography></Button> */}
              </Box>
          </Box>
        </>
    )
}
