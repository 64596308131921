import React, { useState } from "react";
// import FileUploadNft from '../components/defualt/FileUploadNft'
 
import FileUploadNft from '../../components/defualt/FileUploadNft'

import { Box, Button, Typography, Grid } from '@mui/material'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";

export default function NFTMember() {
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState({});

  const changeFileHandle = (event: any) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    console.log(file, objectUrl);
    setSelectedFile(file);
  }


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMember, setOpenMember] = React.useState(false);
  const handleOpenMember = () => setOpenMember(true);
  const handleCloseMember = () => setOpenMember(false);
  return (
    <>
      <Box className='user_list_main'>
        <Typography className='dflt_h1_usr_list' component="h1">Team NFT Control Panel</Typography>
        <Box className='usr_list_in_bx usr_list_in_bx_v2'>
          <Box className='usr_list_header flx_clm_bx'>
            <Typography component="h2">NFTs</Typography>
            <Link to="/team-nft" className='back_btn_nft'>
              <img src='./img/back_arrow.svg' alt='' />
              Back
            </Link>
          </Box>
          <Box className='edit_nft_main nfts_main_two' >
            <Box className='inr_nft_body'>
           
              {/* <Box className='black_mmbr_bx black_mmbr_bx_tim_mmbr'>
                <img src='./img/black_bx_ic.png' alt='' className="big_ic_blk_bg" />
                <Typography component="h4">Team Member</Typography>
                <Button>
                  <Typography>Update NFT</Typography>
                  <img src='./img/pen_ic.svg' alt='' />
                </Button>
              </Box> */}

              <Box className='black_mmbr_bx'>
                <img src='./img/black_bx_ic.png' alt='' className='blck_bg_ic' />
                <Typography component="h4">Team Member</Typography>
                <Box className='fil_nil'>
                    <Box className="aplord_box_ab">
                      <Box className="drgs_drps_bx drgs_drps_bx_nl">
                        <FileUploadNft file={file} setSelectedFile={setSelectedFile} />
                      </Box>
                    </Box>
                  </Box>
                <Box className='nft_ladt_input'>
                  <TextField id="outlined-basic" label="Or paste link" variant="outlined" />
                  <img src='./img/wlt_adrs_ic.png' alt='' />
                </Box>
              </Box>
              <Box className='right_nft_inpts'>
                <Box className='top_p_main_rgt'>
                  <Typography>Here will be some explanation for not cunfusing here will be some explanation for not cunfusing</Typography>
                </Box>
                <Box className='three_radio_prnt'>
                  <Box className="radio_me">
                    <input type="radio" id="radioone" name="fisrt_radio" value="radiovalue" />
                    <label htmlFor="radioone">
                      <span></span>
                      User NFT
                    </label>
                  </Box>
                  <Box className="radio_me">
                    <input type="radio" id="radiotwo" name="fisrt_radio" value="radiovalue" />
                    <label htmlFor="radiotwo">
                      <span></span>
                      Admin NFT
                    </label>
                  </Box>
                  <Box className="radio_me">
                    <input type="radio" id="radiothree" name="fisrt_radio" value="radiovalue" />
                    <label htmlFor="radiothree">
                      <span></span>
                      Manager NFT
                    </label>
                  </Box>


                  {/* <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <Box className='radio_inr_nft'>
                        <Box className='radio_bx_clik'>
                          <FormControlLabel value="female" control={<Radio />} label="User NFT" />
                        </Box>
                        <Box className='radio_bx_clik'>
                          <FormControlLabel value="male" control={<Radio />} label="Admin NFT" />
                        </Box>
                        <Box className='radio_bx_clik'>
                          <FormControlLabel value="other" control={<Radio />} label="Manager NFT" />
                        </Box>
                      </Box>
                    </RadioGroup>
                  </FormControl> */}
                </Box>
                <Box className='inpts_nft_edit_right'>
                  <TextField id="outlined-basic" label="ID user" variant="outlined" />
                  <Box className='nft_ladt_input'>
                    <TextField id="outlined-basic" label="User wallet address" variant="outlined" />
                    <img src='./img/wlt_adrs_ic.png' alt='' />
                  </Box>
                  <TextField id="outlined-basic" label="User name" variant="outlined" />
                </Box>
                <Box className='mint_btn_bx'>
                  <Button className='mint_btn' onClick={handleOpenMember}>Mint NFT</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>


      {/* Modal */}
      <div className='modal_div_user'>
        <Modal
          keepMounted
          open={openMember}
          onClose={handleCloseMember}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='user_modal_inr_main mint_modal_inr_main'>
            <Box className='mdl_hdr_usr'>
              <Button className='back_btn_usr' onClick={handleCloseMember}>
                <img src='./img/back_arrow.svg' alt='' />
                Back
              </Button>
            </Box>
            <Box className='mint_mdl_in_tx_bx'>
              <Typography component="h2">Memner NFT</Typography>
              <Typography>Are you sure you want to mint additional Admin NFT?</Typography>
              <Button>Mint User NFT</Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  )
}
