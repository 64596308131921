import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const BL_BASE_URL = process.env.REACT_APP_BL_BACKEND_URL;
export const getToken = () => {
  return localStorage.getItem('bluToken');
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem('bluUser') as string);
}

export const fetchGetApi = async (query: string, withAuth?: boolean) => {
  let token: string | null = "";
  try {
    if (withAuth) token = getToken();
    const response = await fetch(`${BASE_URL}/${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      console.log("Sorry the server is not available right now please try later")
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchPostApi = async (query: string, body: any, withAuth?: boolean) => {
  let token: string | null = "";
  try {
    if (withAuth) token = getToken();

    const response = await fetch(`${BASE_URL}/${query}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    // const data = await response.json();

    return response;

    // if (response.status === 200 || response.status === 201) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 400) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 403) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 500) {
    //   const data = await response;
    //   console.log("Sorry the server is not available right now please try later")
    //   return data;
    // }
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const fetchPostContactFormApi = async (query: string, body: any, withAuth?: boolean) => {
  let token: string | null = "";
  try {
    if (withAuth) token = getToken();

    const response = await fetch(`${BL_BASE_URL}/${query}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    // const data = await response.json();

    return response;

    // if (response.status === 200 || response.status === 201) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 400) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 403) {
    //   const data = await response;
    //   return data;
    // } else if (response.status === 500) {
    //   const data = await response;
    //   console.log("Sorry the server is not available right now please try later")
    //   return data;
    // }
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const fetchPutApi = async (query: string, body: any, withAuth?: boolean) => {
  let token: string | null = "";
  let response;
  try {
    if (withAuth) token = getToken();
    response = await fetch(`${BASE_URL}/${query}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    console.log('test-response9', response);
    // const data = await response.json();
    return response;
    // if (response.status === 200 || response.status === 201) {
    //   const data = await response.json();
    //   return data;
    // } else if (response.status === 400) {
    //   const data = await response.json();
    //   return data;
    // } else if (response.status === 403) {
    //   return false;
    // } else if (response.status === 500) {
    //   console.log("Sorry the server is not available right now please try later")
    // }
  } catch (error) {
    console.log('test-error', error);
    console.log('test-response', response);
    return response;
  }
}