import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function Footer() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function (currentSlide, nextSlide) {
            //   console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            //   console.log("after change", currentSlide);
        }
    }
    return (
        <>
            <Box className="marquee_bg_color">
                <div className="marquee">
                    <span>WORLD CLASS CREATIVES FULL SPECTRUM PARTNER Bitgraphix CRYPTO ROCKET FUEL Bitgraphix Design for THE Blockchain success Engineering Bitgraphix Premium VISUAL CONTENT FOr Bitcoin and THE Blockchain Bitgraphix WORLD CLASS CREATIVES FULL SPECTRUM PARTNER CRYPTO ROCKET F</span>
                </div>
            </Box>

            <Box className="footer">
                <Box component="img" src='/img/ftr_bg_logo.png' className="ftr_bg_lgl" />
                {/* <Box component="img" className="foter_ic" src='/img/foter_ic.svg' /> */}
                <Container>
                    <Row className="align-items-center">
                        {/* <Col xs={12} md={6} className="ftrlg_bx">
                            <Link to="/">
                                <Box component="img" className="img-fluid" src='/img/footer_logo.png' />
                            </Link>
                            <Typography>design for the blockchain</Typography>
                        </Col> */}
                        <Col xs={12} md={12} xl={12} xxl={12} className="ftrlg_bx mt-5">
                            <Box className="for_centar_flx">
                                <Link to="/">
                                    <Box component="img" className="img-fluid footer_logo" src='/img/footer_logo.png' />
                                    <Box component="img" className="img-fluid foot_img_smlo_ic_01" src='/img/foot_img_smlo_ic_01.svg' />
                                    <Box component="img" className="img-fluid foot_img_smlo_ic_02" src='/img/foot_img_smlo_ic_02.svg' />
                                </Link>
                                <Box className="for_foot_sid">
                                    <Slider {...settings}>
                                        <div className='for_crnt_slid_contin'>
                                            <Typography>design for the blockchain</Typography>
                                        </div>
                                        <div>
                                            <Typography>premium visual content</Typography>
                                        </div>
                                        <div>
                                            <Typography>crypto rocket fuel</Typography>
                                        </div>
                                        <div>
                                            <Typography>world class creatives</Typography>
                                        </div>
                                    </Slider>
                                </Box>


                            </Box>

                        </Col>
                        <Col xs={12} md={12} xl={12} xxl={12} className="ftrlg_bx for_p_0">
                            <Box className="footear_lin_gri_min">
                                <Box className="footear_lin_bx devlig_img_a">
                                    <Box component="img" className="img-fluid" src='/img/devlig_foot_img.png' />
                                    <Box className="devlig_img_a_p">
                                        <Typography>DEV Legion</Typography>
                                        <a href='https://www.devlegion.io/' target="_blank" className="devlig_img_a">
                                            devlegion.io<ArrowOutwardIcon />
                                        </a>
                                    </Box>
                                </Box>

                                <Box className="footear_lin_bx devlig_img_a">
                                    <Box component="img" className="img-fluid" src='/img/blockgurus.png' />
                                    <Box className="devlig_img_a_p">
                                        <Typography>Block Gurus</Typography>
                                        <a href='https://www.blockgurus.ai/' className="">blockgurus.ai<ArrowOutwardIcon /></a>
                                    </Box>
                                </Box>

                                <Box className="footear_lin_bx devlig_img_a">
                                    <Box component="img" className="img-fluid" src='/img/blockledr.png' />
                                    <Box className="devlig_img_a_p">
                                        <Typography>BlockLeader</Typography>
                                        <a href='https://www.blockleader.io/' target="_blank" className="">blockleader.io<ArrowOutwardIcon /></a>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Row className="">
                                <Col xs={12} md={4} className="">

                                </Col>
                                <Col xs={12} md={4} className="">
                                    <Box className="footear_lin_bx">
                                        <Typography>DEV Legion</Typography>
                                        <a href='/' className="">devlegion.io<ArrowOutwardIcon /></a>
                                    </Box>
                                </Col>
                                <Col xs={12} md={4} className="">
                                    <Box className="footear_lin_bx">
                                        <Typography>DEV Legion</Typography>
                                        <a href='/' className="">devlegion.io<ArrowOutwardIcon /></a>
                                    </Box>
                                </Col>
                            </Row> */}
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <Box className="ftrscl_bx">
                                <Typography>Subscribe social media:</Typography>
                                <a href="#" target="_blank">
                                    <Box component="img" src='/img/twitter_ic.svg' />
                                </a>
                                <a href="#" target="_blank">
                                    <Box component="img" src='/img/youtube_ic.svg' />
                                </a>
                                <a href="#" target="_blank">
                                    <Box component="img" src='/img/vimeo_ic.svg' />
                                </a>
                                <a href="#" target="_blank">
                                    <Box component="img" src='/img/instagram_ic.svg' />
                                </a>
                            </Box>
                        </Col> */}
                        {/* <Col xs={12}>
                            <hr className="ftrhr" />
                        </Col> */}
                        {/* <Col xs={12} lg={6}>
                            <Row>
                                <Col xs={12} md={5} className="ftrlnks_v2">
                                    <Typography component="h5">Community Support</Typography>
                                    <Link to="/">FAQ</Link>
                                    <Link to="/">Visit our Store</Link>
                                    <Link to="/">Terms and privacy</Link>
                                </Col>
                                <Col xs={12} md={7} className="ftrlnks_v2">
                                    <Typography component="h5">Community Support</Typography>
                                    <Link to="/">Mission and team</Link>
                                    <Link to="/">Contact</Link>
                                    <Link to="/">Recent work</Link>
                                </Col>
                            </Row>
                        </Col> */}
                        {/* <Col xs={12} lg={6}>
                            <Box className="ftr_form">
                                <Row>
                                    <Col xs={12}>
                                        <Typography component="h5">Work with us:</Typography>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Control type="text" placeholder="Email" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Control type="text" placeholder="Name" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="p_rltv">
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Control as="textarea" rows={3} placeholder="Message" />
                                        </Form.Group>
                                        <Button className="sbmtfrm_btn">
                                            <Box component="img" src='/img/msg_ic.svg' />
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Col> */}
                        {/* <Col xs={12}>
                            <hr className="ftrhr mt-3" />
                        </Col> */}
                        {/* <Box className='min_height_180' /> */}
                        {/* <Col xs={12} className="lastftr">
                            <Typography>© 2021 Bitgraphix. All rights reserved.</Typography>
                        </Col> */}
                    </Row>
                    <Typography className="bit_resevb_p">© 2021 Bitgraphix. All rights reserved.</Typography>
                </Container>
            </Box>

        </>
    )
}

export default Footer
