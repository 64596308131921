import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ProductsSlider from './partitions/ProductsSlider';
import Gallary from './partitions/Gallary';
import { useParams } from 'react-router-dom';
import portfolioData from '../../../data/portfoliosB.json';
import MultipleGridImages from 'react-multiple-image-grid';

const TemplateB = (props) => {
  const { projectName }: any = useParams();
  const [data, setData] = useState<any>();
  console.log(projectName);
  useEffect(() => {
    const portData = portfolioData.filter((portfolio) => {
      if (portfolio.id == projectName) {
        return portfolio;
      }
      return;
    });

    setData(portData[0]);
  }, [projectName]);
  return (
    <>
      {data && (
        <Box
          className='cmn_tp_ttl'
          style={{
            background: 'url(' + data.banner + ') no-repeat center 0',
          }}
        >
          <Container>
            <Typography component='h3'>{data && data.title}</Typography>
          </Container>
        </Box>
      )}
      <Box className='prjcts_main'>
        <Container>
          <Row>
            <Col xs={12} className='mt-5 mb-5'>
              <Box className='pridnt'>
                <Box className='pr_logo_bx'>
                  <Box
                    component='img'
                    className='img-fluid'
                    src={data && data.logo}
                  />
                </Box>
                <Typography component='h5'>{data && data.title}</Typography>
              </Box>
            </Col>
            <Col xs={12} className='mb-5'>
              <Typography className='pro_p'>
                {data && data.logo_info}
              </Typography>
            </Col>
            <Col xs={12} className='mb-5'>
              <Box className='vd_box_v1'>
                <Box className='tmplt_img01'>
                  <Box
                    component='img'
                    src={data && data.product_image}
                    className='img-fluid'
                    alt=''
                  />
                </Box>
                <Box className='vd_content'>
                  <Typography component='h4' className='def_h4'>
                    {data && data.product_title}
                  </Typography>
                  <Typography className='pro_p mb-4'>
                    {data && data.product_text_one}{' '}
                  </Typography>
                  <Typography className='pro_p'>
                    {data && data.product_text_two}
                  </Typography>
                </Box>
              </Box>
            </Col>

            <Col xs={12} lg={6} className='mb-5'>
              <Typography component='h2' className='def_h2'>
                {data && data.title_two}
              </Typography>
            </Col>
            <Col xs={12} className='mb-5'>
              <Typography component='h4' className='def_h4'>
                {data && data.title_three}
              </Typography>
              <Typography className='pro_p mb-5'>
                {data && data.title_info}
              </Typography>
              <Box className='tmplt_mdlcntr'>
                {data &&
                  data.logos.map((logo, index) => (
                    <Box
                      component='img'
                      className='img-fluid'
                      src={logo}
                      key={index + 'logo'}
                    />
                  ))}
              </Box>
            </Col>
            <Col xs={12} className='mb-5'>
              <Typography component='h4' className='def_h4'>
                {data && data.image_title}
              </Typography>
              <Typography className='pro_p'>
                {data && data.title_info_four}
              </Typography>
            </Col>

            <MultipleGridImages images={data && data.images_img_group} />
            {/* <Col xs={12} md={8} className='mb-4 tmplt_b_lut_08'>
              <Box
                component='img'
                className='img-fluid w-100'
                src='/img/tmplt_b/tmplt_b_img01.png'
              />
            </Col>
            <Col xs={12} md={4} className='mb-4 tmplt_b_lut04'>
              <Box
                component='img'
                className='img-fluid w-100 mb-4'
                src='/img/tmplt_b/tmplt_b_img02.png'
              />
              <Box
                component='img'
                className='img-fluid w-100'
                src='/img/tmplt_b/tmplt_b_img03.png'
              />
            </Col> */}

            {/* For single Image */}
            {/* <Col xs={12} className='mb-5'>
              <Box
                component='img'
                className='img-fluid w-100'
                src='/img/tmplt_b/single_img.png'
              />
            </Col> */}
            {/* For single Image */}
            <Col xs={12} lg={6} className='mb-5 mt-5'>
              <Typography component='h2' className='def_h2'>
                {data && data.gallary_title}
              </Typography>
            </Col>
            <Col xs={12}>
              {data && data.gallary_img && (
                <ProductsSlider images={data && data.gallary_img} />
              )}
            </Col>
            <Col xs={12}>
              {data && data.thumb_img && (
                <Gallary images={data && data.thumb_img} />
              )}
            </Col>
          </Row>
        </Container>
      </Box>
    </>
  );
};

export default TemplateB;
