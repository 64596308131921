import React from 'react';
import BigPicture from '@instaffogmbh/react-bigpicture';
import { Box, Typography } from '@mui/material';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export default function Gallary({ images }) {
  return (
    <>
      <Box className='gallary_box gallary_box_v2'>
        <Container>
          <Row>
            {images.map((image) => (
              <Col xs={6} sm={6} md={3} lg={2} className='mb-3'>
                <Box className='vd_bx_h'>
                  <BigPicture
                    type='img'
                    caption='Example of an optional caption.'
                    src={image}
                  >
                    <img src={image} alt='' className='img-fluid w-100' />
                  </BigPicture>
                  <Box
                    component='img'
                    src='/img/view_icon.svg'
                    className='view_btn'
                  />
                </Box>
              </Col>
            ))}
          </Row>
        </Container>
      </Box>
    </>
  );
}
