import axios from "axios";
import { API_URL } from "../constants";

const baseUrl = API_URL

export function getNfts(page = 1) {
  return async (dispatch) => {
    axios({
      method: 'get',
      url: `${baseUrl}/api/user-nft/getAll?&page=${page}`
    }).then((res: any) => {
      dispatch({
        type: "GET_NFTS",
        nfts: res.data.result
      });
    }).catch(err => {
      console.log(err);
    });
  }
}

export const getNftsWithRole = (page = 1, role: string) => async (dispatch) => {
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-nft/getAllWithRole?&page=${page}&role=${role}`
  }).then((res: any) => {
    dispatch({
      type: "GET_NFTS",
      nfts: res.data.result
    });
  }).catch(err => {
    console.log(err);
  });
}

export const addNft = (token, data) => async (dispatch) => {
  axios({
    method: 'post',
    url: `${baseUrl}/api/user-nft/create`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }).then(res => {
    getNfts();
  });
}

export const updateNft = (token: string | null, data) => async (dispatch) => {
  axios({
    method: 'post',
    url: `${baseUrl}/api/user-nft/transfer`,
    data: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }).then(res => {
    getNfts();
  });
}


export const getLastNftId = () => async (dispatch) => {
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-nft/getLastId`
  }).then(res => {
    if (res) {
      dispatch({
        type: 'GET_LAST_NFT_ID',
        nftId: res.data.result[0].nft_token
      });
    }
  });
}

export function reducer(state, action) {
  switch (action.type) {
    case "GET_NFTS":
      return {
        ...state,
        nfts: action.nfts,
      };
    case "GET_LAST_NFT_ID":
      return {
        ...state,
        nftId: action.nftId,
      };
    default:
      return state;
  }
}
