import React, {useEffect} from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ProductsSlider from './partitions/ProductsSlider';
import Gallary from './partitions/Gallary';


export default function TemplateA(props:any) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Box className="cmn_tp_ttl" style={{background: 'url(/img/tmplt_a/head_img.jpg) no-repeat center 0'}}>
                <Container>
                    <Typography component="h3">nekotoshi</Typography>
                </Container>
            </Box>
            <Box className="prjcts_main">
                <Container>
                    <Row>
                        <Col xs={12} className="mt-5 mb-5">
                            <Box className="pridnt">
                                <Box className="pr_logo_bx">
                                    <Box component="img" className="img-fluid" src='/img/tmplt_a/tmplt_logo.png' />
                                </Box>
                                <Typography component="h5">nekotoshi</Typography>
                            </Box>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography className="pro_p">
                            Immerse yourself in the massive online fantasy world of DeFi Dungeon! Stake your claim with one of two factions, and gain power and wealth in a virtual economy that includes crafting, farming, foraging, and more. Buy your Council Seat NFTs now to earn rewards from the treasury and participate in building the world of Defi Dungeon.
                            </Typography>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Box className="vd_box_v1">
                                <Box className="tmplt_img01">
                                    <Box component="img" src="/img/tmplt_a/tmplt_img_01.png" className="img-fluid" alt="" />                                
                                </Box>
                                <Box className="vd_content">
                                    <Typography component="h4" className="def_h4">nekotoshi</Typography>
                                    <Typography className="pro_p mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vel quis id. Nec ultrices amet, at turpis dignissim aliquam. Nullam semper suspendisse risus purus, in sollicitudin habitant. Arcu tincidunt elit euismod mattis cras ipsum. Lectus at mauris a ac semper neque. Euismod pellentesque fringilla at bibendum suspendisse lectus </Typography>
                                    <Typography className="pro_p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper libero sed aliquam, nulla enim elementum. Nibh maecenas libero aliquet turpis eu et in purus. Sed arcu magnis id quis. Duis eget sed massa eget accumsan mattis volutpat egestas velit. Sit urna eget eu cras leo. Volutpat mattis nunc ultrices urna. Eu tempor,</Typography>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography className="pro_p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut vel quis id. Nec ultrices amet, at turpis dignissim aliquam. Nullam semper suspendisse risus purus, in sollicitudin habitant. Arcu tincidunt elit euismod mattis cras ipsum. Lectus at mauris a ac semper neque. Euismod pellentesque fringilla at bibendum suspendisse lectus feugiat tempus fringilla.</Typography>
                        </Col>
                        
                        <Col xs={12} lg={6} className="mb-5">
                            <Typography component="h2" className="def_h2">graphic elements</Typography>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography component="h4" className="def_h4">Logotype</Typography>
                            <Typography className="pro_p mb-5">They are everywhere - treasures - just waiting to be found. If we look back on our history, we will be faced with treasures everywhere - were treasure hunters have been looking for. If many people are driven from the primal instinct to search for treasures, why not combine that with the crypto world? A project with the fascination of the treasure hunt. The Pi Protocol combines exactly this! A unique new game in the DeFi area. Manage your income while you can go on a real treasure hunt!</Typography>
                            <Box className='tmplt_mdlcntr'>
                                <Box component="img" className="img-fluid" src='/img/tmplt_a/nkts_lg_01.png' />
                                <Box component="img" className="img-fluid" src='/img/tmplt_a/nkts_lg_02.png' />
                                <Box component="img" className="img-fluid" src='/img/tmplt_a/nkts_lg_03.png' />
                            </Box>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography component="h4" className="def_h4">images</Typography>
                            <Typography className="pro_p">They are everywhere - treasures - just waiting to be found. If we look back on our history, we will be faced with treasures everywhere - were treasure hunters have been looking for. If many people are driven from the primal instinct to search for treasures, why not combine that with the crypto world? A project with the fascination of the treasure hunt. The Pi Protocol combines exactly this! A unique new game in the DeFi area. Manage your income while you can go on a real treasure hunt!</Typography>
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img01.jpg' />
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img02.jpg' />
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img03.jpg' />
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img04.jpg' />
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img05.jpg' />
                        </Col>
                        <Col xs={6} md={4} className="mb-4">
                            <Box component="img" className="img-fluid w-100" src='/img/tmplt_a/layout_img06.jpg' />
                        </Col>
                        
                        <Col xs={12} lg={6} className="mb-5 mt-5">
                            <Typography component="h2" className="def_h2">gallery</Typography>
                        </Col>
                        <Col xs={12}>
                            <ProductsSlider />
                        </Col>
                        <Col xs={12}>
                            <Gallary />
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>
    )
}
