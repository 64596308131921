import React, {useEffect} from 'react'
import { Box, Container, Typography, Button } from '@mui/material';
import { Row, Col, Form } from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default function ProjectListing() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
  return (
    <>
        <Box className="prjct_lstng_main_bx">
            <Box className="hdng_bx">
                <Typography component="h3">
                    Welcome to Bitgraphix:
                    <span>OUR PROJECTS</span>
                </Typography>
                <Typography>projects we’ve built and sites we’ve made.</Typography>
            </Box>
            <Box className="cards_cntnt_bx cards_cntnt_bx_main">
                <Row className="align-items-center">
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/eBase.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">eBASE</Typography>
                                <Typography>Web Design and Build / NFT Creation / Ongoing Content Partnership</Typography>
                                <a href="https://ebase-qa.vercel.app/" className="sldr_btn" target="_blank">eBASE</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/slic_of_tim_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Slice of Time</Typography>
                                <Typography>Web Design and Build / Front and Backend : Currently in Production  </Typography>
                                <a href="https://sliceoftime.netlify.app/" className="sldr_btn" target="_blank">Slice of time</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/onavax_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">onAVAX</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend / Dev Team Support</Typography>
                                <a href="https://onavax.com/" className="sldr_btn" target="_blank">onavax</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/swapsicle_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">swapsicle</Typography>
                                <Typography>Web Build / Front and Backend / Dev Team Support</Typography>
                                <a href="https://www.swapsicle.io/en/swap" className="sldr_btn" target="_blank">swapsicle</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/pi_Img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Pi Protocol</Typography>
                                <Typography>Web Design and Build / NFT Creation / Ongoing Content Partnership</Typography>
                                <a href="https://www.pi-protocol.io/" className="sldr_btn" target="_blank">Pi Protocol</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/biggerminds_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Bigger MINDS</Typography>
                                <Typography>Web Revisions / Front and Backed updates / Contracts / Dev Team Support</Typography>
                                <a href="https://app.biggerminds.io/" className="sldr_btn" target="_blank">Bigger minds</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/cat_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Capital Aggregator Token</Typography>
                                <Typography>Web Design and Build / Dashboard / Contracts / Dev Team Support</Typography>
                                <a href="https://www.aggregator.capital/" className="sldr_btn" target="_blank">c.a.t</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/cultivator_landing_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">CULTIVATOR</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend  </Typography>
                                <a href="https://thecultivator.finance/" className="sldr_btn" target="_blank">cultivator</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/ecc_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Empire Capital Token</Typography>
                                <Typography>Wed design and Build / Dashboard / Front and Backend development</Typography>
                                <a href="https://www.ecc.capital/#" className="sldr_btn" target="_blank">empire capital</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/rua_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">ROUNDIFY</Typography>
                                <Typography>Wed design and Build / Dashboard / Front and Backend Development</Typography>
                                <a href="https://rua.ecc.capital/" className="sldr_btn" target="_blank">ROUNDIFY</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/upcake_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">upCake</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production</Typography>
                                <a href="https://upcake.netlify.app/" className="sldr_btn" target="_blank">upCake</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/up_matic_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">upMATIC</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production</Typography>
                                <a href="https://upmatic.netlify.app/" className="sldr_btn" target="_blank">upMatic</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/uptether_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">upTether</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production</Typography>
                                <a href="https://uptether.netlify.app/" className="sldr_btn" target="_blank">upTether</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/upbnb_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">upBNB</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production</Typography>
                                <a href="https://upbnb.netlify.app/" className="sldr_btn" target="_blank">upBNB</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">upCRO</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production</Typography>
                                <a href="https://upcro.finance/" className="sldr_btn" target="_blank">upCro</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/catgirls_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Nekotoshi</Typography>
                                <Typography>Entire Project and all Creative Content</Typography>
                                <a href="https://nekotoshi.netlify.app/" className="sldr_btn" target="_blank">NEKOTOSHI</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/defi_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Defi Dungeon</Typography>
                                <Typography>Entire Project / 3d Animated NFTs / Story Narrative : Project Co</Typography>
                                <a href="https://defidungeon.io/" className="sldr_btn" target="_blank">Defi dungeon</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/empire_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Empire Dex</Typography>
                                <Typography>Branding / Web design  and build</Typography>
                                <a href="https://www.ecc.capital/#" className="sldr_btn" target="_blank">Empire DEX</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/devligion_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">DEV Legion</Typography>
                                <Typography>Web Design and Build</Typography>
                                <a href="#" className="sldr_btn" target="_blank">dev legion</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/jigsaw_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">JigSaw</Typography>
                                <Typography>Web Design and Build / Puzzle Shape NFT creation.</Typography>
                                <a href="https://puzlnft.com/#/" className="sldr_btn" target="_blank">JigSaw</a>
                            </Box>
                        </Box>
                    </Col>
                    {/* <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/moonboots_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Moonboots</Typography>
                                <Typography>Web Design and Build </Typography>
                                <a href="https://www.mymoonboots.io/" className="sldr_btn" target="_blank">Moonboots</a>
                            </Box>
                        </Box>
                    </Col> */}
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/prism_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">PRISM</Typography>
                                <Typography>Web design and build : update coming soon.</Typography>
                                <a href="https://prismupdate.netlify.app/" className="sldr_btn" target="_blank">Prism Network</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/root_bridge_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">ROOT Bridge</Typography>
                                <Typography>Web Design and Build / Dashboard / Front and Backend : Currently in Production </Typography>
                                <a href="https://rootbridge.netlify.app/" className="sldr_btn" target="_blank">root bridge</a>
                            </Box>
                        </Box>
                    </Col>      
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/blockvoodoo_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">BlockVooDoo</Typography>
                                <Typography>Web design and build</Typography>
                                <a href="https://blockvoodoo.com/" className="sldr_btn" target="_blank">blockvoodoo</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/long_sharp_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Longs & Sharps</Typography>
                                <Typography>Web Design and build : site coming soon</Typography>
                                <a href="https://longsharp.netlify.app/" className="sldr_btn" target="_blank">longs & sharps</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/vineyard_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Vineyard</Typography>
                                <Typography>Web Design : Sketch for Project in Development</Typography>
                                <a href="/Vineyard_Landing.jpg" className="sldr_btn" target="_blank">Vineyard</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/magic_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Defi Magic</Typography>
                                <Typography>Web Design and Build / 3d VFX Animation / NFT Creation</Typography>
                                <a href="https://defimagic.io/#/" className="sldr_btn" target="_blank">DEFI MAGIC</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/strigoi_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Strigoi</Typography>
                                <Typography>Web Design and Build / Front and Backend Development : project Coming Soon</Typography>
                                <a href="https://strigoi.netlify.app/" className="sldr_btn" target="_blank">Strigoi</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/pbgb_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Push Button Get Banana</Typography>
                                <Typography>Web Design</Typography>
                                <a href="http://feedthemonkey.io" className="sldr_btn" target="_blank">pbgb</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/spartacus_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Spartacus</Typography>
                                <Typography>Website Sketch : Project DOA</Typography>
                                <a href="https://spartacus-dao.netlify.app/" className="sldr_btn" target="_blank">Spartacus</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/diatom_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Diatom</Typography>
                                <Typography>Website Sketch</Typography>
                                <a href="/Diatom_Landing.jpg" className="sldr_btn" target="_blank">Diatom</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/klima_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Klima</Typography>
                                <Typography>Website Sketch</Typography>
                                <a href="/Klima_Landing.jpg" className="sldr_btn" target="_blank">Klima</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/bttlformars_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Battle for Mars</Typography>
                                <Typography>Web Design and Build / Front and Backend Development : Project MIA</Typography>
                                <a href="#" className="sldr_btn" target="_blank">bfm</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/deflect_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Deflect</Typography>
                                <Typography>Deflect is now Prism : website is MIA</Typography>
                                <a href="#" className="sldr_btn" target="_blank">Deflect</a>
                            </Box>
                        </Box>
                    </Col>
                    {/* <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/greed_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">$GREED</Typography>
                                <Typography>Web design and Build</Typography>
                                <a href="https://www.greedecosystem.com/#/" className="sldr_btn" target="_blank">$GREED</a>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={12} lg={3} md={4} sm={6}>
                        <Box className="sldr_box">
                            <Box className="sldr_box_top">
                                <Box component="img" className="img-fluid w-100" src='/img/herchain_img.png' />
                            </Box>
                            <Box className="sldr_box_bottom">
                                <Typography component="h4">Herchain</Typography>
                                <Typography>Website Sketch : project in development</Typography>
                                <a href="#" className="sldr_btn" target="_blank">Herchain</a>
                            </Box>
                        </Box>
                    </Col> */}
                </Row>
            </Box>
        </Box>
        {/* <Box className="clck_hide_btn">
            <Button>click to hide all</Button>
        </Box> */}
        <Box className="cntnt_crtn_bx">
            <Container>
                <Row className="align-items-center justify-content-center">
                    {/* <Col xs={12} md={7} >
                        <Box className="smng_rgt">
                            <Typography component="h4">We provide best tech product</Typography>
                            <Box component="img" className="img-fluid" src='/img/video_img.png' />
                        </Box>
                    </Col> */}
                    <Col xs={12} md={12} className="form_order">
                        <Box className="cntnt_crtn_frm cntnt_crtn_frm_ash">
                            <Box className="crtn_frm_ttl">
                                <Typography component="h6">Engineering Success</Typography>
                                <Typography>A diverse team of talented artists and hardcore programmers producing preimum content for the blockchain space.</Typography>
                            </Box>
                            <Box className="crtn_frm_form">
                                <Box className='bitgrafix_text_img_prnt'>
                                    <Box component="img" className="img-fluid" src='/img/bitgrafix_text_img.svg' />
                                </Box>
                                <Box className="crtn_frm_form_ttl crtn_frm_form_ttl_as">
                                    <Typography>Let’s make the Blockchain a beautiful place. For inquiries:  </Typography>
                                </Box>
                                <Row>
                                    <Col xs={12} lg={12} >
                                        <Form.Group className="mb-2" controlId="formName">
                                            <Form.Control type="text" placeholder="Your name" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <Form.Group className="mb-2" controlId="formEmail">
                                            <Form.Control type="text" placeholder="Your email" />
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Control as="textarea" placeholder="Your message" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <Button className="cntct_btn">
                                        CONTACT us
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Box>
    </>
  )
}
