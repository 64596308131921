import React from 'react'
import { Box, Typography, Tabs, Tab, Container } from '@mui/material'
import ServicesSc from './aboutbitgra/ServicesSc'

export default function BitgraphixAbout() {
    return (

        <>
            <Box className="about_prnt_min_box">
                <img src='/img/abut_se_top_img.png' alt='' className='abut_se_top_img' />
               
                <Box className="about_contin_box">
                    <img src='/img/bit_about_cap_ic.png' alt='' className='bit_about_cap_ic' />
                    <img src='/img/bit_about_rit_b.png' alt='' className='bit_about_rit_b' />
                
                    <Box className='walcom_bitgra_top_contin walcom_bitgra_top_contin_se_2 '>
                        <Typography component="h2">Welcome to Bitgraphix : <span>ABOUT</span></Typography>
                        <Typography>Your Partner in Crafting Unforgettable Digital Experiences</Typography>
                    </Box>
                    <Box className="abou_p">
                        <Typography>Bitgraphix was born from a singular vision: to merge the power of storytelling with the limitless potential of design. When you have a dream that's waiting to take flight, we're here to give it wings.</Typography>
                        <br />
                        <Typography>Whether you're a charity eager to shake up fundraising with a groundbreaking NFT collection, an entrepreneur on the cusp of unveiling a dynamic website or app, or a business ready to captivate with cutting-edge motion graphics, Bitgraphix is your partner in making the extraordinary happen.</Typography>
                        <br />
                        <Typography>We don't just create designs: <b>we craft experiences.</b> We don't just build apps; we build bridges between your vision and your audience. And we do it all with a fusion of design expertise, AI wizardry, and a relentless commitment to your success.</Typography>
                        <br />
                        <Typography><i>We're more than a design firm. We're visionaries, innovators, and dreamers just like you. Our founder, Zack Judson, started Bitgraphix with a simple premise: to bring Hollywood-quality aesthetics to every project we touch, to every dream we help realize.</i></Typography>
                        <br />
                        <Typography>When you choose <b>Bitgraphix.</b> You're not Just Choosing a Team. <b>YOUR VISION. OUR MISSION.</b>Together, let's unleash the extraordinary.</Typography>
                    </Box>
                </Box>
            </Box>
            <ServicesSc />
        </>
    )
}
