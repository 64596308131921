import React, { useState, useEffect } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import uuidv4 from 'uuid';
import { Box, Typography } from '@mui/material';

export default function ProductsSlider(props) {
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(true);
  const [goToSlide, setGoToSlide] = useState(null);
  const [slides, setSlides] = useState([]);
  const { images } = props;

  useEffect(() => {
    images.map((image, index) => {
      setSlides([
        ...slides,
        {
          key: uuidv4(),
          content: <img src={image} alt='' />,
          onClick: () => setGoToSlide(index),
        },
      ]);
    });
  }, [images]);
  console.log(slides);

  // useEffect(() => {
  //   console.log(slides);
  //   slides.map((element, index) => {
  //     return { ...element, onClick: () => setGoToSlide(index) };
  //   });
  // }, [slides]);

  // const slides = [
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img01.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img02.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img03.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img04.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img05.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img06.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img01.jpg' alt='' />,
  //   },
  //   {
  //     key: uuidv4(),
  //     content: <img src='/img/tmplt_a/layout_img02.jpg' alt='' />,
  //   },
  // ].map((element, index) => {
  //   return { ...element, onClick: () => setGoToSlide(index) };
  // });
  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);
  return (
    <>
      <Box className='productslider'>
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
          showNavigation
          animationConfig={config.gentle}
        />
      </Box>
    </>
  );
}
