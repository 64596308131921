import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import history from "./history";
import logger from "redux-logger";
import rootReducer from "./rootReducer";
import jwt_decode from "jwt-decode";
let window: any;
const router = routerMiddleware(history);

// NOTE: Do not change middleares delaration pattern since rekit plugins may register middlewares to it.
const middlewares = [thunk, router];

/* istanbul ignore if  */
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");

  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);

  // if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  //   devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
  // }
}

function configureStore(initialState = {}) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  //   let module:any;
  // /* istanbul ignore if  */
  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept("./rootReducer", () => {
  //     const nextRootReducer = require("./rootReducer"); // eslint-disable-line
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");

  if (token) {
    try {
      let decodedToken = jwt_decode(token);

      if (decodedToken) {
        store.dispatch({ type: "AUTHORIZE_USER", user: JSON.parse(user) });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return store;
}

export default configureStore();
