import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import BannerAnim from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import Lottie from 'react-lottie';
import TweenOne from 'rc-tween-one';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ContactForm from './ContactForm';
import Vimeo from '@u-wave/react-vimeo';

import playButtonAnimation from '../../../data/play_button.json';
import playBtn from '../../../data/play_button_1.json';
import playBtn1 from '../../../data/play_button_2.json';
import playBtnCenter from '../../../data/play_button_center.json';
import designBlue from '../../../data/ourservices/blue/Design_blue.json';
import nftBlue from '../../../data/ourservices/blue/NFT_blue.json';
import contractBlue from '../../../data/ourservices/blue/SmartContract_blue.json';
import supportBlue from '../../../data/ourservices/blue/Support_blue.json';
import websiteBlue from '../../../data/ourservices/blue/website_blue.json';
import designGray from '../../../data/ourservices/latest/Dashboards.json';
import nftGray from '../../../data/ourservices/latest/NFT.json';
import contractGray from '../../../data/ourservices/latest/UIUX.json';
import supportGray from '../../../data/ourservices/latest/Support.json';
import websiteGray from '../../../data/ourservices/latest/website.json';

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

export default function ServicesSc() {
    const tiles = [
        {
            title: 'Websites / Web3',
            description: 'Design and Fabrication',
            icon: '/img/ourservice_img01.png',
            blueIcon: websiteBlue,
            grayIcon: websiteGray,
        },
        {
            title: 'NFTs',
            description: 'Premium NFT art and design',
            icon: '/img/ourservice_img02.png',
            blueIcon: nftBlue,
            grayIcon: nftGray,
        },
        {
            title: 'UI/UX',
            description: 'Outstanding Interactions',
            icon: '/img/ourservice_img03.png',
            blueIcon: contractBlue,
            grayIcon: contractGray,
        },
        {
            title: 'Dashboards',
            description: 'Beautiful Analytics',
            icon: '/img/ourservice_img04.png',
            blueIcon: designBlue,
            grayIcon: designGray,
        },
        {
            title: 'Motion Graphics',
            description: '2d and 3d Animation',
            icon: '/img/ourservice_img05.png',
            blueIcon: supportBlue,
            grayIcon: supportGray,
        }
    ];
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: playBtnCenter,
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [animationCompleted, setAnimationCompleted] = React.useState(false);
    const [mouseLeave, setMouseLeave] = React.useState(true);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [tileClicked, setTileClicked] = useState(-1);
    const [motionClicked, setMotionClicked] = useState(false);
    const [openTwo, setOpenTwo] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpenTwo = () => {
        setOpenTwo(true);
    };
    const handleCloseTwo = () => {
        setOpenTwo(false);
    };

    const [openThree, setOpenThree] = React.useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpenThree = () => {
        setOpenThree(true);
    };
    const handleCloseThree = () => {
        setOpenThree(false);
    };

    const showDropPlayBtn = (index: number) => {
        console.log(index, tiles.length)
        setTileClicked(index);
        if (index === tiles.length - 1) setMotionClicked(true);
    }

    const [selectedTile, setSelectedTile] = useState(0);
    const [isOnTiles, setIsOnTiles] = useState(false);
    useEffect(() => {
        let timer;
        if (isOnTiles) {
            timer = setInterval(() => {
                if (selectedTile === tiles.length - 1) {
                    if (mouseLeave) {
                        setIsOnTiles(false)
                        setSelectedTile(-1)
                    } else {
                        setSelectedTile(0);
                    }
                } else {
                    setSelectedTile(selectedTile + 1);
                }
            }, 750);
        } else {
            setSelectedTile(-1);
            if (timer) {
                clearInterval(timer);
            }
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [selectedTile, isOnTiles]);

    return (
        <>
            <Box className="service_sc">
                {/* <Box className="srv_ttl_blkbx" /> */}
                <Container className="z_index">

                    <Row>
                        <Col xs={12}>
                            <Box className="srvcttl">
                                <Box className="srv_ttl_img">
                                    <Box component="img" src="/img/logo.png" alt="" />
                                </Box>
                                <Typography component="h3">
                                    OUR
                                    <span>SERVICES</span>
                                </Typography>
                            </Box>
                        </Col>
                        <Col xs={12} className="cstm_row">
                            <Box className="srv_pd_bx">
                                <Row>
                                    {
                                        tiles.map((tile, index) => (
                                            <Col
                                                xs={12}
                                                md={3}
                                                className="s_in_box_col"
                                                onMouseEnter={() => {
                                                    setIsOnTiles(true);
                                                    setSelectedTile(index);
                                                    setMouseLeave(false);
                                                }}
                                                onMouseLeave={() => setMouseLeave(true)}
                                            >
                                                <Box
                                                    className={`in_s_box ${tileClicked === index ? 'clicked' : ''}`}
                                                    style={{ transition: "background-color .5s ease", backgroundColor: selectedTile === index ? `rgba(89, 215, 255, ${0.2 * (index + 1)})` : 'white' }}
                                                    onClick={() => showDropPlayBtn(index)}
                                                >
                                                    <Typography component="h4" className="wbst_bx">{tile.title}</Typography>
                                                    <Typography className={`nft_bx ${tileClicked === index ? 'clicked' : ''}`}>{tile.description}</Typography>
                                                    {tileClicked === index ?
                                                        <Lottie
                                                            options={
                                                                {
                                                                    loop: false,
                                                                    autoplay: true,
                                                                    animationData: tile.grayIcon,
                                                                }
                                                            }
                                                            width={138}
                                                            height={138}
                                                        /> : <Box component="img" src={tile.icon} className="img-fluid" alt="" />
                                                    }
                                                </Box>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row>
                                    {/* <Col xs={12} md={3}>
                                            <Box className="in_s_box" data-aos="fade-down" data-aos-duration="2500">
                                                <Box className="in_s_box_ttl">
                                                    <Typography component="h5">Sound Design</Typography>
                                                    <Typography>we hear it too.</Typography>
                                                </Box>
                                                <Box component="img" src="/img/service_img06.png" className="img-fluid" alt="" />
                                            </Box>
                                        </Col> */}
                                    <Col xs={12} md={4} className="form_order">
                                        <ContactForm />
                                    </Col>
                                    <Col xs={12} md={7}>
                                        {/* {motionClicked && */}
                                        <div>
                                            <BannerAnim
                                                // autoPlay
                                                ease="easeInOutExpo"
                                                sync
                                                type="across"
                                            >
                                                <Element key="aaa" prefixCls="banner-user-elem">
                                                    <BgElement
                                                        key="bg"
                                                        className="bg"
                                                        style={{
                                                            backgroundImage: "transparent",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "left center",
                                                        }}
                                                    />
                                                    <Box className="banner_md_sc">
                                                        <TweenOne  animation={{ y: 0, opacity: 0, type: "from", delay: animationCompleted ? 500 : 0 }}
                                                        >
                                                            <a target="_blank" onClick={handleClickOpen} className="vmo_lnk">
                                                                <Box component="img" src="/img/vm_btn_01.png" className={`img-fluid ${animationCompleted ? 'visible' : 'invisible'}`} alt="" />
                                                                <Box className={`play-btn ${animationCompleted ? 'invisible' : 'visible'}`} >
                                                                    <Lottie
                                                                        className="play-btn"
                                                                        options={defaultOptions}
                                                                        width={600}
                                                                        height={560}
                                                                        eventListeners={[
                                                                            {
                                                                                eventName: 'complete',
                                                                                callback: () => {
                                                                                    setAnimationCompleted(true);},
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Box>
                                                            </a>
                                                        </TweenOne>
                                                    </Box>
                                                    <Dialog
                                                        fullScreen={fullScreen}
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="responsive-dialog-title"
                                                        className='videoplayer_padding'
                                                    >
                                                        <DialogContent>
                                                            <div className="ifrm_div"><iframe className="ifrm" src="https://player.vimeo.com/video/694422918?h=dcb0a44e81&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" title="NIX Platform: Features Promo"></iframe></div>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Element>
                                                <Element key="bbb" prefixCls="banner-user-elem" >
                                                    <BgElement
                                                        key="bg"
                                                        className="bg"
                                                        style={{
                                                            backgroundImage: "transparent",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "left center",
                                                        }}
                                                    />
                                                    <Box className="banner_md_sc">
                                                    <TweenOne animation={{ y: 0, opacity: 0, type: "from", delay: animationCompleted ? 500 : 0 }}
                                                            >
                                                            <a target="_blank" onClick={handleClickOpenTwo} className="vmo_lnk">
                                                                <Box component="img" src="/img/vm_btn_02.png" className="img-fluid" alt="" />
                                                            </a>
                                                        </TweenOne>
                                                    </Box>
                                                    <Dialog
                                                        fullScreen={fullScreen}
                                                        open={openTwo}
                                                        onClose={handleCloseTwo}
                                                        className='videoplayer_padding'
                                                        aria-labelledby="responsive-dialog-title"
                                                    >
                                                        <DialogContent>
                                                            <div className="ifrm_div"><iframe className="ifrm" src="https://player.vimeo.com/video/539881029?h=be2b5e1a90&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" title="DEFLECT : Promo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Element>
                                                <Element key="ccc" prefixCls="banner-user-elem" >
                                                    <BgElement
                                                        key="bg"
                                                        className="bg"
                                                        style={{
                                                            backgroundImage: "transparent",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "left center",
                                                        }}
                                                    />
                                                    <Box className="banner_md_sc">
                                                    <TweenOne animation={{ y: 0, opacity: 0, type: "from", delay: animationCompleted ? 500 : 0 }}
                                                            >
                                                            <a target="_blank" onClick={handleClickOpenThree} className="vmo_lnk">
                                                                <Box component="img" src="/img/vm_btn_03.png" className="img-fluid" alt="" />
                                                            </a>
                                                        </TweenOne>
                                                    </Box>
                                                    <Dialog
                                                        fullScreen={fullScreen}
                                                        open={openThree}
                                                        onClose={handleCloseThree}
                                                        className='videoplayer_padding'
                                                        aria-labelledby="responsive-dialog-title"
                                                    >
                                                        <DialogContent>
                                                            <div className="ifrm_div"><iframe className="ifrm" src="https://player.vimeo.com/video/604857451?h=3973693e13&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" title="Defi Dungeon Promotional Trailer"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Element>
                                            </BannerAnim> 
                                        </div>
                                            {/* } */}
                                    </Col>
                                </Row>
                            </Box>

                        </Col>
                    </Row>

                </Container>
            </Box>
        </>
    )
}
