import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const onSubmit = (formData) => {
    dispatch(
      login(formData, () => {
        history.push("/admin");
      })
    );
  };

  return (
    <div className="login-form" id="login-form-container">
      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-input-container">
            <label htmlFor="email">Email</label>
            <input name="email" {...register("email")} />
          </div>
        </div>

        <div className="form-row">
          <div className="form-input-container">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" {...register("password")} />
          </div>
        </div>

        {/* {errors["email"] && (
          <p className="form-error-message">{errors.email.message}</p>
        )}

        {errors["password"] && (
          <p className="form-error-message">{errors.password.message}</p>
        )} */}

        {/* {props.auth.error && typeof props.auth.error === "string" && (
          <p className="form-error-message">{props.auth.error}</p>
        )} */}

        <div className="button-container">
          <button type="submit">Log In</button>
        </div>
      </form>
    </div>
  );
}

export default Login;
