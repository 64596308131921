import React, { useEffect } from "react";
import BannerAnim from "rc-banner-anim";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ModelViewer from "@metamask/logo";
import MetamaskLogo from "./MetamaskLogo.js";

import { Link } from "react-scroll";
import { Button } from "react-bootstrap";

// const viewer = ModelViewer({
//   pxNotRatio: true,
//   width: 100,
//   height: 100,
//   followMouse: true,
//   slowDrift: false,
// });
// const container = document.getElementById("logo-container");
// container.appendChild(viewer.container);
// viewer.lookAt({
//   x: 100,
//   y: 100,
// });
// viewer.setFollowMouse(true);
// viewer.stopAnimation();

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

const { animType, setAnimCompToTagComp } = BannerAnim;

// animType.custom = (elem, type, direction, animData) => {
//   console.log(`custom animType, type:${type}`); // eslint-disable-line no-console
//   let _y;
//   const props = { ...elem.props };
//   let children = props.children;
//   if (type === 'enter') {
//     _y = direction === 'next' ? '100%' : '-100%';
//   } else {
//     _y = direction === 'next' ? '-10%' : '10%';
//     children = React.Children.toArray(children).map(setAnimCompToTagComp);
//   }
//   return React.cloneElement(elem, {
//     ...props,
//     animation: {
//       ...animData,
//       y: _y,
//       type: type === 'enter' ? 'from' : 'to',
//     },
//   }, children);
// };

function BannerSc() {
  return (
    <>
      {/* <Box component="img" src="/img/slider_one.jpg" alt="" className="img-fluid" /> */}
      <Box className="mainBannerSc">
        <BannerAnim
          // autoPlay
          autoPlaySpeed={5000}
          type="grid"

        >
          <Element key="ddd" prefixCls="banner-user-elem">
            <BgElement
              key="bg"
              className="bg"
              style={{
                backgroundImage: "url(/img/04.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "left center",
              }}
            >
              <Box
                component="img"
                src="/img/bitgraphix_text_img.png"
                alt=""
                className="bit_graphix_text_img"
              />
            </BgElement>
            {/* <Box className="blured_bx" /> */}
            <Box className="banner_md_sc">
              <TweenOne
                animation={{ y: 0, opacity: 0, type: "from", delay: 0 }}
              >
                <Box className="banner_md_h1_prnt">
                  <h1 key="h1">
                    {/* Premium VISUAL CONTENT FOr<br /> <b>Bitcoin and THE Blockchain</b> */}

                    Premium VISUAL CONTENT FOr {" "}<br />
                    <span>
                      YOUR DIGITAL JOURNEY
                    </span>{" "}
                  </h1>
                </Box>
                {/* <p key="p">Ant Motion Demo.Ant Motion Demo.Ant Motion Demo.Ant Motion Demo</p> */}
              </TweenOne>
            </Box>

          </Element>
          <Element key="aaa" prefixCls="banner-user-elem">
            <BgElement
              key="bg"
              className="bg"
              style={{
                backgroundImage: "url(/img/01.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "left center",
              }}
            >
              <Box
                component="img"
                src="/img/bitgraphix_text_img.png"
                alt=""
                className="bit_graphix_text_img"
              />
            </BgElement>
            {/* <Box className="blured_bx" /> */}

            <Box className="banner_md_sc">
              <Box className="banner_md_h1_prnt">
                <TweenOne
                  animation={{ y: 0, opacity: 0, type: "from", delay: 0 }}
                >
                  <h1>
                    {/* world class <span>creatives <br/>
                                    full spectrum</span> partner */}
                    VISIONARY <span>CREATIVES<br /> YOUR FULL SPECTRUM</span> PARTNER
                    {/* <b className="bnr is-animetion">
                                        <span>B</span>
                                        <span>i</span>
                                        <span>t</span>
                                        <span>c</span>
                                        <span>o</span>
                                        <span>i</span>
                                        <span>n</span> 
                                        &nbsp;
                                        <span>a</span>
                                        <span>n</span>
                                        <span>d</span>
                                        &nbsp;
                                        <span>T</span> 
                                        <span>H</span> 
                                        <span>E</span> 
                                        &nbsp;
                                        <span>B</span> 
                                        <span>l</span> 
                                        <span>o</span> 
                                        <span>c</span> 
                                        <span>k</span> 
                                        <span>c</span> 
                                        <span>h</span> 
                                        <span>a</span> 
                                        <span>i</span> 
                                        <span>n</span> 
                                    </b> */}
                  </h1>
                </TweenOne>
              </Box>

              {/* <p key="p">Ant Motion Demo.Ant Motion Demo.Ant Motion Demo.Ant Motion Demo</p> */}
            </Box>

          </Element>
          <Element key="bbb" prefixCls="banner-user-elem">
            <BgElement
              key="bg"
              className="bg"
              style={{
                backgroundImage: "url(/img/02.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "left center",
              }}
            >
              <Box
                component="img"
                src="/img/bitgraphix_text_img.png"
                alt=""
                className="bit_graphix_text_img"
              />
            </BgElement>
            {/* <Box className="blured_bx" /> */}
            <Box className="banner_md_sc">
              <TweenOne
                animation={{ y: 0, opacity: 0, type: "from", delay: 0 }}
              >
                <Box className="banner_md_h1_prnt">
                  <h1 key="h1">
                    {/* Crypto 
                                    <b>ROCKET FUEL</b> */}
                    IGNITING {" "}
                    <span>
                      TRANSFORMATION
                    </span>{" "}

                  </h1>
                </Box>
              </TweenOne>
            </Box>

          </Element>
          <Element key="ccc" prefixCls="banner-user-elem">
            <BgElement
              key="bg"
              className="bg"
              style={{
                backgroundImage: "url(/img/03.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "left center",
              }}
            >
              <Box
                component="img"
                src="/img/bitgraphix_text_img.png"
                alt=""
                className="bit_graphix_text_img"
              />
            </BgElement>
            {/* <Box className="blured_bx" /> */}
            <Box className="banner_md_sc">
              <TweenOne
                animation={{ y: 0, opacity: 0, type: "from", delay: 0 }}
              >
                <Box className="banner_md_h1_prnt">
                  <h1 key="h1">
                    {/* Design for <b>THE Blockchain</b> <br />Building your <b>success</b> */}
                    Design for <span>THE DIGITAL AGE<br></br> ENGINEERING</span> SUCCESS
                  </h1>
                </Box>
                {/* <p key="p">Ant Motion Demo.Ant Motion Demo.Ant Motion Demo.Ant Motion Demo</p> */}
              </TweenOne>
            </Box>

          </Element>
          

        </BannerAnim>
        <Box className="banner_bttm_sc">

          <Box className="banner_inf_box">
            <Box component="img" src="/img/project_ic.svg" />
            <Link to="projects" className="nft_shop_link">
              Projects
            </Link>
            <Typography component="p">
              Projects we are partners with and content we have created.{" "}
            </Typography>
          </Box>


          <Box className="banner_inf_box">
            <Box component="img" src="/img/nftshop_icv2.svg" />
            <Link to="nft_stor" className="nft_shop_link">
              NFT shop <span>Coming Soon</span>
            </Link>
            <Typography component="p">
              Buy, Collect and Trade our unique line of exceptional NFTs.
            </Typography>
          </Box>

        </Box>
        {/* <Box className="mddl_anmtn">

          <a href="#" className="banner_cntr_sc">
            <Typography component="h6">Collect our</Typography>
            <Typography component="h4">NFTs</Typography>

            <MetamaskLogo />
            
            <Box
              component="img"
              src="/img/lnk_ic.png"
              className="link_ic"
            />
          </a>

        </Box> */}
      </Box>
      <Box className="unlesh_grin_prt_bx">
        <Typography component="h3">Unleash Your Vision with Bitgraphix: Where Art Meets Impact</Typography>
        <Typography>Your vision deserves to be unleashed. To thrive in a world saturated with digital noise, you need to captivate, resonate, and inspire. Meet Bitgraphix, your key to unlocking the extraordinary.</Typography>
      </Box>


    </>
  );
}

export default BannerSc;
