import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Container, Row, Col, Button } from "react-bootstrap";
import BackArrow from "@mui/icons-material/ArrowBack";
import LinkIcon from "@mui/icons-material/Link";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Card1 } from "./nftCards/Card1";

import { useFetchPoolBalances } from "./nft5/redux/hooks";

import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';

import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OurNftStore(props) {
  const [value, setValue] = React.useState(0);
  let { nft5pool } = useFetchPoolBalances();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [shortAddress, setShortAddress] = useState("");
  const { connected, address, connectWallet, disconnectWallet } = props;

  useEffect(() => {
    if (!connected) return;
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 3)}..${address.slice(-3)}`);
    }
  }, [address, connected]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <>
      <Element name='nft_stor'>

        <Box className="nft_str_box nft_str_box_bg_color">
          {/* <Box className="ttl_back" /> */}
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <Typography component="h3" className="def_bl_ttl bg_changed_ash">
                  <span>VISIT</span>{" "}
                  <span className="cmng_sn_txt">Unlock Savings</span>
                  <br />
                  our NFT STORE
                </Typography>
              </Col>
              <Col xs={12} md={6} className="text-end">

                <Button className="cnnctwllt_btn btnpNone" onClick={connected ? disconnectWallet : connectWallet}>
                  <span>
                    <LinkIcon />
                  </span>
                  <BackArrow />    
                  <u>
                  {connected ?
                    shortAddress
                    :
                    "Connect Wallet"
                  }
                  </u>  
                  <Box className="cssnText">Coming Soon</Box>
                </Button>

              </Col>
            </Row>
            <Box className="p_rltv_bx">
              {/* <Box className='box_clr_bg' /> */}
              <Box className="collps_box">
                <Box className="bitgr_tab_box">
                  {/* <Typography className="capt_left_p">Category:</Typography>
                  <Link to="/nft-gallary" className='allc_right_p allc_right_p_link'>All Collections <Box component="img" src='/img/arrow_right_white.svg' /></Link> */}
                  {/* <Typography className="allc_right_p">
                  All Collections{" "}
                  <Box component="img" src="/img/Arrow_right.svg" />
                </Typography> */}
                  <Box sx={{ width: "100%" }}>
                    {/* <Box className="ris_btn_two">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="span_bg_n"
                      >
                        <Tab
                          label="Bitgraphix Original"
                          {...a11yProps(0)}
                          className="stk_btn"
                          icon={
                            <Box className="b_img">
                              <Box
                                component="img"
                                src="/img/b_tab_img.svg"
                                className=""
                              />
                            </Box>
                          }
                        />
                        <Tab
                          label="Client Collection"
                          {...a11yProps(1)}
                          className="stk_btn margi_r"
                          icon={
                            <Box className="b_img">
                              <Box
                                component="img"
                                src="/img/b_tab_img.svg"
                                className=""
                              />
                            </Box>
                          }
                        />
                      </Tabs>
                    </Box> */}
                    <Box className="pading_tab">
                      <TabPanel value={value} index={0}>
                        <Row>

                          {nft5pool.map((npool, index) => {
                            return <Card1 index={index} npool={npool} />;
                          }
                          )}




                          {/* ------------------------------------------------------------|||||------------------------------------------------ */}
                          <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                              <Box
                                component="img"
                                src="/img/tab_col_02.png"
                                className="img-fluid"
                              />
                              <Box className="min_h_box">
                                <Typography component="h3" className="the_fir_h3">
                                  Coming Soon
                                  <br /> Bitgraphix NFT
                                </Typography>
                                <Box className="totel_img_box jut_con_c">
                                  {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                  <Box
                                    component="img"
                                    src="/img/mint_img.svg"
                                    className=""
                                  />
                                  {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                </Box>
                                <Typography className="bit_col_p bit_col_p_02">
                                  This collectors series of 3d Animated ETH
                                  inspired NFTs will drop throughout April{" "}
                                </Typography>
                                <Button className="for_btn cominsoon_btn">
                                  Coming Soon
                                </Button>
                              </Box>
                            </Box>
                          </Col>

                          <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                              <Box
                                component="img"
                                src="/img/tab_col_03.png"
                                className="img-fluid"
                              />
                              <Box className="min_h_box">
                                <Typography component="h3" className="the_fir_h3">
                                  Coming Soon
                                  <br /> Bitgraphix NFT
                                </Typography>
                                <Box className="totel_img_box jut_con_c">
                                  {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                  <Box
                                    component="img"
                                    src="/img/mint_img.svg"
                                    className=""
                                  />
                                  {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                </Box>
                                <Typography className="bit_col_p bit_col_p_02">
                                  This collectors series of 3d Animated ETH
                                  inspired NFTs will drop throughout April{" "}
                                </Typography>
                                <Button className="for_btn cominsoon_btn">
                                  Coming Soon
                                </Button>
                              </Box>
                            </Box>
                          </Col>

                          <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                              <Box
                                component="img"
                                src="/img/tab_col_04.png"
                                className="img-fluid"
                              />
                              <Box className="min_h_box">
                                <Typography component="h3" className="the_fir_h3">
                                  Coming Soon
                                  <br /> Bitgraphix NFT
                                </Typography>
                                <Box className="totel_img_box jut_con_c">
                                  {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                  <Box
                                    component="img"
                                    src="/img/mint_img.svg"
                                    className=""
                                  />
                                  {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                </Box>
                                <Typography className="bit_col_p bit_col_p_02">
                                  This collectors series of 3d Animated ETH
                                  inspired NFTs will drop throughout April{" "}
                                </Typography>
                                <Button className="for_btn cominsoon_btn">
                                  Coming Soon
                                </Button>
                              </Box>
                            </Box>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        Item Two
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Button className="vew_aro_btn" as="a" href="/marketplacecataogue">
                {" "}
                <Box className="viw_aro">
                  Vew all NFT’s
                  <Box component="img" src="/img/Arrow_right.svg" />
                  <span className="cmmssnbtntxt">Coming Soon</span>
                </Box>{" "}
                
              </Button> */}
              {/* <Typography className="buy_bitgrfi_P">Buy <span>Bitgraphix</span> NFTs : Unlock Discounts on Our Expertise!</Typography> */}
              {/* <Button className="click_red_mor_btn">Click to Read More</Button> */}
              {/* <Button className="click_red_mor_btn" onClick={handleClick}>
                {open ? "Click to Read Less" : "Click to Read More"}
              </Button> */}
            </Box>
            {open ?
              <Box>
                <Box className="read_more_box_min">
                  <Typography className="jon_bit_gr_p for_mt12">Join our <span>Bitgraphix NFT Synergy Program</span> and elevate your business with our limited edition NFTs! As a <span>Diamond Tier</span> holder, you'll have the opportunity to <span>save up to 15%</span> on your entire project build.</Typography>
                  <Typography className="jon_bit_gr_p for_mt10">Our strategy is simple but rewarding - the more <span>Bitgraphix Synergy NFTs</span> you collect, the greater your savings. Simply return one NFT back to us to unlock the benefits of your collection, and <span>redeem your discount.</span></Typography>
                  <Box className="grin_brd_box">
                    <Typography className="jon_bit_gr_p t_cent"><span>Our NFT Synergy Program works in six tiers, each providing a higher level of discount on our services:</span></Typography>
                    <Box className="ylo_p_flex">
                      <Box className="ylo_ul_bx ylo_ul_bx_v2">
                        <Typography>1. Copper Tier (1-2 NFTs): Enjoy a 3% discount on our services.</Typography>
                        <Typography>2. Bronze Tier (3-4 NFTs): Enjoy a 5% discount on our services</Typography>
                        <Typography>3. Silver Tier (5-7 NFTs): Enjoy a 7% discount on our services.</Typography>
                      </Box>
                      <Box className="ylo_ul_bx">
                        <Typography>4. Gold Tier (8-10 NFTs): Enjoy a 10% discount on our services.</Typography>
                        <Typography>5. Platinum Tier (11-14 NFTs): Enjoy a 12% discount on our services.</Typography>
                        <Typography>6. Diamond Tier (15+ NFTs): Enjoy a massive 15% discount on our services.</Typography>
                      </Box>
                    </Box>

                    <Typography className="jon_bit_gr_p t_cent">Only  Bitgraphix Synergy Collection NFTs are considered as part of the Synergy Program discount. </Typography>
                  </Box>
                  <Box>
                    <Typography className="jon_bit_gr_p mt35_margi">Dive into the <span>Bitgraphix NFT Synergy Program</span> today, buy a <span>Synergy Collection NFT</span> and let creativity bring tangible, valuable rewards! These savings extend across all divisions of <span>Block Leader Inc,</span> including <span>Bitgraphix, Dev Legion,</span> and <span>Block Gurus.</span> It's more than an investment in limited edition art—it's a smart strategy for your business.</Typography>
                    <Typography className="jon_bit_gr_p mt40_margi">Join the <span>Bitgraphix NFT Synergy Program</span> today and start enjoying the rewards of your creativity!</Typography>
                  </Box>
                </Box>
              </Box>
              : ""
            }



          </Container>

        </Box>
      </Element>
      <Box className="wi_on_ai_bx">
        <img src='/img/we_ai_img.png' alt='' />
        <Box className="wi_ai_contin">
          <Typography><span>We know that your brand's visual identity is crucial to your success, and we take that responsibility seriously.</span></Typography>
          <Typography className="mt18_margi">That's why we use cutting-edge <span>AI tools</span> to turn your concepts into reality with lightning speed and precision, so you can launch your project faster and with more confidence than ever.</Typography>
        </Box>
      </Box>
    </>
  );

}
