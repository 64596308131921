import React from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import ForwardArrow from '@mui/icons-material/ArrowForward';

export default function OurMerchStore() {
    return (
        <>
            <Box className="mrchstr_main">
                <Container>
                    <Row className="justify-content-center spasing_row">
                        <Col xs={12} lg={5} className="mrchstr_ttl">
                            <Typography component="h3" className="def_bl_ttl">
                                <span>VISIT</span>
                                <span className="cmng_sn_txt crypt_swg_txt">crypto swag</span>
                                <br />
                                our MERCH STORE
                            </Typography>
                        </Col>
                        <Col xs={12} lg={5}></Col>
                        <Col xs={12} lg={2}></Col>
                        {/* <a href="https://bitgraphix.io/shop/" target="_blank" className="def_lnks">View All <ForwardArrow /></a> */}
                        <Col xs={12} md={6} xl={3}>
                            <Box component="a" className="mrch_items">
                                <Box component="img" className="img-fluid w-100" src='/img/merch_store_img01.png' />
                                <Box className="slids_futur_contin_bx">
                                    <Typography component="h4">
                                        The Future is Peaceful
                                    </Typography>
                                    <Typography>
                                        Bitcoin, Clothing, T-Shirts
                                    </Typography>
                                    <Box className="mrch_items_action">
                                        <Typography component="h6">
                                            {/* $22.44 - $26.44 */}
                                        </Typography>
                                        <button>
                                        Coming Soon
                                            {/* <Box component="img" src='/img/cart_ic.svg' /> */}
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={6} xl={3}>
                            <Box component="a"  className="mrch_items">
                                <Box component="img" className="img-fluid w-100" src='/img/merch_store_img02.png' />
                                <Box className="slids_futur_contin_bx">
                                    <Typography component="h4">
                                        Work Buy Hodl Die
                                    </Typography>
                                    <Typography>
                                        Bitcoin, Clothing, T-Shirts
                                    </Typography>
                                    <Box className="mrch_items_action">
                                    <Typography component="h6">
                                            {/* $22.44 - $26.44 */}
                                        </Typography>
                                        <button>
                                        Coming Soon
                                            {/* <Box component="img" src='/img/cart_ic.svg' /> */}
                                        </button>
                                    </Box>
                                </Box>

                            </Box>
                        </Col>
                        <Col xs={12} md={6} xl={3}>
                            <Box component="a" className="mrch_items">
                                <Box component="img" className="img-fluid w-100" src='/img/merch_store_img03.png' />
                                <Box className="slids_futur_contin_bx">
                                    <Typography component="h4">
                                        The Future is Peaceful
                                    </Typography>
                                    <Typography>
                                        Bitcoin, Clothing, T-Shirts
                                    </Typography>
                                    <Box className="mrch_items_action">
                                    <Typography component="h6">
                                            {/* $22.44 - $26.44 */}
                                        </Typography>
                                        <button>
                                        Coming Soon
                                            {/* <Box component="img" src='/img/cart_ic.svg' /> */}
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={6} xl={3}>
                            <Box component="a" className="mrch_items">
                                <Box component="img" className="img-fluid w-100" src='/img/merch_store_img04.png' />
                                <Box className="slids_futur_contin_bx">
                                    <Typography component="h4">
                                        Bitcoin Science
                                    </Typography>
                                    <Typography>
                                        Bitcoin, Clothing, T-Shirts
                                    </Typography>
                                    <Box className="mrch_items_action">
                                    <Typography component="h6">
                                            {/* $22.44 - $26.44 */}
                                        </Typography>
                                        <button>
                                        Coming Soon
                                            {/* <Box component="img" src='/img/cart_ic.svg' /> */}
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>
    )
}
