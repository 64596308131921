import React from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, } from 'react-bootstrap'; 


export default function NftsForClint() {
  return (
    <>
        <Box className="nft_for_clint_main">
            <Typography component="h3" className="black_box_h" >
                View    <span>contract collections</span>
                <br/><b>NFTs for clients</b>
            </Typography>
            <Row >
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                            <Button>Buy NFT</Button>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                            <Button>Buy NFT</Button>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                            <Button>Buy NFT</Button>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                            <Button>Buy NFT</Button>
                        </Box>
                    </Box>
                </Col>
                <Col xs={12} sm={6}  md={6} lg={3}>
                    <Box className='nft_clnt_box'>
                        <Box component="img" src='/img/nft_for_clnt_img01.png' className='' />
                        <Box className='text_btm_box'>
                            <Typography>Bitgraphix is the premium content creation and development company servicing the blockchain space. This is the first NFT in a limited edition series.</Typography>
                            <Button>Buy NFT</Button>
                        </Box>
                    </Box>
                </Col>
            </Row>
            <Box className='nft_clnt_show_more_btn_prnt'>
                <Button>Show More</Button>
            </Box>
        </Box>
    </>
  )
}
