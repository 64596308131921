import React from 'react'
import { Box, Typography, Tabs, Tab, Container } from '@mui/material'
import { Row, Col, Button } from "react-bootstrap";
// import { Card1 } from "./nftCards/Card1";

export default function TabPanelOne() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <Container className='def_conta_iner'>
                <Row className='synergy_bx_row'>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <Box className="synerg_box">
                            <Typography component="h2">Synergy</Typography>
                            <Typography>
                                noun [ U ] business, medical specialized : us  /ˈsɪn.ɚ.dʒi/ uk  /ˈsɪn.ə.dʒi/
                                (also synergism, us/ˈsɪn.ɚ.dʒɪ.zəm/ uk/ˈsɪn.ə.dʒɪ.zəm/)<br />
                                the combined power of a group of things when they are working together
                                that is greater than the total power achieved by each working separately:
                                Team work at its best results in a synergy that can be very productive.
                            </Typography>
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <img src='/img/syaner_img.png' alt='' className='img-fluid' />
                    </Col>
                </Row>

                <Box className="tab_panl_min_bx">
                    <Row>

                        {/* {nft5pool.map((npool, index) => {
                        console.log('99090909090909090', index)
                        console.log('99090909090909090npool', npool)

                        return <Card1 index={index} npool={npool} />;
                    }
                    )} */}




                        {/* ------------------------------------------------------------|||||------------------------------------------------ */}
                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <video width="100%" muted loop autoPlay>
                                    <source
                                        src="/img/col_vido_01.webm"
                                        type="video/mp4"
                                    ></source>
                                </video>
                                <Box className="min_h_box">
                                    <Typography component="h3" className="the_fir_h3">
                                        The first official Bitgraphix NFT!- <br />
                                        Limited NFT Miami Edition -
                                    </Typography>
                                    <Box className="totel_img_box totel_img_box_v2a">
                                        <Typography component="h5">
                                            total mint: <span>12/100</span>
                                        </Typography>
                                        <Box
                                            component="img"
                                            src="/img/mint_img.svg"
                                            className=""
                                        />
                                        <Typography component="h4">
                                            1 NFT <span> ETH</span>
                                        </Typography>
                                    </Box>
                                    {/* <Box className="totel_img_box">
            
                                        <Box
                                            component="img"
                                            src="/img/mint_img.svg"
                                            className=""
                                        />
                                        <Typography component="h4">
                                            3 NFT <span>{forMat(byDecimals(MP1[8] * 3))} ETH</span>
                                        </Typography>
                                        </Box> */}


                                    <Typography className="bit_col_p">
                                        Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br />
                                        This is the first NFT in a limited edition series.
                                    </Typography>
                                    <Button className="for_btn" onFocus={(event) => event.stopPropagation()}
                                        // disabled={isDepositPending}
                                        onClick={(event) => {
                                            // onDeposit(MP1[8]);
                                        }}>Buy NFT</Button>

                                    {/* <Button className="for_btn"      onFocus={(event) => event.stopPropagation()}
                        disabled={isDepositPending2}
                        onClick={(event) => {
                          onDeposit2(MP1[8] * 3);
                        }}>Buy 3 NFT</Button> */}
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tab_col_02.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography component="h3" className="the_fir_h3">
                                        Coming Soon
                                        <br /> Bitgraphix NFT
                                    </Typography>
                                    <Box className="totel_img_box jut_con_c">
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box
                                            component="img"
                                            src="/img/mint_img.svg"
                                            className=""
                                        />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                    </Box>
                                    <Typography className="bit_col_p bit_col_p_02">
                                        This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                    </Typography>
                                    <Button className="for_btn cominsoon_btn">
                                        Coming Soon
                                    </Button>
                                </Box>
                            </Box>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tab_col_03.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography component="h3" className="the_fir_h3">
                                        Coming Soon
                                        <br /> Bitgraphix NFT
                                    </Typography>
                                    <Box className="totel_img_box jut_con_c">
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box
                                            component="img"
                                            src="/img/mint_img.svg"
                                            className=""
                                        />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                    </Box>
                                    <Typography className="bit_col_p bit_col_p_02">
                                        This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                    </Typography>
                                    <Button className="for_btn cominsoon_btn">
                                        Coming Soon
                                    </Button>
                                </Box>
                            </Box>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3}>
                            <Box className="man_box_colps">
                                <Box
                                    component="img"
                                    src="/img/tab_col_04.png"
                                    className="img-fluid"
                                />
                                <Box className="min_h_box">
                                    <Typography component="h3" className="the_fir_h3">
                                        Coming Soon
                                        <br /> Bitgraphix NFT
                                    </Typography>
                                    <Box className="totel_img_box jut_con_c">
                                        {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                        <Box
                                            component="img"
                                            src="/img/mint_img.svg"
                                            className=""
                                        />
                                        {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                    </Box>
                                    <Typography className="bit_col_p bit_col_p_02">
                                        This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                    </Typography>
                                    <Button className="for_btn cominsoon_btn">
                                        Coming Soon
                                    </Button>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Container>

            <Box className="synergy_colle_forbg__color">
                <Box className="synergy_collection_min">
                    <Typography component="h2">The Synergy Collection</Typography>
                    <Typography>What if your passion for digital art could fuel the success of your business? Welcome to the <span>Bitgraphix Synergy Collection.</span> Each limited edition NFT you collect is not just a unique piece of digital artistry, but a key to unlock incredible savings on services across all divisions of <span>Block Leader Inc,</span> including <span>Bitgraphix, Dev Legion,</span> and <span>Block Gurus</span>. Imagine <span>saving up to 15%</span> on your project builds and using your NFT collection as a dynamic asset, a valuable a tool for growth of your business. Embrace the future, unlock the synergy, and let your <span>Bitgraphix NFT Collection</span> open up a world of opportunities. <span></span></Typography>
                    <Typography component="h4">Bitgraphix <span>Synergy Collection</span> NFTs: Collect and Save <span>BIG</span> your Build! </Typography>
                </Box>
                <Button className="click_red_mor_btn" onClick={handleClick}>
                    {open ? "Click to Learn More" : "Click to Learn More"}
                </Button>
                {open ?
                    <Box>
                        <Box className="read_more_box_min">
                            <Typography className="jon_bit_gr_p for_mt12">Join our <span>Bitgraphix NFT Synergy Program</span> and elevate your business with our limited edition NFTs! As a <span>Diamond Tier</span> holder, you'll have the opportunity to <span>save up to 15%</span> on your entire project build.</Typography>
                            <Typography className="jon_bit_gr_p for_mt10">Our strategy is simple but rewarding - the more <span>Bitgraphix Synergy NFTs</span> you collect, the greater your savings. Simply return one NFT back to us to unlock the benefits of your collection, and <span>redeem your discount.</span></Typography>
                            <Box className="grin_brd_box">
                                <Typography className="jon_bit_gr_p t_cent"><span>Our NFT Synergy Program works in six tiers, each providing a higher level of discount on our services:</span></Typography>
                                <Box className="ylo_p_flex">
                                    <Box className="ylo_ul_bx ylo_ul_bx_v2">
                                        <Typography>1. Copper Tier (1-2 NFTs): Enjoy a 3% discount on our services.</Typography>
                                        <Typography>2. Bronze Tier (3-4 NFTs): Enjoy a 5% discount on our services</Typography>
                                        <Typography>3. Silver Tier (5-7 NFTs): Enjoy a 7% discount on our services.</Typography>
                                    </Box>
                                    <Box className="ylo_ul_bx">
                                        <Typography>4. Gold Tier (8-10 NFTs): Enjoy a 10% discount on our services.</Typography>
                                        <Typography>5. Platinum Tier (11-14 NFTs): Enjoy a 12% discount on our services.</Typography>
                                        <Typography>6. Diamond Tier (15+ NFTs): Enjoy a massive 15% discount on our services.</Typography>
                                    </Box>
                                </Box>

                                <Typography className="jon_bit_gr_p t_cent">Only  Bitgraphix Synergy Collection NFTs are considered as part of the Synergy Program discount. </Typography>
                            </Box>
                            <Box>
                                <Typography className="jon_bit_gr_p mt35_margi">Dive into the <span>Bitgraphix NFT Synergy Program</span> today, buy a <span>Synergy Collection NFT</span> and let creativity bring tangible, valuable rewards! These savings extend across all divisions of <span>Block Leader Inc,</span> including <span>Bitgraphix, Dev Legion,</span> and <span>Block Gurus.</span> It's more than an investment in limited edition art—it's a smart strategy for your business.</Typography>
                                <Typography className="jon_bit_gr_p mt40_margi">Join the <span>Bitgraphix NFT Synergy Program</span> today and start enjoying the rewards of your creativity!</Typography>
                            </Box>
                        </Box>
                    </Box>
                    : ""
                }
            </Box>

            <Box className='def_conta_iner_ried_mor'>
                <Container className='def_conta_iner'>
                    <Box className="tab_panl_min_bx">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <video width="100%" muted loop autoPlay>
                                        <source
                                            src="/img/col_vido_01.webm"
                                            type="video/mp4"
                                        ></source>
                                    </video>
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            The first official Bitgraphix NFT!- <br />
                                            Limited NFT Miami Edition -
                                        </Typography>
                                        <Box className="totel_img_box totel_img_box_v2a">
                                            <Typography component="h5">
                                                total mint: <span>12/100</span>
                                            </Typography>
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            <Typography component="h4">
                                                1 NFT <span> ETH</span>
                                            </Typography>
                                        </Box>
                                        <Typography className="bit_col_p">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br />
                                            This is the first NFT in a limited edition series.
                                        </Typography>
                                        <Button className="for_btn" onFocus={(event) => event.stopPropagation()}
                                            // disabled={isDepositPending}
                                            onClick={(event) => {
                                                // onDeposit(MP1[8]);
                                            }}>Buy NFT</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_02.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_03.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_04.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Container>
                <Button className="vew_aro_btn" as="a" href="/nft-gallary">
                    <Box className="viw_aro">
                    Vew FULL Collection
                        <Box component="img" src="/img/Arrow_right.svg" />
                    </Box>
                </Button>
            </Box>

            <Box className='recent_add_bax'>
                <Typography className='recent_add_p'>RECENT ADDED</Typography>
                <Container className='def_conta_iner'>
                    <Box className="tab_panl_min_bx">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <video width="100%" muted loop autoPlay>
                                        <source
                                            src="/img/col_vido_01.webm"
                                            type="video/mp4"
                                        ></source>
                                    </video>
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            The first official Bitgraphix NFT!- <br />
                                            Limited NFT Miami Edition -
                                        </Typography>
                                        <Box className="totel_img_box totel_img_box_v2a">
                                            <Typography component="h5">
                                                total mint: <span>12/100</span>
                                            </Typography>
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            <Typography component="h4">
                                                1 NFT <span> ETH</span>
                                            </Typography>
                                        </Box>
                                        <Typography className="bit_col_p">
                                            Bitgraphix is the premium content creation and development company servicing the blockchain space.<br /><br />
                                            This is the first NFT in a limited edition series.
                                        </Typography>
                                        <Button className="for_btn" onFocus={(event) => event.stopPropagation()}
                                            // disabled={isDepositPending}
                                            onClick={(event) => {
                                                // onDeposit(MP1[8]);
                                            }}>Buy NFT</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_02.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_03.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3}>
                                <Box className="man_box_colps">
                                    <Box
                                        component="img"
                                        src="/img/tab_col_04.png"
                                        className="img-fluid"
                                    />
                                    <Box className="min_h_box">
                                        <Typography component="h3" className="the_fir_h3">
                                            Coming Soon
                                            <br /> Bitgraphix NFT
                                        </Typography>
                                        <Box className="totel_img_box jut_con_c">
                                            {/* <Typography component="h5">total mint: <span>12/100</span></Typography> */}
                                            <Box
                                                component="img"
                                                src="/img/mint_img.svg"
                                                className=""
                                            />
                                            {/* <Typography component="h4">total mint: <span>12/100</span></Typography> */}
                                        </Box>
                                        <Typography className="bit_col_p bit_col_p_02">
                                            This collectors series of 3d Animated ETH inspired NFTs will drop throughout April {" "}
                                        </Typography>
                                        <Button className="for_btn cominsoon_btn">
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Container>
            </Box>

        </>
    )
}
