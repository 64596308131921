import { Box, Button, Typography, Grid } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Select from 'react-select'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { fetchGetApi } from '../../utils/backendApi';
import { IUser } from '../../utils/interface';
import UserlistTable from './UserlistTable';
import UserModalForm from './UserModalForm';

export type paymentType = {
  walletName: string;
  walletAddress: string;
  blockchain:
  {
    value: string;
    label: JSX.Element;
  };
  token:
  {
    value: string;
    label: JSX.Element;
  };
};
export const options = [
  { value: 'user', label: <div>Normal</div> },
  { value: 'manager', label: <div>Manager</div> },
  { value: 'admin', label: <div>Admin</div> },
];
export const optionsToken = [
  { value: 'USDC', label: <div><img src="/img/token_img04.svg" height="26px" width="26px" />USDC (USDC)</div> },
  { value: 'ETH', label: <div><img src="/img/token_img01.svg" height="26px" width="26px" />Ethereum (ETH)</div> },
  { value: 'BNB', label: <div><img src="/img/token_img02.svg" height="26px" width="26px" />Binance (BNB)</div> }
  // { value: 'BTC', label: <div><img src="/img/token_img03.svg" height="26px" width="26px" />Bitcoin (BTC)</div> },
];
export const optionsBlockchain = [
  { value: 'ERC20', label: <div>ERC20</div> },
  { value: 'BEP20', label: <div>BEP20</div> },
  { value: 'AVAX_C', label: <div>AVAX C-Chain</div> },
];

export const initialPaymentMethod = {
  active: false,
  walletName: '',
  walletAddress: '',
  blockchain: optionsBlockchain[0],
  token: optionsToken[0],
};

export default function UserList() {
  const [openUser, setOpenUser] = React.useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [filterArchive, setFilterArchive] = useState<Number>(0)

  const getUsers = async (page = 1) => {
    try {
      const response = await fetchGetApi(`api/user?&page=${page}&size=10&archive=${filterArchive}`, true);
      setUsers(response.result.result);
      setPage(response.result.currentPage);
      setTotalPage(response.result.totalPages);
    } catch (error: any) {
      console.log(error);
    }
  };

  const setUser = (user: any) => {
    setSelectedUser(user);
    handleOpenUser();
    // onUserFormOpen();
  }

  useEffect(() => {
    if (!openUser) {
      setSelectedUser(undefined);
    }
  }, [openUser]);

  useEffect(() => {
    getUsers();
  }, [filterArchive]);

 

  
  return (
    <>
      <Box className='user_list_main'>
        <Typography className='dflt_h1_usr_list' component="h1">User list</Typography>
        <Box className='usr_list_in_bx'>
          <Box className='usr_list_header'>
            <Typography component="h2">Users</Typography>
            <Box className='right_crte_btn'>
              {/* <a href='/archiveduser' className='archive_btn' >
                <img src='/img/archive_btn_ic.svg' alt='' />
                <Typography component="h6">Archived users</Typography>
              </a> */}
              <Button className='create_usr_btn' onClick={handleOpenUser}>
                <img src='/img/creat_usr_ic.svg' alt='' />
                <Typography component="h6" >Create user</Typography>
              </Button>
            </Box>
          </Box>
          <Box className='inr_table_user'>
            <UserlistTable users={users} getUsers={getUsers} setUser={setUser} />
          </Box>
          <Box className='tabl_pgntn'>
            <Pagination count={10} />
          </Box>
        </Box>
      </Box>

      <UserModalForm 
        user={selectedUser}
        isOpen={openUser}
        onClose={handleCloseUser}
        getUsers={getUsers} />

      
    </>
  )
}
