import React from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Element } from 'react-scroll';

export default function ContactForm() {
  return (
    <>
        <Element name='Contact'>
            <Box className="cntnt_crtn_frm cntnt_crtn_frm_for_new_se">
                <Box className="crtn_frm_ttl">
                    <Typography component="h6">CONTENT CREATION</Typography>
                    <Typography>A diverse team of talented artists and hardcore programmers producing preimum content for the blockchain space. </Typography>
                </Box>
                <Box className="crtn_frm_form">
                    <Box className="crtn_frm_form_ttl">
                        <Box component="img" src="/img/blck_logo.png" className="img-fluid" alt="" />
                        <Typography>Let’s make the Blockchain a beautiful place. For inquiries:</Typography>
                    </Box>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Control type="text" placeholder="Your name" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Control type="text" placeholder="Your email" />
                            </Form.Group>
                        </Col>
                        
                        <Col xs={12}>
                            <Form.Group className="mb-3 tex_high" controlId="formName">
                                <Form.Control as="textarea" placeholder="Your message" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="text-center">
                            <Button className="cntct_btn">
                                CONTACT us
                            </Button>
                        </Col>
                    </Row>
                </Box>
            </Box>
        </Element>
    </>
  )
}
