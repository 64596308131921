import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import Slider from "@mui/material/Slider";

const EasyCrop = ({ image, croppedAreaPixels, setCroppedAreaPixels, rotation, setRotation }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // console.log("image: ",image);
  // console.log("croppedImage: ",croppedImage);
  return (
    <div>
      {/* <button
        style={{
          display: image === null || croppedImage !== null ? "none" : "block",
        }}
        onClick={showCroppedImage}
      >
        Crop
      </button> */}
      <div
        className="container"
        style={{
          display: image?.original_url != "" || croppedImage !== null ? "block" : "none",
        }}
      >
        <div className="crop-container">
          <Cropper
            image={image?.original_url}
            crop={crop}
            cropSize={image?.cropSize}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
        </div>
        <div className="controls">
          <label>
            Rotate
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="rotate"
              onChange={(e, rotation) => setRotation(rotation)}
              className="range"
            />
          </label>
          <label>
            Zoom
            <Slider
              value={zoom}
              min={0.1}
              max={3}
              step={0.001}
              aria-labelledby="zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              className="range"
            />
          </label>
        </div>
      </div>
      {/* <div className="cropped-image-container">
        {croppedImage && (
          <img className="cropped-image" src={croppedImage} alt="cropped" style={{width:"150px",
        height:"100px"
        }}/>
        )}
        {croppedImage && <button onClick={onClose}>close</button>}
      </div> */}
    </div>
  );
};

export default EasyCrop;