import React, { useEffect, useState } from "react";
import "./assets/css/App.scss";
import "./index.css";
import NetworksProvider from "./components/NetworksProvider/NetworksProvider";
import { useConnectWallet } from "./redux/connectWallet";
import { useDisconnectWallet } from "./redux/disconnectWallet";
import { createWeb3Modal } from "./web3/createWeb3Modal";
import "./assets/css/App.scss";
import "./index.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import Login from "./components/login/Login.js";
import Admin from "./components/admin/Admin.js";
import HomePage from "./components/home/HomePage";
import PiProtocol from "./components/projects/pi-protocol/PiProtocol";
import DefiDungeon from "./components/projects/defi-dungeon/DefiDungeon";
import TemplateA from "./components/portfolio/templateA/TemplateA";
import TemplateB from "./components/portfolio/templateB/TemplateB";
import ProjectListing from "./components/project-listing/ProjectListing";
import ProjectListingDetails from "./components/project-listing-details/ProjectListingDetails";
import About from "./components/about/About";
import NftGallary from "./components/gallary/NftGallary";
import LoginNew from "./components/login/Login-new";
import UserList from "./components/user-list/UserList";
import LayoutTwo from "./components/LayoutTwo";
import TeamNFT from "./components/team-nft/TeamNFT";
import ArchivedUser from "./components/user-list/ArchivedUser";
import EditNFT from "./components/team-nft/EditNFT";
import NFTMember from "./components/team-nft/NFTMember";
import { Provider } from "react-redux";
import MarketplaceCataogue from "./components/MarketplaceCataogue";
import BitgraphixAbout from "./components/BitgraphixAbout";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)


function App() {
  const {
    connectWallet,
    web3,
    address,
    networkId,
    connected,
    connectWalletPending,
  } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);

  // useEffect(() => {
  //   setModal(createWeb3Modal());
  // }, [setModal]);

  // useEffect(() => {
  //   if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
  //     connectWallet(web3Modal);
  //   }
  // }, [web3Modal, connectWallet]);

  // useEffect(() => {
  //   if (
  //     web3 &&
  //     address &&
  //     !connectWalletPending &&
  //     Boolean(networkId !== 80001)
  //   ) {
  //     alert("Please connect to Mumbai Test Network!");
  //   }
  // }, [web3, address, networkId, connectWalletPending]);
  const MainLayout = props => (
    <>
      <Header
        address={address}
        connected={connected}
        connectWallet={() => connectWallet(web3Modal)}
        disconnectWallet={() => disconnectWallet(web3, web3Modal)}
      />
      {props.children}
      <Footer />
    </>
  )


  return (
    <BrowserRouter>
      {/* <Provider store={store}> */}
      <NetworksProvider>
        <div className="App">
          
         
            
            <Switch>
              {/* <Route
                exact
                path="/"
                render={(props) => (
                  <HomePage
                    address={address}
                    connected={connected}
                    connectWallet={() => connectWallet(web3Modal)}
                    disconnectWallet={() => disconnectWallet(web3, web3Modal)}
                  />
                )}
              /> */}
            <RouteWithLayout
              exact
              layout={MainLayout}
              path="/"
              component={HomePage}
              render={(props) => (
                <HomePage
                  address={address}
                  connected={connected}
                  connectWallet={() => connectWallet(web3Modal)}
                  disconnectWallet={() => disconnectWallet(web3, web3Modal)}
                />
              )}
            />
            <RouteWithLayout layout={MainLayout} exact path="/login" component={Login} />
            <RouteWithLayout exact layout={LayoutTwo} path="/admin" component={Admin} />
            <RouteWithLayout layout={MainLayout} exact path="/pi-protocol" component={PiProtocol} />
            <RouteWithLayout layout={MainLayout} exact path="/project-listing" component={ProjectListing} />
            <RouteWithLayout layout={MainLayout} exact path="/pl-detail" component={ProjectListingDetails} />
            <RouteWithLayout layout={MainLayout} exact path="/defi-dungeon" component={DefiDungeon} />
            <RouteWithLayout layout={MainLayout} exact path="/template-a" component={TemplateA} />
            <RouteWithLayout layout={MainLayout} exact path="/project/:projectName" component={TemplateB} />
            <RouteWithLayout layout={MainLayout} exact path="/about-us" component={About} />
            <RouteWithLayout layout={MainLayout} exact path="/nft-gallary" component={NftGallary} />
            <RouteWithLayout layout={MainLayout} exact path="/login-new" component={LoginNew} />
            <RouteWithLayout exact layout={LayoutTwo} path="/user-list" component={UserList} />
            <RouteWithLayout exact layout={LayoutTwo} path="/team-nft" component={TeamNFT} />
            <RouteWithLayout exact layout={LayoutTwo} path="/archiveduser" component={ArchivedUser} />
            <RouteWithLayout exact layout={LayoutTwo} path="/editnft" component={EditNFT} />
            <RouteWithLayout exact layout={LayoutTwo} path="/nftmember" component={NFTMember} />
            <RouteWithLayout exact layout={MainLayout} path="/marketplacecataogue" component={MarketplaceCataogue} />
            <RouteWithLayout exact layout={MainLayout} path="/bitgraphixabout" component={BitgraphixAbout} />
            

            {/* MarketplaceCataogue */}

          </Switch>
         
        </div>
      </NetworksProvider>
      {/* </Provider> */}
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
