import React, { useEffect, useState } from "react";
import { fetchPutApi } from "../../utils/backendApi";
import { IUser } from "../../utils/interface";
import { Box, Button, Typography, Grid } from '@mui/material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useEventListener from '@use-it/event-listener';

interface UserListProps {
  users: IUser[],
  getUsers: () => void,
  setUser: (user: IUser) => void
}

export default function UserlistTable({ users, getUsers, setUser }: UserListProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEventListener('click', handleClose, document, { capture: true });

  const cancelRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [filterArchive, setFilterArchive] = useState<Number>(0)

  //pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const { isOpen: isUserFormOpen, onOpen: onUserFormOpen, onClose: onUserFormClose } = useDisclosure();

  const changeArchive = async (user: IUser) => {
    setSelectedUser(user);
    // onConfirmOpen();
  }

  const doChangeArchive = async () => {
    try {
      setLoading(true);
      const data = {
        id: selectedUser?._id,
        archive: selectedUser?.is_archived == 0 ? 1 : 0
      }
      const response = await fetchPutApi(`api/user/archive`, data, true);
      getUsers();
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
      // onConfirmClose();
    }
  }

  useEffect(() => {
    getUsers();
  }, [currentPage])

  // const setUser = (user: IUser) => {
  //   setSelectedUser(user);
  //   onUserFormOpen();
  // }

  return (
    <>
      <table className='user_table'>
        <thead className='user_thead'>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>ID NFT Token</th>
            <th>Type</th>
            <th>Name</th>
            <th>Last name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='tbody_user'>
        {(users ? users.map((user: IUser, index: number) => 
          <tr>
            <td>{user._id}</td>
            <td>{user.username}</td>
            <td>{user?.id_nft_token?.nft_token}</td>
            <td>{user.type}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>
            <Box>
              <div className='drp_dwn_main'>
                <Button
                  id="basic-button"
                  aria-controls={false ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={false ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <img src='./img/three_dot_drp_dwn.png' alt='' />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className="menu_right_dots"
                >
                  <MenuItem 
                  onClick={handleClose}
                   >
                    <img src='./img/archive_btn_ic.svg' alt='' />
                    <Typography component="h4">Archive User</Typography>
                  </MenuItem>
                  <MenuItem 
                  onClick={(e) => { 
                    setUser(user) 
                    handleClose()
                  }}
                  >
                    <img src='./img/pen_ic.svg' alt='' />
                    <Typography component="h4">Edit User</Typography>
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>
              </div>
            </Box>
            </td>
          </tr>) : <></>)}
        </tbody>
      </table>
    </>
  );
}
