import React, { useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import OurNftStore from './partitions/OurNftStore'
import NftsForClint from './partitions/NftsForClint'

export default function NftGallary() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
        <Box className='nft_gallary_main'>
            <Container maxWidth="lg">
                <Box className='welcm_galary_text'>
                    <Typography component="h2">
                        Welcome to Bitgraphix <br/><span>NFT GAllery</span>
                    </Typography>
                    <Typography>
                        Bitgraphix Original NFTs and Bitgraphix Client NFTs
                    </Typography>
                </Box>
                <OurNftStore />
                <NftsForClint />
            </Container>
        </Box>
    </>
  )
}
