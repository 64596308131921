import { Box, Button, Typography, Grid } from '@mui/material'
import React, {useState} from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Select from 'react-select'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function ArchivedUser() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [openUser, setOpenUser] = React.useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);

  const Type = [
    { value: 'Normal', label: 'Normal' },
    { value: 'User', label: 'User' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Admin', label: 'Admin' }
  ]
  const State = [
    { value: 'State 1', label: 'State 1' },
    { value: 'State 2', label: 'State 2' },
    { value: 'State 3', label: 'State 3' }
  ]
  const Country = [
    { value: 'Country 1', label: 'Country 1' },
    { value: 'Country 2', label: 'Country 2' },
    { value: 'Country 3', label: 'Country 3' }
  ]
  const  Token = [
    { value: 'token 1', 
    label: <Box className='eth_bx_menu'>
      <img src='./img/tkn_slct01.svg' alt='' />
      <Typography>Ethereum (ETH)</Typography>
    </Box> },
    { value: ' Token 2', label: 
    <Box className='eth_bx_menu'>
      <img src='./img/tkn_slct02.svg' alt='' />
      <Typography>Binance (BNB)</Typography>
    </Box> },
    { value: ' Token 3', label:
     <Box className='eth_bx_menu'>
      <img src='./img/tkn_slct03.svg' alt='' />
      <Typography>Bitcoin (BTC)</Typography>
    </Box> },
    { value: ' Token 3', label:
    <Box className='eth_bx_menu'>
     <img src='./img/tkn_slct04.svg' alt='' />
     <Typography>USDC (USDC)</Typography>
   </Box> }
  ]
  const  Blockchain = [
    { value: ' ERC20', label: <Box className='eth_bx_menu'><Typography>ERC20</Typography></Box> },
    { value: ' BEP20', label:  <Box className='eth_bx_menu'><Typography>BEP20</Typography></Box> },
    { value: ' AVAX C-Chain', label:  <Box className='eth_bx_menu'><Typography>AVAX C-Chain</Typography></Box> }
  ]
  
 

  
  return (
    <>
      <Box className='user_list_main'>
        <Typography className='dflt_h1_usr_list' component="h1">User list</Typography>
        <Box className='usr_list_in_bx'>
          <Box className='usr_list_header'>
            <Typography component="h2">Users</Typography>
            <Box className='right_crte_btn'>
              <a href='/archiveduser' className='archive_btn' >
                <img src='/img/archive_btn_ic.svg' alt='' />
                <Typography component="h6">Unarchived users</Typography>
              </a>
            </Box>
          </Box>
          <Box className='inr_table_user'>
            <table className='user_table'>
              <thead className='user_thead'>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>ID NFT Token</th>
                  <th>Type</th>
                  <th>Rate</th>
                  <th>Name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>ID Company</th>
                  <th>Methods</th>
                  <th>ID Client</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='tbody_user'>
                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>

                <tr>
                  <td>5498w5aw554w</td>
                  <td>Mike_M007</td>
                  <td>awop241ujio124s</td>
                  <td>Normal</td>
                  <td>$25.00</td>
                  <td>Mike</td>
                  <td>Maykers</td>
                  <td>mike_m007@mail.com</td>
                  <td>846854</td>
                  <td>Collections</td>
                  <td>Collections</td>
                  <td>
                    <Box className='right_ckbx_main'>
                      <Box className='chck_bx_user'>
                        <Checkbox {...label} />
                      </Box>
                      <img src='./img/dwnlod_ic.svg' alt='' />
                    </Box>
                  </td>
                </tr>


              </tbody>
            </table>
          </Box>
          <Box className='tabl_pgntn'>
            <Pagination count={10} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
