import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const thumb = {

};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export default function FileUpload({file, setSelectedFile}) {
  const [files, setFiles] = useState([file]);
  const [thumbs, setThumbs] = useState()
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setSelectedFile(acceptedFiles[0])
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

useEffect(() => {
  setFiles([file])
}, [file])

  useEffect(() => {
    setThumbs(files.map(file => (
      <div key={file.name}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    )))
  }, [files])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <h3 className='whit_colr'>+ Drag & drop</h3>
        <p className='whit_colr'>Or click to choose NFT image / video</p>
        <span className='uplo_anth_img'>Formats: jpg, png, mp4, webM, GIF</span>
      </div>
      <aside style={thumbsContainer}>
        <div className='thumb_bx'>
          {thumbs}
        </div>
      </aside>
    </>
  );
}

