import React, {useEffect} from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ProductsSlider from './partitions/ProductsSlider';
import VideoGallary from './partitions/VideoGallary';


export default function PiProtocol(props:any) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Box className="cmn_tp_ttl pi_c_bg">
                <Container>
                    <Typography component="h3">Pi Protocol</Typography>
                </Container>
            </Box>
            <Box className="prjcts_main">
                <Container>
                    <Row>
                        <Col xs={12} className="mt-5 mb-5">
                            <Box className="pridnt">
                                <Box className="pr_logo_bx">
                                    <Box component="img" className="pi_logo" src='/img/pi_logo.png' />
                                </Box>
                                <Typography component="h5">PROJECT: Pi Protocol </Typography>
                            </Box>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography className="pro_p">
                                The NFT treasure hunt project. A unique new game in the DeFi arena. Manage your income while you can go on a real treasure hunt!<br />
                                <b>More info here:</b>  <a href="https://pi-protocol.io/#/" target="_blank">https://pi-protocol.io/#/</a>
                            </Typography>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Box className="vd_box_v1">
                                <video autoPlay loop>
                                    <source src="/img/pi/Pi_Bitgraphix_Classroom_BETA_NFT.mp4" type="video/mp4" />
                                </video>
                                <Box className="vd_content">
                                    <Typography component="h4" className="def_h4">PROJECT: Pi Protocol </Typography>
                                    <Typography className="pro_p mb-5">Pi Protocol is a uique project that combines NFTs with Staking, Vaults and other familar DeFi mechanics all while adding a gamified treasure hunting feature and custom NFT marketplace. Solve puzzles and clues to find NFTs hiden in cyberspace and also hidden, like a geo-cache, in real life.  </Typography>
                                    <Typography component="h4" className="def_h4">Additional info from the Pi website:</Typography>
                                    <Typography className="pro_p">They are everywhere - treasures - just waiting to be found. If we look back on our history, we will be faced with treasures everywhere - were treasure hunters have been looking for. If many people are driven from the primal instinct to search for treasures, why not combine that with the crypto world? A project with the fascination of the treasure hunt. The Pi Protocol combines exactly this! A unique new game in the DeFi area. Manage your income while you can go on a real treasure hunt! </Typography>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} lg={6} className="mb-5">
                            <Typography component="h2" className="def_h2">graphic elements</Typography>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography component="h4" className="def_h4">Logotype</Typography>
                            <Typography className="pro_p">We created the entire graphics package top to bottom, includeing website, logos, branding, and all the animated NFTs. Bitgraphix is proud to be a fully integretaed partner providing content creation support for the exciting <span>Pi Protocol</span> project.</Typography>
                            <Box component="img" className="img-fluid mt-5 mb-5" src='/img/pi/pi_img01.png' />
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography component="h4" className="def_h4">images</Typography>
                            <Typography className="pro_p">A collection of images and motion graphic content created for the Pi Protocol project. </Typography>
                        </Col>
                        <Col xs={12} md={9} className="mb-2 cstm_md9">
                            <Box component="img" className="img-fluid w-100" src='/img/pi/video_01.png' />
                        </Col>
                        <Col xs={12} md={3} className="mb-2 cstm_md3">
                            <video autoPlay loop className="w-100">
                                <source src="/img/pi/Pi_Logo_Number_Swarm_v_WEB.mp4" type="video/mp4" />
                            </video>
                        </Col>
                        <Col xs={12} md={3} className="mb-2 cstm_md3">
                            <video autoPlay loop className="w-100">
                                <source src="/img/pi/Bitgraphix_Bitcircle_v_WEB.mp4" type="video/mp4" />
                            </video>
                        </Col>
                        <Col xs={12} md={6} className="mb-2 cstm_md6">
                            <Box component="img" className="img-fluid w-100" src='/img/pi/video_02.png' />
                        </Col>
                        <Col xs={12} md={3} className="mb-2 cstm_md3">
                            <video autoPlay loop className="w-100">
                                <source src="/img/pi/Pi_Number_Spiral_v_WEB.mp4" type="video/mp4" />
                            </video>
                        </Col>
                        <Col xs={12} lg={6} className="mb-5 mt-5">
                            <Typography component="h2" className="def_h2">gallery</Typography>
                        </Col>
                        <Col xs={12}>
                            <ProductsSlider />
                        </Col>
                        <Col xs={12}>
                            <VideoGallary />
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>
    )
}
