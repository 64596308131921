import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from "react";
import Slider from "react-slick";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function PartnerProject() {
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Box className="prtnr_prjtct_main_bx">
                <Container>
                    <Box className="jasty_btn">
                        <Box className="main_hdng">
                            <Typography component="h4">
                                OUR
                                <span>projects we support </span>
                                <b>Partner Projects</b>
                            </Typography>
                        </Box>
                        <Box>
                            <Link to="/project-listing" className="vive_all_link">Click to View All <span className='for_arwo_bx'><ArrowForwardIcon /></span></Link>
                        </Box>
                    </Box>


                    <div className='slid_prnt_bx'>
                        {/* <h2> Responsive </h2> */}
                        <Slider {...settings}>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/eBase.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">eBASE</Typography>
                                        <Typography>Web Design and Build / Fullstack </Typography>
                                        <Button target="_blank" href="https://ebase-qa.vercel.app/" className="sldr_btn">eBASE</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/p01.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Block Certified</Typography>
                                        <Typography>Web Design and Build / Fullstack </Typography>
                                        <Button target="_blank" href="https://www.blockcertified.io/" className="sldr_btn">Block certified</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/p02.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">TruBlox</Typography>
                                        <Typography>Web Design and Build / Fullstack</Typography>
                                        <Button target="_blank" href="https://www.trublox.ai/" className="sldr_btn">Trublox</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/p03.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">onXDC</Typography>
                                        <Typography>Web Build / Front and Backend / Dev Team Support</Typography>
                                        <Button target="_blank" href="https://www.onxdc.io/events" className="sldr_btn">onXDC</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/p04.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Shop Botz</Typography>
                                        <Typography>Web Design and Build / NFT Collection</Typography>
                                        <Button target="_blank" href="https://www.bitbotz.world/" className="sldr_btn">Shop botz</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>



                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            <div>
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </div>
                            {/* <div>
                                <h3>6</h3>
                            </div>
                            <div>
                                <h3>7</h3>
                            </div>
                            <div>
                                <h3>8</h3>
                            </div> */}
                        </Slider>
                    </div>
                    {/* <Box className="prnt_boxes_bx">
                        <Row className="justify-content-center">
                            <Col xs={12} md={3} >
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/cat_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Capital Aggregator Token</Typography>
                                        <Typography>Website / Contracts / Dashboard<br /> Full Dev Team Support</Typography>
                                        <Button target="_blank" href="https://www.aggregator.capital" className="sldr_btn">CAT</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={3} >
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/biggerminds_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Bigger MINDS</Typography>
                                        <Typography>Additional Web Design / Development<br /> Contracts and Full Dev Team Support</Typography>
                                        <Button target="_blank" href="https://app.biggerminds.io/" className="sldr_btn">Bigger minds</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={3} >
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/pi_Img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Pi Protocol</Typography>
                                        <Typography>Web Design / Front End Build<br /> Entire NFT Content Creation Package and Support     </Typography>
                                        <Button target="_blank" href="https://www.pi-protocol.io" className="sldr_btn">Pi Protocol</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={3} >
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/ecc_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">Empire Capital Token</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">ECC</Button>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={4} >
                                <Box className="sldr_box">
                                    <Box className="sldr_box_top">
                                        <Box component="img" className="img-fluid w-100" src='/img/upcro_img.png' />
                                    </Box>
                                    <Box className="sldr_box_bottom">
                                        <Typography component="h4">upCRO</Typography>
                                        <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mattis morbi eu nunc massa ut arcu </Typography>
                                        <Button target="_blank" href="/pl-detail" className="sldr_btn">upCro</Button>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box> */}
                </Container>
            </Box>
        </>
    )
}
