import React, { useCallback, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import BannerSc from "./partitions/BannerSc";
import OurWork from "./partitions/OurWork";
import OurNftStore from "./partitions/OurNftStore";
import SomethingAboutUs from "./partitions/SomethingAboutUs";
import OurMerchStore from "./partitions/OurMerchStore";
import ServicesSc from "./partitions/ServicesSc";
import AOS from "aos";
import ProjectListingDetails from "./partitions/ProjectListingDetails";
import ProjectListingGallery from "./partitions/ProjectListingGallery";
import PartnerProject from "./partitions/PartnerProject";
import DevTeam from "./partitions/DevTeam";
import ViewOurWork from "./partitions/ViewOurWork";
import CropImage from "./partitions/CropImage";
import Cropper from "react-easy-crop";
import Test from "./partitions/Test";
import getCroppedImg from "./partitions/Crop";
import EasyCrop from "./partitions/EasyCrop";

function HomePage(props) {
  const { connected, address, connectWallet, disconnectWallet } = props;

  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  
  const [openCropPopup, setOpenCropPopup] = React.useState(false);
  const handleClickOpen = () => {
      setOpenCropPopup(true);
  };
  const handleClose = () => {
      setOpenCropPopup(false);
  };

  // Crop function
  const [rotation, setRotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])
  const [image, setImage] = useState(null);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);
  // End Crop function

  const handleImageUpload = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <BannerSc />
      {/* <OurWork /> */}
      {/* <ProjectListingGallery /> */}
      <ViewOurWork />
      {/* <label className="_coverImage-holder">
          Upload Image
          <input
            type="file"
            name="cover"
            onChange={handleImageUpload}
            accept="img/*"
            style={{ display: "none" }}
          />
        </label>
      <EasyCrop image={image}/> */}

      <OurNftStore
        address={address}
        connected={connected}
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
      />

      <OurMerchStore />
      <ServicesSc />
      <DevTeam />
      {/* <SomethingAboutUs /> */}
      <PartnerProject />
    </>  
  )

  // return (
  //   <>
  //     <BannerSc />


  //     <OurWork />
  //     <ProjectListingGallery />

  //     <Test 
  //     handleClose={handleClose}
  //     openCropPopup={openCropPopup}
  //     crop={crop}
  //     setCrop={setCrop}
  //     zoom={zoom}
  //     onCropComplete={onCropComplete}
  //     image={image} setImage={setImage} handleClickOpen={handleClickOpen}/>
  //     <ViewOurWork handleClickOpen={handleClickOpen} />

  //     <OurNftStore
  //       address={address}
  //       connected={connected}
  //       connectWallet={connectWallet}
  //       disconnectWallet={disconnectWallet}
  //     />

  //     <OurMerchStore />
  //     <ServicesSc />
  //     <DevTeam />
  //     <SomethingAboutUs />
  //     <PartnerProject />

  //     <CropImage
  //       handleClickOpen={handleClickOpen}
  //       handleClose={handleClose}
  //       openCropPopup={openCropPopup}
  //       crop={crop}
  //       setCrop={setCrop}
  //       zoom={zoom}
  //       onCropComplete={onCropComplete}
  //     />
  //   </>
  // );
}

export default HomePage;
