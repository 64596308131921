import React, {useEffect} from 'react'
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export default function DefiDungeon() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Box className="cmn_tp_ttl defi_c_bg">
                <Container>
                    <Typography component="h3">defi dungeon</Typography>
                </Container>
            </Box>
            <Box className="prjcts_main">
                <Container>
                    <Row>
                        <Col xs={12} className="mt-5 mb-5">
                            <Box className="pridnt">
                                <Box className="pr_logo_bx">
                                    <Box component="img" className="defi_logo mt-2 mb-2" src='/img/defi/defi_logo.png' />
                                </Box>
                                <Typography component="h5">DEFI DUNGEON: a new way to defi</Typography>
                            </Box>
                        </Col>
                        <Col xs={12} className="mb-5">
                            <Typography className="pro_p">
                                Immerse yourself in the massive online fantasy world of DeFi Dungeon! Stake your claim with one of two factions, and gain power and wealth in a virtual economy that includes crafting, farming, foraging, and more. Buy your Council Seat NFTs now to earn rewards from the treasury and participate in building the world of Defi Dungeon. <br />
                                <b>More info here:</b>  <a href="https://defidungeon.io/" target="_blank">https://defidungeon.io/</a>
                            </Typography>
                        </Col>
                    </Row>
                </Container>
                <Box className="defi_pbx_01">
                    <Row className="w-100 align-items-center">
                        <Col xs={12} lg={5}>
                            <video autoPlay loop muted className="w-100">
                                <source src="/img/defi/dd_icon_small.mp4" type="video/mp4" />
                            </video>
                        </Col>
                        <Col xs={12} lg={7}>
                            <Typography component="h4" className="def_h4 mt-3">defi dungeon</Typography>
                            <Typography component="h6">Bitgraphix are co-creators and creative directors of Defi Dungeon: the MMORPG set in a medieval fantasy world. </Typography>
                            <Typography component="h4" className="def_h4">Game Philosophy</Typography>
                            <Typography className="mb-3">Ownership and anarchy. Taxation is theft and government interference in any activities that individuals engage in with property that they rightfully own is tyranny. The overarching theme in Defi Dungeon is ownership of property with as little interference from the game rules as possible. The dream and philosophy of cryptocurrency is a financial environment that is free from government influence and control. For example, you pay no taxes or dues, you can decide who can and can’t use your property and resources, you decide who can and can’t use your workshops, you set your own prices and restrictions for all of your assets, and basically have control of what you own.</Typography>
                        </Col>
                    </Row>
                </Box>
                <Box className="defi_pbx_02">
                    <Container>
                        <Row>
                            <Col xs={12} className="text-center">
                                <Typography component="h3" className="defi_ttl">
                                    Two Kingdoms
                                    <span>Choose Your Faction</span>
                                </Typography>
                            </Col>
                            <Col xs={12}>
                                <Box component="img" className="img-fluid" src='/img/defi/Two_Kingdoms.png' />
                            </Col>
                            {/* <Col xs={12} sm={6}>
                                <Box component="img" className="img-fluid" src='/img/defi/mght_img.png' />
                            </Col> */}
                            <Col xs={12} lg={7} className="as-mbmns-5">
                                <Typography component="h2" className="def_h2">graphic elements</Typography>
                            </Col>
                            <Col xs={12}>
                                <Box className="defi_lg_txt">
                                    <Typography component="h4">Logotype</Typography>
                                    <Box component="img" className="img-fluid" src='/img/defi/defi_img01.png' />
                                </Box>
                            </Col>
                            <Col xs={12} className="mb-5">
                                <Typography className="pro_p">We created the entire graphics package top to bottom, includeing website, logos, branding, and all the animated 3d NFTs. Bitgraphix is proud to be a fully integretaed partner providing content creation support for the exciting <span>Defi Dungeon</span> project.  </Typography>
                            </Col>
                        </Row>
                    </Container>
                </Box>
                <Box className="defi_pbx_03">
                    <video autoPlay loop muted className="w-100">
                        <source src="/img/defi/DD_Text_Form_liquid_metal_for_web2.mp4" type="video/mp4" />
                    </video>
                </Box>
                <Box className="defi_pbx_04">
                    <Container>
                        <Row>
                            <Col xs={12} className="mt-4">
                                <Typography component="h4" className="def_h4">images</Typography>
                                <Typography className="pro_p mb-5">A collection containing images, video and 3d animated content created for the Defi Dungeon project. </Typography>
                            </Col>
                            <Col xs={12} className="mb-3">
                                <a href="https://defidungeon.io/" className="defi_lnk" target="_blank">
                                    <Box component="img" className="img-fluid w-100 dngn_shdimg" src='/img/defi/defi_img02.png' />
                                </a>
                            </Col>
                            <Col xs={12} md={9} className="mb-3 def_md_9">
                                <video autoPlay loop className="w-100">
                                    <source src="/img/defi/The_Order_Shield_FIANL_v4_FOR_PROMOsm.mp4" type="video/mp4" />
                                </video>
                            </Col>
                            <Col xs={12} md={3} className="mb-3 def_md_3">
                                <Box component="img" className="img-fluid w-100" src='/img/defi/defi_img03.png' />
                            </Col>
                            <Col xs={12} md={3} className="mb-3 def_md_3">
                                <Box component="img" className="img-fluid w-100" src='/img/defi/defi_img04.png' />
                            </Col>
                            <Col xs={12} md={9} className="mb-3 def_md_9">
                                <video autoPlay loop className="w-100">
                                    <source src="/img/defi/FORSAKEN_Shield_FINAL_v5_FOR_PROMOsm.mp4" type="video/mp4" />
                                </video>
                            </Col>
                        </Row>
                    </Container>
                </Box>
            </Box>
        </>
    )
}
