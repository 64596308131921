import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Typography, Link } from "@mui/material";
import { addToLocalGallery, getGalleryItems, logout } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import MultipleFileUpload from "../gallary/MultipleFileUpload";

function Admin(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!props.home.authenticated) {
      history.push("/");
    }
  }, [props.home]);

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: "onChange",
  });
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [isSaving, setIsSaving] = useState(false)
  let widget;

  if (window.cloudinary) {
    widget = window.cloudinary.createUploadWidget(
      {
        sources: ["local", "camera", "dropbox", "google_drive"],
        cloudName: "dvk8wiwnj", // need to update
        uploadPreset: "kvovueij", // need to update
        showPoweredBy: false,
        text: {
          en: {
            local: {
              dd_title_single: "Drag and Drop File Here",
              dd_title_multi: "Drag and Drop Files Here",
            },
            queue: {
              title_uploading: "Uploading Files",
              title: "Files to Upload",
              title_uploading_with_counter: "Uploading {{num}} Files",
            },
          },
        },

        // #59d7ff

        styles: {
          palette: {
            window: "#fff", // white
            windowBorder: "#fff", // white
            tabIcon: "#000", // blue
            menuIcons: "#000", // black
            textDark: "#000", // black
            textLight: "#000", // black
            link: "#59d7ff", // button, blue
            action: "#59d7ff", // done button, blue
            inactiveTabIcon: "#7f7e7e", // gray
            error: "#db5461", // red
            inProgress: "#db5461", // red
            complete: "#5dae48", // green
            sourceBg: "#000", // white
          },
          fonts: {
            "'Lato', sans-serif":
              "https://fonts.googleapis.com/css2?family=Lato",
          },
        },
      },
      (error, result) => {
        if (result && result.info.files) {
          setFiles(result.info.files);
        } else if (error) {
        }
      }
    );
  }

  const onSubmit = (data) => {
    setIsSaving(true)
    data.files = files;
    data.description = data.desc
    dispatch(
      addToLocalGallery(data, () => {
        setFiles([]);
        getGalleryItems();
        setIsSaving(false)
      })
    );
  };

  const showWidget = () => {
    widget.open();
  };

  const handleLogout = () => {
    dispatch(logout(history.push("/")));
  };

  const ViewPreview = () => {
    const [ui, setUi] = useState()
    useEffect(() => {
      if(previewUrls){
        let data = previewUrls.map((file, index) => {
          return (
            <>
              <div className="form-input">
                <img src={file} width={500} height={500}/>
                <label htmlFor="desc">File Description</label>
                <input
                  autoComplete="off"
                  type="text"
                  name="desc"
                  {...register(`desc[${index}]`)}
                />
              </div>
            </>
          );
        })
        setUi(data)
      }
    }, [previewUrls])
    return <>
    {ui}</>
  }
  
  return (
    <div className="admin">
      {files.length > 0 && (
        <form className="upload-form" onSubmit={handleSubmit(onSubmit)}>
          {/* {files &&
            files.map((file) => {
              return (
                <>
                  <div className="form-input">
                    <img src={file.uploadInfo.thumbnail_url} />
                    <label htmlFor="desc">File Description</label>
                    <input
                      autoComplete="off"
                      type="text"
                      name="desc"
                      {...register(`desc[${file.uploadInfo.asset_id}]`)}
                    />
                  </div>
                </>
              );
            })
          } */}

          
          <ViewPreview/>
          <div className="button-container">
            <input
              className="submit-button"
              type="submit"
              value={isSaving ? "Submitting..." : "Add to Gallery"}
              disabled={isSaving}
            />
            <button className="cancel-button" onClick={(e) => {
              setFiles([])
              setPreviewUrls([])
            }}>Cancel</button>
          </div>
        </form>
      )}

      {files.length < 1 && (
        // <Box>
        //   {" "}
        //   <div className="cloudinary-button-container">
        //     <button
        //       onClick={(e) => {
        //         e.preventDefault();
        //         showWidget();
        //       }}
        //       id="upload_widget"
        //       className="cloudinary-button button"
        //     >
        //       Upload Files
        //     </button>
        //   </div>
        // </Box>

        <MultipleFileUpload files={files} setFiles={setFiles} previewUrls={previewUrls} setPreviewUrls={setPreviewUrls}/>
      )}

      <button className="log-out" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps)(Admin);
