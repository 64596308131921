import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Button, Menu } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { logout } from '../redux/login';


import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}



export default function LayoutTwo(props: any) {

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { pathname } = location
  const splitLocation = pathname.split('/')
  const user = useSelector(
    (state: any) => state.home.user)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [scroll, setScroll] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    handleClose()
    dispatch(logout(history.push("/")));
  }
  //   useEffect(() => {
  //     props.addEventListener('scroll', () => {
  //       setScroll(props.scrollY > 10);
  //     });
  //   });
  const drawer = (

    <>

      <Divider className='main_sdbr_drwer' />
      <Box className='sidebar_logo'>
        <Link to="/"><img src='./img/logo.png' alt='' /></Link>
      </Box>
      <List className='disebar_main'>
        <Link to="/user-list" className={splitLocation[1] === 'user-list' ? 'active' : ''}>
          <Box className='sdbr_sml_img_bx'>
            <img src='./img/sidebar01.svg' alt='' />
          </Box>
          <Typography component="h2">User list</Typography>
        </Link>
        <Link to="/team-nft" className={splitLocation[1] === 'team-nft' ? 'active' : ''}>
          <Box className='sdbr_sml_img_bx'>
            <img src='./img/sidebar02.svg' alt='' />
          </Box>
          <Typography component="h2">Team NFT</Typography>
        </Link>
        <Link to="/admin" className={splitLocation[1] === 'admin' ? 'active' : ''}>
          <Box className='sdbr_sml_img_bx'>
            <img src='./img/sidebar01.svg' alt='' />
          </Box>
          <Typography component="h2">Gallery</Typography>
        </Link>
        <Box className='right_two_btns '>
          <Button className='mike_mayer_btn open_btn_1199'>
            <img src='/img/mike_btn01.svg' alt='' />
            <Typography component="h5">{`${user?.firstName || ''} ${user?.lastName || ''}`}</Typography>
            <img src='/img/mike_btn02.svg' alt='' />
          </Button>
        </Box>
      </List>
      {/* <Divider /> */}

    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;


  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }} className="sidebar_nl_main" >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
        className={scroll ? 'appbar_inr scrolled' : 'appbar_inr'}
      >
        <Toolbar className="toolbar_sdbr">
          <Box className='tgl_logo_mx_bx'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
              className="toggle_btn"
            >
              <MenuIcon />
            </IconButton>
            <Box className='sidebar_logo bg_none_logo'>
              <Link to="/"><img src='./img/logo_dark_clr.png' alt='' /></Link>
            </Box>
          </Box>
          <Box className='right_two_btns'>
            <Button className='dark_laight_btn'>
              <img src='/img/light_ic.svg' alt='' />
              <Switch className='light_dark_switch' {...label} />
              <img src='/img/dark_ic.svg' alt='' />
            </Button>
            <Button className='mike_mayer_btn dspl_none_1199' id="profile-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img src='/img/mike_btn01.svg' alt='' />
              <Typography component="h5">{`${user?.firstName || ''} ${user?.lastName || ''}`}</Typography>
              <img src='/img/mike_btn02.svg' alt='' />
            </Button>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'profile-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '20ch'
                },
              }}
            >
              <MenuItem onClick={handleClose} disableRipple>
                Profile
              </MenuItem>
              <MenuItem onClick={() => handleSignOut()} disableRipple>
                Sign out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          className=""
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
        className="sidebar_main_prnt"
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
// 