import axios from "axios";
import Web3 from "web3";
import { useConnectWallet } from "./connectWallet";

// import { disconnectWallet } from "./actions";

export function login({ email, password }, callback) {
  return async (dispatch) => {
    let apiUrl = process.env.REACT_APP_BACKEND_URL

    axios
      .post(`${apiUrl}/api/login`, { email, password })

      .then((response) => {
        return response.data;
      })
      .then((data) => {
        localStorage.setItem("token", data.token);
        dispatch({
          type: "AUTHORIZE_USER",
          user: data.user,
        });

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch({
            type: "AUTH_ERROR",
            error: err.response,
          });
        } else {
          dispatch({
            type: "AUTH_ERROR",
            error: "There was an error logging in.",
          });
        }
      });
  };
}

export function logout(callback) {
  return async (dispatch) => {
    dispatch({
      type: "UNAUTHORIZE_USER",
      payload: null,
    });
    localStorage.removeItem("token");
    if (callback) {
      callback();
    }
  };
}

export function loginNFT(callback) {
  return async (dispatch) => {
    if (window.ethereum) {
      const apiUrl = process.env.REACT_APP_BACKEND_URL
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const token = Math.floor(Math.random() * 100000).toString();
        const signature = await web3.eth.personal.sign(
          `BLU Signature : ${token}`,
          web3.utils.toChecksumAddress(accounts[0]),
          ''
        );
        const payload = {
          address: accounts[0],
          token: `BLU Signature : ${token}`,
          signature: signature,
        };
        axios({
          method: 'post',
          url: `${apiUrl}/api/nftlogin`,
          data: payload
        }).then(res => {
          localStorage.setItem("token", res.data.result.token);
          localStorage.setItem('user', JSON.stringify(res.data.result.user));
          // localStorage.setItem('bluUser', JSON.stringify(res.data.result));
          dispatch({
            type: "AUTHORIZE_USER",
            user: res.data.result.user
          });
  
          if (callback) {
            callback();
          }
        }).catch(err => {
          if (err && err?.response?.data?.message) {
            dispatch({
              type: "NFT_AUTH_ERROR",
              error: err?.response?.data?.message,
            });
          } else {
            dispatch({
              type: "NFT_AUTH_ERROR",
              error: "There was an error logging in.",
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("MetaMask not found.");
    }
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case "AUTH_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "AUTHORIZE_USER":
      return {
        ...state,
        authenticated: true,
        user: action.user,
      };
    case "UNAUTHORIZE_USER":
      return {
        ...state,
        authenticated: false,
        user: null,
      };
    case "NFT_AUTH_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "NFT_AUTHORIZE_USER":
      return {
        ...state,
        nftAutenticated: true,
        user: action.user,
        token: action.token,
      };
    case "NFT_UNAUTHORIZE_USER":
      return {
        ...state,
        nftAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
}
