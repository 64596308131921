import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Box, Typography, Link } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function About() {
    return (
        <>
            <Box
            className='cmn_tp_ttl'
            style={{background: 'url(/img/about/about_tp_bg.jpg) no-repeat center 0',}}
            >
            <Container>
                <Typography component='h3'>ABOUT US</Typography>
            </Container>
            </Box>
            <Box className="abt_main_box">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Box className="abt_innr_cntn">
                                <Typography component="h2" className="def_h2 blue_h2">MISSON</Typography>
                                <Typography component="h4">Premium graphics for bitcoin and blockchain</Typography>
                                <Typography className="mb-5">As the internet age evolves into the blockchain era, Bitgraphix aims to be on the forefront of this new era’s design language. With decades of experience in 3d animation, Feature Film visual effects, motion graphics, and print design, the Bitgraphix team is focusing its talents on Blockchain. Our Digital Content Creation (DCC) tools are ever changing and evolving in both scope and ability, and we believe there’s no space more appropriate than Blockchain in which to apply our creative talents and toolset. Together, Blockchain and DCC are a perfect blend of leading-edge software technology. The team at Bitgraphix is committed to expressing that fusion through premium, eye-catching, visual design.  </Typography>
                                <Typography component="h2" className="def_h2 mb-5">ABOUT US</Typography>
                                <Typography className="mb-5">Bitgraphix is laser focused on bringing feature film quality effects, animation, motion graphics and graphic design into the blockchain and crypto space. Our team members have diverse talents, backgrounds, and skills which are brought together under the Bitgraphix umbrella by our overwhelming drive to create.</Typography>
                            </Box>
                        </Col>
                        <Col xs={12} className="our_team_txt mt-3 mb-5">
                            <Typography component="h1" >OUR TEAM</Typography>
                            <Typography>Clothing for the Bitcoin, cryptocurrency and blockchain space.</Typography>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/acroyear.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Acroyear</Typography>
                                    <Typography >Team Leader</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/karza.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Karza</Typography>
                                    <Typography >VFX</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/biotron.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Biotron</Typography>
                                    <Typography >Cocept ART</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/paradox.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Paradox</Typography>
                                    <Typography >Stories / Game </Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/bacon.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Bacon</Typography>
                                    <Typography >Video Edit / Sound </Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/barfly.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Barfly</Typography>
                                    <Typography >3d VFX</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/paradox.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Cat Mover</Typography>
                                    <Typography >Web UI / UX</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/bacon.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Bacon</Typography>
                                    <Typography >Video Edit / Sound </Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <Box component="a" href="/" className="our_team_boxes">
                                <Box component="img" className="img-fluid" src='/img/about/barfly.png' alt='' />
                                <Box>
                                    <Typography component="h4" >Barfly</Typography>
                                    <Typography >3d VFX</Typography>
                                </Box>
                                <Box className="arrow_box">
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </Box>
            <Box className="services_box">
                <Box className="srv_ttl_blkbx" />
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Box className="srvcttl">
                                <Box className="srv_ttl_img">
                                    <Box component="img" src="/img/logo.png" alt="" />
                                </Box>
                                <Typography component="h3">SERVICES</Typography>
                            </Box>
                        </Col>
                        <Col xs={12}>
                            <Box className="blur_bg_box">
                                <Row>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/nft_design_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >NFT Design</Typography>
                                        </Box>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/visual_effect_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >3D Visual Effects</Typography>
                                        </Box>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/graphic_design_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >Graphic Design</Typography>
                                        </Box>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/motion_design_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >Motion Design</Typography>
                                        </Box>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/web_ui_ux_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >WEB UI / UX</Typography>
                                        </Box>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Box className="services_innr_cntnt">
                                            <Box className="def_same_blu_box">
                                                <Box component="img" src='/img/about/sound_design_ic.svg' alt='' />
                                            </Box>
                                            <Typography component="h3" >Sound Design</Typography>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col> 
                    </Row>
                </Container>
            </Box>
            <Box className="fuel_main_box">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Box className="fuel_innr_cntnt">
                                <Box component="img" src='/img/blck_logo.png' alt='' />
                                <Typography component="h2" className="def_h2 ms-auto fuel_blU_h2">FUEL</Typography>
                            </Box>
                            <Typography className="fuel_p_txt">
                                We are passionate about Bitcoin and Blockchain tech. To us Bitcoin is much more than some innovative lines of code, more than a trending speculative investment, more than a buzzword on CNBC. We believe it holds the promise of a brighter future for all humanity. Not only is it a decentralized digital store of value and form of money, but it’s also unconfiscatable, incorruptible, and resistant to censorship. It’s like physical gold 2.0. Government printed money (fiat money) has a limitless supply, as central banks can print as much as they like, which, in extreme cases, can lead to budget deficits, hyper-inflation and currency collapse. In subtler ways the continuous expansion of the fiat money supply looks simply like the cost of living going up. But in reality, the things we buy aren’t necessarily getting more expensive, it’s the expanding money supply that is making the fiat dollars less valuable. Bitcoin is truly the solution to this and many other societal challenges. *                                
                            </Typography>
                            <Typography className="fuel_p_txt">Bitcoin is too important for us not to be passionate about it, and its that passion that fuels Bitgraphix.</Typography>
                            <Typography className="fuel_p_txt">For an exegesis on Bitcoin and its value in society, we highly recommend reading The Bitcoin Standard by <Box component="a" href='#'>Saifedean Ammous</Box></Typography>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>
    )
}
