import React, { useEffect, useState } from "react";

// import { toast } from "react-toastify";
import { fetchPostApi, fetchPutApi } from "../../utils/backendApi";
import Select from 'react-select';
import { initialPaymentMethod, options, optionsBlockchain, optionsToken, paymentType } from "./UserList";
import httpStatus from "http-status";
import { API_URL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "../../../store";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
// import { getCountries } from "../../../slices/countries";
// import { getStateByCountry } from "../../../slices/states";
// import { getCitiesByState } from "../../../slices/cities";

interface Props {
  user: any,
  isOpen: boolean,
  onClose: () => void,
  getUsers: () => void,
}


export default function UserModalForm({ user, isOpen, onClose, getUsers }: Props) {
  const cancelRef = React.useRef(null);
  // const { colorMode } = useColorMode();

  // const dispatch: AppDispatch = useDispatch();

  // const countries: any = useSelector((state: RootState) => state.countries);
	// const states: any = useSelector((state: RootState) => state.states);
	// const cities: any = useSelector((state: RootState) => state.cities);

  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [clickUpID, setClickUpID] = useState<any>();
  const [username, setUsername] = useState<string>('');
  const [type, setType] = useState<any>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [hourRate, setHourRate] = useState<number>(1);

  const [companyName, setCompanyName] = useState<string>('');
  const [companyAddress, setCompanyAddress] = useState<string>('');
  const [companyAddress2, setCompanyAddress2] = useState<string>('');
  const [companyCity, setCompanyCity] = useState<any>();
  const [companyCountry, setCompanyCountry] = useState<any>();
  const [companyState, setCompanyState] = useState<any>();
  const [companyPostalCode, setCompanyPostalCode] = useState<string>('');
  const [companyTaxNumber, setCompanyTaxNumber] = useState<string>('');
  const [optionUser, setOptionUser] = useState<any[]>([]);
  const [error, setError] = useState<any>({
    username: false,
    firstName: false,
    lastName: false,
    email: false,
    hourRate: false,
  })

  const [paymentMethodsEdit, setPaymentMethodsEdit] = useState<any[]>([initialPaymentMethod]);
  const [paymentActive, setPaymentActive] = useState<string>('0')

  const [countryOptions, setCountryOptions] = useState([])
	const [stateOptions, setStateOptions] = useState([])
	const [cityOptions, setCityOptions] = useState([])

  // useEffect(() => {
	// 	dispatch(getCountries(1, 500));
	// }, []);

	// useEffect(() => {
	// 	// console.log("countries: ", countries.countries)
	// 	if(countries.countries.result){
	// 		let countryOptions = countries.countries.result.map((e:any) => {
	// 			return {
	// 				value: {id: e.id, name:e.name},
	// 				label: e.name
	// 			}
	// 		})
	// 		setCountryOptions(countryOptions)
	// 	}

	// }, [countries]);
	
	// useEffect(() => {
	// 	// console.log("countries: ", countries.countries)
	// 	if(states.states.result){
	// 		let stateOptions = states.states.result.map((e:any) => {
	// 			return {
	// 				value: {id: e.id, name:e.name},
	// 				label: e.name
	// 			}
	// 		})
	// 		setStateOptions(stateOptions)
	// 	}

	// }, [states]);

	// useEffect(() => {
	// 	// console.log("countries: ", countries.countries)
	// 	if(cities.cities.result){
	// 		let cityOptions = cities.cities.result.map((e:any) => {
	// 			return {
	// 				value: {id: e.id, name:e.name},
	// 				label: e.name
	// 			}
	// 		})
	// 		setCityOptions(cityOptions)
	// 	}

	// }, [cities]);

  const getFormData = async () => {
    const bluToken = localStorage.getItem('bluToken');
    const response = await fetch(API_URL + "/api/clickup/user", {
      headers: { 'Authorization': 'Bearer ' + bluToken }
    })
    if (response.status == httpStatus.OK) {
      const data = await response.json();
      let options = []
      for (const item of data) {
        if (user?.id_user_clickup) {
          if (user?.id_user_clickup == item.id) {
            setClickUpID({
              value: item.id,
              name: item.name,
              label: <div>{item.name}</div>
            })
          }
        }
        options.push({
          value: item.id,
          name: item.name,
          label: <div>{item.name}</div>
        })
      }
      setOptionUser(options);
    }
  }

  console.log('###############')
  const updateWalletName = (index: number) => (e: any) => {
    let tempData = [...paymentMethodsEdit];
    tempData[index].walletName = e.target.value;
    setPaymentMethodsEdit(tempData);
  };

  const updateWalletAddress = (index: number) => (e: any) => {
    let tempData = [...paymentMethodsEdit];
    tempData[index].walletAddress = e.target.value;
    setPaymentMethodsEdit(tempData);
  };

  const updateToken = (e: any, index: number) => {
    let tempData = [...paymentMethodsEdit];
    tempData[index].token = e;
    setPaymentMethodsEdit(tempData);
  };

  const updateBlockchain = (e: any, index: number) => {
    let tempData = [...paymentMethodsEdit];
    tempData[index].blockchain = e;
    setPaymentMethodsEdit(tempData);
  };

  const addPaymentMethod = () => {
    let data = {
      active: false,
      walletName: '',
      walletAddress: '',
      blockchain: '',
      token: '',
    }

    setPaymentMethodsEdit(paymentMethod => [...paymentMethod, data]);
  };

  const deletePaymentMethod = (index: number) => {
    setPaymentMethodsEdit(paymentMethodsEdit.filter((item, id) => id !== index))
  }

  const handleEmail = (e: any) => {
    setError({
      ...error,
      email: false,
    })
    setEmail(e.target.value)
  }

  const handleFirstName = (e: any) => {
    setError({
      ...error,
      firstName: false,
    })
    setFirstName(e.target.value)
  }

  const handleLastName = (e: any) => {
    setError({
      ...error,
      lastName: false,
    })
    setLastName(e.target.value)
  }

  const handleHourRate = (e: any) => {
    setError({
      ...error,
      hourRate: false,
    })
    setHourRate(e.target.value)
  }

  const handleUsername = (e: any) => {
    setError({
      ...error,
      username: false,
    })
    setUsername(e.target.value)
  }

  const doSubmit = async () => {
    let newError = {
      username: false,
      firstName: false,
      lastName: false,
      email: false,
      hourRate: false,
    }

    if (username === '') {
      newError.username = true
    }

    if (firstName === '') {
      newError.firstName = true
    }

    if (lastName === '') {
      newError.lastName = true
    }

    if (email === '') {
      newError.email = true
    }

    if (!hourRate) {
      newError.hourRate = true
    }

    setError(newError)

    if (username === '' || firstName === '' || lastName === '' || email === '') {
      // toast.error('Please fill the forms required!')
      return
    }
    setLoading(true);
    const body = {
      _id: id,
      id_user_clickup: clickUpID?.value,
      username: username,
      type: type?.value,
      firstName,
      lastName,
      email,
      companyName: companyName,
      companyCity: companyCity,
      companyCountry: companyCountry,
      companyPostalCode: companyPostalCode,
      companyAddress: companyAddress,
      companyAddress2: companyAddress2,
      companyState: companyState,
      companyTaxNumber: companyTaxNumber,
      payment_methods: paymentMethodsEdit.map(value => ({
        walletName: value?.walletName,
        walletAddress: value?.walletAddress,
        blockchain: value.blockchain?.value,
        token: value.token?.value,
      })),
      payment_active: paymentActive
    };
    try {
      if (id == '') {
        // console.log('start')
        const response: any = await fetchPostApi('api/user/create', body, true);
        // console.log("RESPONSE : ", response)
        if (response?.status == httpStatus.CREATED) {
          let data = await response.json();
          // toast.success('Success');
        }
        else {
          // toast.error(`Error:${'Internal server error'}`);
        }
      }
      else {
        const response = await fetchPutApi('api/user/update', body, true);
        if (response?.status == httpStatus.OK) {
          let data = await response.json();
          if (data?.success) {
            // toast.success('Success');
          }
          else {
            // toast.error(`Error:${data?.message ?? 'Internal server error'}`);
          }
        }
        else {
          // toast.error(`Error:${'Internal server error'}`);
        }
      }
      getUsers();
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    // getFormData();

    if (user) {
      // console.log('USER : ', user)
      setId(user._id);
      setClickUpID(user.id_user_clickup);
      setUsername(user.username);
      setType(user.type);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setHourRate(parseInt(user.hour_rate))
      setCompanyName(user.company?.name)
      setCompanyAddress(user.company?.address)
      setCompanyAddress2(user.company?.address2)
      setCompanyCity(user.company?.city)
      setCompanyCountry(user.company?.country)
      setCompanyPostalCode(user.company?.postal_code)
      setCompanyTaxNumber(user.company?.tax_number)
      setPaymentActive(user.payment_active || '0')
      setCompanyState(user.company?.state)

      // dispatch(getStateByCountry(user.company?.country?.id, 1, 99999))
			// dispatch(getCitiesByState(user.company?.state?.id, 1, 99999))

      let typeData = options.filter(e => {
        return e.value == user.type
      })[0];
      setType(typeData)
      if (user.payment_methods) {
        const temp = user.payment_methods.map((paymentMethod: any) => (
          {
            walletName: paymentMethod.walletName,
            walletAddress: paymentMethod.walletAddress,
            blockchain: optionsBlockchain.find(e => e.value === paymentMethod.blockchain),
            token: optionsToken.find(e => e.value === paymentMethod.token),
          }
        ));
        setPaymentMethodsEdit(temp as paymentType[]);
      }
    }
    else {
      let typeData = options.filter(e => {
        return e.value == 'user'
      })[0];

      setType(typeData)
      setId('');
      setClickUpID('');
      setUsername('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setHourRate(1);
      setCompanyName('');
      setCompanyAddress('');
      setCompanyAddress2('');
      setCompanyState('');
      setCompanyCity('');
      setCompanyCountry('');
      setCompanyPostalCode('');
      setCompanyTaxNumber('');
      setPaymentMethodsEdit([]);
      setPaymentActive('0')
    }
    return () => {
      setPaymentActive('0')
    };
  }, [user])



  return (
    <div className='modal_div_user'>
        <Modal
          keepMounted
          open={isOpen}
          onClose={onClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='user_modal_inr_main'>
            <Box className='mdl_hdr_usr'>
                <Button className='back_btn_usr' onClick={onClose}>
                  <img src='./img/back_arrow.svg' alt='' />
                  Back
                </Button>
                <Typography component="h1">Create new user</Typography>
            </Box>
            <Box className='usr_mdl_body_prnt'>
              <Typography component="h1">
              User
              </Typography>
              <Box className='user_inpts'>
                <Grid container spacing={6}>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="First Name" variant="standard" value={firstName} onChange={(e: any) => handleFirstName(e)} />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="User Nickname" variant="standard" value={username} onChange={(e: any) => handleUsername(e)} />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Team NFT ID Number" variant="standard"  value={user?.id_nft_token?.nft_token} disabled />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Last name" variant="standard" value={lastName} onChange={(e: any) => handleLastName(e)}/>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Email" variant="standard" value={email} onChange={(e: any) => handleEmail(e)}/>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6} className="slct_grd_cstm">
                    <Box className='slct_bx_crte_usr'>
                        <Select options={Type} className="slct_main_usr" classNamePrefix='cstm_slct' placeholder="Type" />
                    </Box>
                  </Grid> */}
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Address 1" variant="standard" value={companyAddress} onChange={(e: any) => setCompanyAddress(e.target.value)} />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Address 2 (optional)" variant="standard" value={companyAddress2} onChange={(e: any) => setCompanyAddress2(e.target.value)}  />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="City" variant="standard" />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="slct_grd_cstm">
                    <Box className='slct_bx_crte_usr'>
                        <Select options={State} className="slct_main_usr" classNamePrefix='cstm_slct' placeholder="State" />
                    </Box>
                  </Grid>
                  <Grid item xs={6} className="slct_grd_cstm">
                    <Box className='slct_bx_crte_usr'>
                        <Select options={Country} className="slct_main_usr" classNamePrefix='cstm_slct' placeholder="Country" />
                    </Box>
                  </Grid> */}
                  <Grid item xs={6} className="clastm_grid_prnt">
                    <Box className='cstm_input'>
                      <TextField id="standard-basic" label="Postal Code" variant="standard" value={companyPostalCode} onChange={(e: any) => setCompanyPostalCode(e.target.value)} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Box className='wlt_tkn_blckchain'>
                <Grid container spacing={2}>
                  <Grid item xs={3} className="">
                    <Typography component="h2">Wallet name</Typography>
                  </Grid>
                  <Grid item xs={3} className="">
                    <Typography component="h2">Token</Typography>
                  </Grid>
                  <Grid item xs={3} className="">
                    <Typography component="h2">Blockchain</Typography>
                  </Grid>
                  <Grid item xs={3} className="">
                    <Typography component="h2">Wallet Address</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className='wlt_inr_bxs'>
                <Grid container spacing={1}>
                  <Grid item xs={1} className="clastm_grid_prnt xs_1_only">
                    <FormControlLabel value="female" className='radio_cstm' control={<Radio />} label=" " />
                  </Grid>
                  <Grid item xs={2} className="clastm_grid_prnt  frst_wlt_nme">
                    <Box className='wlt_name_bxx ds_flex'>
                      <Box className='wlt_name_bx '>
                        <input type="text" placeholder="Wallet name" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2} className="clastm_grid_prnt">
                    <Box className='slct_wlt_name slct_bx_crte_usr'>
                      <Select options={Token} className="slct_main_usr" classNamePrefix='cstm_slct' placeholder="Token"   />
                    </Box>
                  </Grid>
                  <Grid item xs={2} className="clastm_grid_prnt">
                    <Box className='slct_wlt_name slct_bx_crte_usr'>
                      <Select options={Blockchain} className="slct_main_usr" classNamePrefix='cstm_slct' placeholder="Blockchain" />
                    </Box>
                   </Grid>
                   <Grid item xs={4} className="clastm_grid_prnt wlt_inpt_prnt">
                    <Box className='wlt_adrs_input'>
                      <input type="text" placeholder="Wallet address" />
                      <img src="/img/wlt_adrs_ic.png" alt='' />
                    </Box>
                   </Grid>
                   <Grid item xs={1} className="clastm_grid_prnt xs_1_only">
                      <Box className='delete_ic_bx'>
                        <img src='/img/dlt_ic.png' alt='' />
                      </Box>
                   </Grid>
                </Grid>
              </Box>
              <Box className='new_paymnt_btn_bx'>
                <Button>+ Add new payment method</Button>
              </Box> */}
              <Box className='user_mdl_ftr'>
                <Button className='cncl_btn_usr' onClick={onClose}>Cancel</Button>
                <Button className='create_btn_usr' 
                disabled={loading}
              onClick={doSubmit}>Fill all for create</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
  )
}