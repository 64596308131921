import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import Gallery from "./Gallery";
import { connect, useDispatch } from 'react-redux';
import { getGalleryItems } from "../../../redux/actions";

function ViewOurWork(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [previewImages, setPreviewImages] = React.useState([]);
  // const [previewImages, setPreviewImages] = React.useState([
  //   "/img/work_img_sc01.png",
  //   "/img/work_img_sc02.png",
  //   "/img/work_img_sc03.png",
  //   "/img/work_img_sc04.png",
  //   "/img/work_img_sc05.png",
  //   "/img/work_img_sc06.png",
  //   "/img/work_img_sc07.png",
  //   "/img/work_img_sc08.png",
  //   "/img/work_img_sc09.png",
  //   "/img/work_img_sc10.png",
  //   "/img/work_img_sc11.png",
  //   "/img/work_img_sc13.png",
  //   "/img/work_img_sc14.png",
  //   "/img/work_img_sc15.png",
  //   "/img/work_img_sc16.png",
  //   "/img/work_img_sc17.png",
  //   "/img/work_img_sc18.png",
  //   "/img/work_img_sc19.png",
  //   "/img/work_img_sc20.png",
  //   "/img/work_img_sc21.png",
  //   "/img/work_img_sc22.png",
  //   "/img/work_img_sc23.png",
  //   "/img/work_img_sc24.png",
  //   "/img/work_img_sc25.png",
  // ]);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    dispatch(getGalleryItems());
  }, []);

  useEffect(() => {
    setPreviewImages(props.gallery?.reverse().slice(0, 24))
  }, [props.gallery]);

  const handleClick = () => {
    setOpen(!open);

    const element = document.getElementsByClassName("ourwork")[0];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const shuffleImages = () => {
    setIsButtonDisabled(true);

    const imageElements = imageContainerRef.current.querySelectorAll('.image-container img');

    const shuffledIndices = [];
    for (let i = 0; i < previewImages.length; i++) {
      shuffledIndices.push("image" + i);
    }

    for (let i = shuffledIndices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledIndices[i], shuffledIndices[j]] = [shuffledIndices[j], shuffledIndices[i]];
    }

    let moving = [];

    for (let i = 0; i < imageElements.length; i++) {
      const newPosition = shuffledIndices.findIndex((img) => img === imageElements[i].getAttribute('id'));
      if (newPosition !== -1 && (i != newPosition)) {
        if (imageElements[newPosition] != undefined) {
          const imageStyles = getComputedStyle(imageElements[newPosition]);
          moving.push({ idx: i, to: imageStyles });
        }
      }
    }

    for (let i = 0; i < moving.length; i++) {
      let obj = moving[i]
      let image = imageElements[obj.idx];
      let newPositionRect = obj.to;

      const topValue = newPositionRect.getPropertyValue("top");
      const leftValue = newPositionRect.getPropertyValue("left");
      const rightValue = newPositionRect.getPropertyValue("right");
      const bottomValue = newPositionRect.getPropertyValue("bottom");
      const widthValue = newPositionRect.getPropertyValue("width");
      const heightValue = newPositionRect.getPropertyValue("height");

      image.style.left = leftValue;
      image.style.top = topValue;
      image.style.right = rightValue;
      image.style.bottom = bottomValue;
      image.style.width = widthValue;
      image.style.height = heightValue;
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 4000);
  };

  const showCropDialog = () => {
    props.handleClickOpen();
  }

  function formatNumberWithLeadingZeros(number) {
    const length = 2; // Desired length of the resulting string
    return number.toString().padStart(length, '0');
  }

  let apiUrl = process.env.REACT_APP_BACKEND_URL;

  console.log("ViewOurWork-open", open);
  console.log("ViewOurWork-previewImages", previewImages);

  return previewImages ? (
    <>
      <div className={open ? "ourwork open" : 'ourwork'}>
        <Box className='work_txt_bx'>
          <img src="/img/bit_gri_p_img.png" alt="" className='bit_gri_p_img' />
          <Typography>View our work</Typography>
          <span onClick={(e) => shuffleImages()} className={isButtonDisabled ? 'disabled' : ''}>shuffle</span>
        </Box>

        <Box className={open ? "mainGallerybx open" : 'mainGallerybx'}>
          <Box className="absltGallery">
            <Box className="view_work_min_prnt">
              <Box className="view_work_pozi">
                <div className="image-container" ref={imageContainerRef}>
                  {/* <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade"> */}
                  {previewImages.map((img, index) => {
                    // if(index == 16) return <></>

                    // let imageUrl = apiUrl +'/api/images/thumb/'+img.fileName;
                    // let imageUrl = apiUrl +'/api/gallery/view-image-thumb?image='+img.fileName + "&width=500&height=500"

                    let imageUrl = img.url.replace('/upload', '/upload/c_thumb,w_500,h_500');
                    // console.log("ViewOurWork-imagesUrl", index, imageUrl);
                    return (
                      <a data-src={imageUrl} key={index}>
                        <img
                          src={imageUrl}
                          alt=""
                          id={`image${index}`}
                          className={`work_img_sc${formatNumberWithLeadingZeros(index + 1)}`}
                          style={{ transitionDelay: `${index * 100}ms`, transition: `all 2s` }} />
                      </a>
                    )
                  })}
                  {/* </LightGallery> */}
                </div>
              </Box>
            </Box>
          </Box>
          {/* <Box className="absltBtmAction">
            <Button className='click_vive_btn' onClick={handleClick}>
              {open ?
                <>
                  Click to Hide All<ArrowUpwardIcon />
                </>
                :
                <>
                  Click to View All<ArrowDownwardIcon />
                </>
              }
            </Button>
          </Box> */}
          <Box className={open ? "gallyr_min_prnt_bx open" : 'gallyr_min_prnt_bx'}>
            <Box className="cstmGallr">
              <Gallery />
            </Box>
          </Box>
        </Box>
        <Box className="unlock_min_orng_bx">
          <Box>
            <Typography component="h4">Unlock the power<br></br><span>of visual<br></br> storytelling</span></Typography>
          </Box>
          <Typography>We're not just designers; we're architects of awe-inspiring visual narratives and master craftsmen of user experiences that resonate. You might be a charity with a revolutionary fundraising idea, an entrepreneur craving a unique digital footprint, or a business hungry for an immersive mobile app that stands out. Whatever your dream, Bitgraphix helps you turn it into reality.</Typography>
        </Box>
        <Box className="dare_part_wit_bg_box">
          <Typography>Dare to be unforgettable.<br></br><span>Welcome to Bitgraphix.</span></Typography>
        </Box>
      </div>
    </>
  ) : <>previewImages</>
}

const mapStateToProps = (state) => {
  return {
    gallery: state.home.gallery
  };
};

export default connect(mapStateToProps)(ViewOurWork);

