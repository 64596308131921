import React, { useState } from 'react';
import Select from 'react-select';
import { Box, Typography, Tabs, Tab, Container } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import TabPanelOne from './bitgraphixtab/TabPanelOne';
import TabPanelTwo from './bitgraphixtab/TabPanelTwo';

const options = [
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
    { value: 'most sold', label: 'Most Sold' },
    { value: 'most rare', label: 'Most Rare' },
    { value: 'lowest price', label: 'Lowest Price' },
    { value: 'highest price', label: 'Highest Price' },
];

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }} className='tab_panl_bx_min'>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function MarketplaceCataogue() {
    const [selectedOption, setSelectedOption] = useState(null);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [age, setAge] = React.useState('');

    const handleChangetwo = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };
    const handleChangethre = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Box className='walcom_bitgra_top_contin'>
                <Typography component="h2">Welcome to Bitgraphix : <span>NFT GAllery</span></Typography>
                <Typography>Bitgraphix Original NFTs and Bitgraphix Client NFTs</Typography>
            </Box>

            <Box sx={{ width: '100%' }} className="bit_tab_min_prnt">
                <Box>
                    <Container className='def_conta_iner'>
                        <Box className='tab_siad_select'>
                            <Box className="tab_sid_contin">
                                <Typography component="h3" className="def_bl_ttl bg_changed_ash">
                                    <span>View</span>{" "}
                                    <span className="cmng_sn_txt">our own collections</span>
                                    <br />
                                    our Bitgraphix NFTs
                                </Typography>
                            </Box>
                            <Box className="tab_two_select">
                                {/* <FormControl sx={{ m: 1, minWidth: 152 }} className=''>
                                    <Select
                                        value={age}
                                        onChange={handleChangetwo}
                                        displayEmpty
                                        id="demo-simple-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className='sel_bx_in'
                                    >
                                        <MenuItem value="">
                                            Newest
                                        </MenuItem>

                                        <MenuItem value={20}>Oldest</MenuItem>
                                        <MenuItem value={30}>Most Sold</MenuItem>
                                        <MenuItem value={30}>Most Rare</MenuItem>
                                    </Select>
                                    <FormHelperText>Without label</FormHelperText>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 152 }} className=''>
                                    <Select
                                        value={age}
                                        onChange={handleChangethre}
                                        displayEmpty
                                        id="demo-simple-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className='sel_bx_in'
                                    >
                                        <MenuItem value="">
                                            Newest
                                        </MenuItem>

                                        <MenuItem value={20}>Oldest</MenuItem>
                                        <MenuItem value={30}>Most Sold</MenuItem>
                                        <MenuItem value={30}>Most Rare</MenuItem>
                                    </Select>
                                    <FormHelperText>Without label</FormHelperText>
                                </FormControl> */}
                                <Box className="top_selact_fx">
                                    <div className="App selct_bx">
                                        <Select
                                            options={options}
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    <div className="App selct_bx selct_bx_v2a">
                                        <Select
                                            options={options}
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                </Box>

                            </Box>

                        </Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab_had_bx'>
                            <Tab label="Bitgraphix Original" {...a11yProps(0)} className='tab_orgin_btn' />
                            <Tab label="Client Collection" {...a11yProps(1)} className='tab_coll_btn' />
                        </Tabs>
                    </Container>

                </Box>
                <TabPanel value={value} index={0}>
                    <Box className='TabPanelone_bx' >
                        <TabPanelOne />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box className='TabPanelTwo_bx' >
                        <TabPanelTwo />
                    </Box>
                </TabPanel>

            </Box>
        </>
    )
}
