import React from 'react'
import BigPicture from "@instaffogmbh/react-bigpicture";
import { Box, Typography } from '@mui/material'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export default function Gallary() {
   
    return (
        <>
            <Box className="gallary_box">
                <Container>
                    <Row>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img01.jpg"
                                >
                                    <img src="/img/tmplt_a/layout_img01.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img02.jpg"
                                >
                                    <img src="/img/tmplt_a/layout_img02.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img03.jpg"
                                >
                                   <img src="/img/tmplt_a/layout_img03.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img04.jpg"
                                >
                                    <img src="/img/tmplt_a/layout_img04.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img05.jpg"
                                >
                                     <img src="/img/tmplt_a/layout_img05.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2} className="mb-3">
                            <Box className="vd_bx_h">
                                <BigPicture
                                    type="img"
                                    caption="Example of an optional caption."
                                    src="/img/tmplt_a/layout_img01.jpg"
                                >
                                    <img src="/img/tmplt_a/layout_img01.jpg" alt="" className="img-fluid" />
                                </BigPicture>
                                <Box component="img" src="/img/view_icon.svg" className="view_btn" />
                            </Box>
                        </Col>

                    </Row>
                </Container>
            </Box>
            
        
        </>
    )
}
