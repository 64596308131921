import axios from "axios";

export function addToGallery(data, callback) {
  let apiUrl = process.env.REACT_APP_BACKEND_URL

  return async (dispatch) => {
    axios
      .post(`${apiUrl}/api/gallery`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an error adding to gallery.");
        }
      });
  };
}


export function addToLocalGallery(data, callback) {
  let apiUrl = process.env.REACT_APP_BACKEND_URL

  return async (dispatch) => {
    const formData = new FormData();
    for(let i=0;i<data.files.length; i++){
      formData.append("files", data.files[i]);
    }
    formData.append("description", data.description)

    axios
      .post(`${apiUrl}/api/gallery/local`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an error adding to gallery.");
        }
      });
  };
}

export function updateGalleryBlock(data, callback) {
  let apiUrl = process.env.REACT_APP_BACKEND_URL

  return async (dispatch) => {
    axios
      .put(`${apiUrl}/api/gallery`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an updating the gallery.");
        }
      });
  };
}

export function updateGalleryCropSize(data, callback) {
  let apiUrl = process.env.REACT_APP_BACKEND_URL
  return async (dispatch) => {
    axios
      .put(`${apiUrl}/api/gallery/edit-crop-size`, data, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an updating the gallery.");
        }
      });
  };
}

export function getGalleryItems() {
  let apiUrl = process.env.REACT_APP_BACKEND_URL
  return async (dispatch) => {
    
    axios
      .get(`${apiUrl}/api/gallery/new`)

      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch({
          type: "GET_GALLERY_ITEMS",
          gallery: data,
        });
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an getting gallery items.");
        }
      });
  };
}

export function getGalleryItemsShufflePagination(page) {
  let apiUrl = process.env.REACT_APP_BACKEND_URL
  return async (dispatch) => {
    
    axios
      .get(`${apiUrl}/api/gallery/shuffle/${page}`)

      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch({
          type: "GET_GALLERY_ITEMS_SHUFFLE",
          galleryShuffle: data,
        });
      })
      .catch((err) => {
        if (err && err.response) {
          console.log(err);
        } else {
          console.log("There was an getting gallery items.");
        }
      });
  };
}
export function reducer(state, action) {
  switch (action.type) {
    case "GET_GALLERY_ITEMS":
      return {
        ...state,
        gallery: action.gallery,
      };
    case "GET_GALLERY_ITEMS_SHUFFLE":
      return {
        ...state,
        galleryShuffle: action.galleryShuffle,
      };
    default:
      return state;
  }
}
